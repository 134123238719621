import { PaymentProvider } from 'app/models/payment-providers/payment-provider';
import { Order } from 'app/models/orders';

export abstract class CheckoutStrategy {
  abstract get type(): string;

  abstract get purchaseButtonText(): string;

  abstract get displayPlanSelection(): boolean;

  abstract get showAutoRenewTerms(): boolean;

  abstract getSkuAmountPathForPaymentPlanType(paymentPlanType: string): string;

  abstract supportsRouterPath(routerPath: string): boolean;

  abstract supportsPaymentProvider(provider: PaymentProvider): boolean;

  abstract heroTextLarge(): string;

  abstract heroTextMedium(order: Order): string;

  abstract heroTextSmall(order: Order): string;

  abstract continueButtonLabel: string;

  abstract submitOrderButtonLabel: string;

  abstract reviewPageHeroTextLarge: string

  createPurchase?(userId: string, inputData: object): Promise<any>;

  validatePurchase?(userId: string, inputData: object): Promise<any>;
}

import { Order, CampaignParticipation } from 'app/models/orders';

export interface ApiFirstMonthFreePromotion {
  after_promo_upfront_discount_amount: number;
  after_promo_upfront_discount_amount_display: string;
}

export interface FirstMonthFreePromotion {
  type: 'first-month-free';
  afterPromoUpfrontDiscountAmount: number;
  afterPromoUpfrontDiscountAmountDisplay: string;
}

type Promotion = FirstMonthFreePromotion;

const isApiFirstFreeMonthPromotion = (apiPromotion: any): apiPromotion is ApiFirstMonthFreePromotion => {
  const upfrontDiscountAmount = apiPromotion['after_promo_upfront_discount_amount'];
  const upfrontDiscountDisplay = apiPromotion['after_promo_upfront_discount_amount_display'];
  return Number.isInteger(upfrontDiscountAmount) && typeof upfrontDiscountDisplay === 'string';
};

const firstMonthFreePromotionFromApi = (apiPromotion: ApiFirstMonthFreePromotion): FirstMonthFreePromotion => ({
  type: 'first-month-free',
  afterPromoUpfrontDiscountAmount: apiPromotion.after_promo_upfront_discount_amount,
  afterPromoUpfrontDiscountAmountDisplay: apiPromotion.after_promo_upfront_discount_amount_display,
});

// TODO Incorporate free-trial promotion here, to have consistent means
//      of preparing API promotion data for the frontend
export const promotionsFromOrder = (order: Order): Promotion[] => {
  const isParticipating = order?.campaignParticipation === CampaignParticipation.JOIN;
  if (!isParticipating) {
    return [];
  }
  
  const promotions = Object.entries(order.promotion).map(([_promoName, promoDetails]): (Promotion | undefined) => {
    if (isApiFirstFreeMonthPromotion(promoDetails)) {
      return firstMonthFreePromotionFromApi(promoDetails);
    }

    return undefined;
  })
  .filter<Promotion>((promotion: Promotion | undefined): promotion is Promotion => promotion !== undefined);

  return promotions;
};


import get from 'lodash/get';
import { createAsyncAction, createAction } from 'app/helpers/action-helper';
import errorHelper from 'app/helpers/error-helper';
import { getState } from 'app/helpers/geo-helper';
import orderService from 'app/services/order-service';
import Types from './types';
import { getExperimentsMetadata } from 'app/reducers/user';
import store from 'app/store';

const handleOrderError = (error) => {
  if (errorHelper.isApplicationError(error)) {
    return { error };
  }

  return { error: errorHelper.orderFailedError({ cause: error }) };
};

export default {
  updateOrder(newOrderInput) {
    const state = store.getState();
    const previousOrder = get(state, 'checkout.order');
    const userId = get(state, 'user.user.userId');

    return createAsyncAction(
      Types.UPDATE_ORDER,
      Types.UPDATE_ORDER_COMPLETE,
      () => {
        return orderService
          .buildOrder({
            orderInput: newOrderInput,
            previousOrder,
            userId
          })
          .catch(handleOrderError);
      }
    );
  },

  createOrder(orderInput) {
    const userId = get(store.getState(), 'user.user.userId');

    return createAsyncAction(
      Types.CREATE_ORDER,
      Types.CREATE_ORDER_COMPLETE,
      () => {
        return orderService
          .buildOrder({
            orderInput,
            userId
          })
          .catch(handleOrderError);
      }
    );
  },

  updateOrderWithUser(order, user) {
    return createAsyncAction(
      Types.UPDATE_ORDER_WITH_USER,
      Types.UPDATE_ORDER_WITH_USER_COMPLETE,
      () => {
        return orderService
          .updateOrderWithUser(order, user)
          .catch(handleOrderError);
      }
    );
  },

  /**
   * Populate state from zip code.
   *
   * @param zipCode
   */
  convertZipCodeToState(zipCode) {
    const state = getState(zipCode) || '';
    return createAction(Types.CONVERT_ZIP_CODE_TO_STATE, state);
  },

  updatePaymentMethodData(data) {
    return createAction(Types.UPDATE_PAYMENT_METHOD_DATA, data);
  },

  replaceAvailablePaymentMethodData(data) {
    return createAction(Types.REPLACE_AVAILABLE_PAYMENT_METHOD, data);
  },

  updateSelectedPaymentMethod(provider) {
    return createAction(Types.UPDATE_SELECTED_PAYMENT_METHOD, provider);
  },

  useExistingCard(use) {
    return createAction(Types.USE_EXISTING_CARD, use);
  },

  submitPurchase(paymentData) {
    const {
      checkout: { order },
      user: { user, anonymousId }
    } = store.getState();

    return createAsyncAction(
      Types.SUBMIT_PURCHASE,
      Types.SUBMIT_PURCHASE_COMPLETE,
      () =>
        orderService.submitPurchase(
          order,
          user,
          anonymousId,
          getExperimentsMetadata(store.getState()),
          paymentData
        )
    );
  },

  getRazorpayOrderId() {
    const {
      checkout: { order },
      user: { user, anonymousId }
    } = store.getState();

    return createAsyncAction(
      Types.FETCH_RAZORPAY_ORDER_ID,
      Types.FETCH_RAZORPAY_ORDER_ID_COMPLETE,
      () =>
        orderService
          .getRazorpayOrderId(
            order,
            user,
            anonymousId,
            getExperimentsMetadata(store.getState())
          )
          .catch((error) => {
            return { error: errorHelper.razorpayFailedOrderError(error) };
          })
    );
  },

  submitRazorpayPurchase(razorpayResponse) {
    const {
      checkout: { purchaseData }
    } = store.getState();

    return createAsyncAction(
      Types.SUBMIT_RAZORPAY_PURCHASE,
      Types.SUBMIT_RAZORPAY_PURCHASE_COMPLETE,
      () => orderService.submitRazorpayPurchase(purchaseData, razorpayResponse)
    );
  }
};

import { Button, ButtonProps } from '@udacity/veritas-components';
import React, { FC } from 'react';

export type CompleteOrderButtonProps = {
  selectedProvider?: string;
} & ButtonProps;

const CompleteOrderButton: FC<CompleteOrderButtonProps> = ({
  selectedProvider,
  ...buttonProps
}) => {
  switch (selectedProvider) {
    case 'stripe':
    case 'paypal':
      return <Button {...buttonProps} />;
    default:
      return null;
  }
};

export default CompleteOrderButton;

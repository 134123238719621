import React from 'react';
import { IconCaretDown } from '@udacity/veritas-icons';
import styles from './icon-caret.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const IconCaret = ({ rotated, color }) => (
  <div
    className={cx({
      icon: true,
      'icon--up': rotated,
      'icon--blue': color === 'blue',
      'icon--gray': color === 'gray'
    })}
  >
    <IconCaretDown />
  </div>
);

export default IconCaret;

import config from '../../config';
import HttpHelper from 'app/helpers/http-helper';

const api = new HttpHelper(config.REACT_APP_NOTIFICATION_PREF_API_URL);

export default {
  updateNotificationPref(userId, receiveSms) {
    const requestBody = {
      sms_opt_in: {
        payment_reminders: receiveSms
      }
    };
    return api.patch(`/users/${userId}/preferences`, requestBody);
  }
};

import constants from 'app/constants/constants';
import { PaymentProvider } from './payment-provider';
import _isObject from 'lodash/isObject';
import { CreditCardBrands } from '../credit-card-brands';
import { SavedCreditCard } from '../saved-credit-card';
import Payment from 'payment';
import { PurchaseDataKind } from './purchase-data-kind';

export class EbanxCardPaymentProvider extends PaymentProvider {
  get providerName(): string {
    return constants.providers.EBANX_CARD;
  }

  get paymentMethodType(): string {
    return 'ebanx_card';
  }

  requiresCardToken(): boolean {
    return true;
  }

  savesTokenData(): boolean {
    return true;
  }

  canAuthorizeCharge(): boolean {
    return true;
  }

  getPurchaseDataToSubmit(): PurchaseDataKind[] {
    return [
      PurchaseDataKind.COLLECTED_DATA,
      PurchaseDataKind.PAYMENT_METHOD_URN
    ];
  }
}

/**
 * Data stored in the Ebanx payment form
 */
export interface EbanxPaymentMethodData {
  name: string,
  expiry: string,
  cvc: string,
  cardNumber: string
  city: string
  cpf: string
  line2: string
  phoneNumber: string,
  postalCode: string
  region: string
  streetName: string
  streetNumber: string
}

export const INITIAL_FORM_VALUES: EbanxPaymentMethodData = {
  name: '',
  expiry: '',
  cvc: '',
  cardNumber: '',
  city: '',
  cpf: '',
  line2: '',
  phoneNumber: '',
  postalCode: '',
  region: '',
  streetName: '',
  streetNumber: ''
};

/**
 * Convert a token response object to a standard saved card object.
 * @param response Ebanx Token call response
 * @param name Cardholder's name
 */
export const convertToSavedCreditCard = (response: EbanxTokenSuccess, name: string): SavedCreditCard => {
  if (!response || !response.masked_card_number) {
    throw Error('error in card token response');
  }
  return {
    last4: response.masked_card_number.substring(response.masked_card_number.length - 4),
    name,
    brand: CreditCardBrands.fromEbanxType(response.payment_type_code)
  };
};

/**
 * When Ebanx tokenizes the credit card, its local JS first performs a simple check, and may return
 * an EbanxLocalTokenFail. If the local check passes, Ebanx calls the Tokenize endpoint. The endpoint
 * may return an EbanxRemoteTokenFail.
 */
type EbanxTokenFail = EbanxLocalTokenFail | EbanxRemoteTokenFail

/**
 * The tokenization failed response coming from the in-memory Ebanx.js. No API call was involved.
 */
interface EbanxLocalTokenFail {
  field: string
  message: string
  name: string
}

/**
 * The tokenization failed response coming from the Ebanx's API call.
 */
interface EbanxRemoteTokenFail {
  status: string
  status_code: string
  status_message: string
}

// The tokenization successful response
export interface EbanxTokenSuccess {
  deviceId: string
  masked_card_number: string
  payment_type_code: string
  token: string
}

// The raw response from the tokenization call
export type EbanxTokenResponse = {
  data: EbanxTokenSuccess | {},
  error: { err: EbanxTokenFail } | {}
}

/**
 * Type guard to determine if the error is thrown by JS or as a API call.
 */
const isLocalTokenFail = (err: EbanxTokenFail): err is EbanxLocalTokenFail => {
  return _isObject(err) && 'message' in err;
};

/**
 * Return the error message from an EbanxTokenFail
 */
export const extractTokenFailMessage = (err: EbanxTokenFail): string => {
  if (isLocalTokenFail(err)) {
    return err.message;
  } else {
    return err.status_message;
  }
};

/**
 * Call Ebanx API to tokenize a credit card.
 */
export const createEbanxToken = async (data: EbanxPaymentMethodData): Promise<EbanxTokenResponse> => {

  if (!window.EBANX) {
    throw new Error('Ebanx API is not initialized');
  }

  // Convert form data to the format Ebanx accepts
  const parsedDate = Payment.fns.cardExpiryVal(data.expiry);
  const expiry = `${parsedDate.month}/${parsedDate.year}`;
  const card = {
    card_name: data.name,
    card_number: data.cardNumber.replace(/\s/g, ''),
    card_due_date: expiry,
    card_cvv: data.cvc
  };

  const createTokenPromisified = (): Promise<EbanxTokenResponse> => {
    return new Promise((resolve, reject) => {
      window.EBANX.card.createToken(card, (data: any, error: any) => {
        if (error) {
          return reject(error);
        }
        resolve(data);
      });
    });
  };

  return createTokenPromisified();
};

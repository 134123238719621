import memoize from 'lodash/memoize';
import { reportError } from './sentry-helper';
import { Severity } from '@sentry/types';

export const FALLBACK_GEO_LOCATION = {
  'countryCode': 'US',
};

async function fetchGeoLocation(): Promise<object> {
  try {
    const response = await fetch('/api/geode');
    if (response.ok) {
      return response.json();
    }
  } catch (e) {
    reportError(e, {
      fingerprint: ['fetchGeoLocation error'],
      severity: Severity.Warning
    });
  }
  return FALLBACK_GEO_LOCATION;
}

const memoFn = memoize(fetchGeoLocation);

export async function getGeoLocation(): Promise<object> {
  return await memoFn();
}

export async function getCountryCode(): Promise<string> {
  const location = await getGeoLocation();
  return location['countryCode'];
}

/**
 * Get the state from zip code. Copied from https://stackoverflow.com/a/28823169
 *
 * @param zipCode 5 digit or Zip+4 format
 */
export function getState(zipCode?: string): string | undefined {

  if (!zipCode || zipCode.length < 5) {
    return undefined;
  }

  // Ensure we don't parse strings starting with 0 as octal values
  const code = parseInt(zipCode.substring(0, 5), 10);

  let state;

  // Code blocks alphabetized by state
  if (code >= 35000 && code <= 36999) {
    state = 'AL';
  } else if (code >= 99500 && code <= 99999) {
    state = 'AK';
  } else if (code >= 85000 && code <= 86999) {
    state = 'AZ';
  } else if (code >= 71600 && code <= 72999) {
    state = 'AR';
  } else if (code >= 90000 && code <= 96699) {
    state = 'CA';
  } else if (code >= 80000 && code <= 81999) {
    state = 'CO';
  } else if (code >= 6000 && code <= 6999) {
    state = 'CT';
  } else if (code >= 19700 && code <= 19999) {
    state = 'DE';
  } else if (code >= 32000 && code <= 34999) {
    state = 'FL';
  } else if (code >= 30000 && code <= 31999) {
    state = 'GA';
  } else if (code >= 96700 && code <= 96999) {
    state = 'HI';
  } else if (code >= 83200 && code <= 83999) {
    state = 'ID';
  } else if (code >= 60000 && code <= 62999) {
    state = 'IL';
  } else if (code >= 46000 && code <= 47999) {
    state = 'IN';
  } else if (code >= 50000 && code <= 52999) {
    state = 'IA';
  } else if (code >= 66000 && code <= 67999) {
    state = 'KS';
  } else if (code >= 40000 && code <= 42999) {
    state = 'KY';
  } else if (code >= 70000 && code <= 71599) {
    state = 'LA';
  } else if (code >= 3900 && code <= 4999) {
    state = 'ME';
  } else if (code >= 20600 && code <= 21999) {
    state = 'MD';
  } else if (code >= 1000 && code <= 2799) {
    state = 'MA';
  } else if (code >= 48000 && code <= 49999) {
    state = 'MI';
  } else if (code >= 55000 && code <= 56999) {
    state = 'MN';
  } else if (code >= 38600 && code <= 39999) {
    state = 'MS';
  } else if (code >= 63000 && code <= 65999) {
    state = 'MO';
  } else if (code >= 59000 && code <= 59999) {
    state = 'MT';
  } else if (code >= 27000 && code <= 28999) {
    state = 'NC';
  } else if (code >= 58000 && code <= 58999) {
    state = 'ND';
  } else if (code >= 68000 && code <= 69999) {
    state = 'NE';
  } else if (code >= 88900 && code <= 89999) {
    state = 'NV';
  } else if (code >= 3000 && code <= 3899) {
    state = 'NH';
  } else if (code >= 7000 && code <= 8999) {
    state = 'NJ';
  } else if (code >= 87000 && code <= 88499) {
    state = 'NM';
  } else if (code >= 10000 && code <= 14999) {
    state = 'NY';
  } else if (code >= 43000 && code <= 45999) {
    state = 'OH';
  } else if (code >= 73000 && code <= 74999) {
    state = 'OK';
  } else if (code >= 97000 && code <= 97999) {
    state = 'OR';
  } else if (code >= 15000 && code <= 19699) {
    state = 'PA';
  } else if (code >= 300 && code <= 999) {
    state = 'PR';
  } else if (code >= 2800 && code <= 2999) {
    state = 'RI';
  } else if (code >= 29000 && code <= 29999) {
    state = 'SC';
  } else if (code >= 57000 && code <= 57999) {
    state = 'SD';
  } else if (code >= 37000 && code <= 38599) {
    state = 'TN';
  } else if ((code >= 75000 && code <= 79999) || (code >= 88500 && code <= 88599)) {
    state = 'TX';
  } else if (code >= 84000 && code <= 84999) {
    state = 'UT';
  } else if (code >= 5000 && code <= 5999) {
    state = 'VT';
  } else if (code >= 22000 && code <= 24699) {
    state = 'VA';
  } else if (code >= 20000 && code <= 20599) {
    state = 'DC';
  } else if (code >= 98000 && code <= 99499) {
    state = 'WA';
  } else if (code >= 24700 && code <= 26999) {
    state = 'WV';
  } else if (code >= 53000 && code <= 54999) {
    state = 'WI';
  } else if (code >= 82000 && code <= 83199) {
    state = 'WY';
  }

  return state;
}

export const __TEST__ = {
  fetchGeoLocation
};

// Exports all env variables by default.
// This is a good place to add other global variables.
export default {
  ...process.env,

  // Add env vars that we need to use in typescript here,
  // otherwise TS complains that it doesn't exist on the config object.
  REACT_APP_AFFIRM_REDIRECT_URL: process.env.REACT_APP_AFFIRM_REDIRECT_URL,
  REACT_APP_BRAAVOS_API_URL: process.env.REACT_APP_BRAAVOS_API_URL,
  REACT_APP_CKO_API_KEY: process.env.REACT_APP_CKO_API_KEY,
  REACT_APP_EBANX_PUBLIC_KEY: process.env.REACT_APP_EBANX_PUBLIC_KEY,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_EXPERIMENTS_API_URL: process.env.REACT_APP_EXPERIMENTS_API_URL,
  REACT_APP_RAZORPAY_API_KEY: process.env.REACT_APP_RAZORPAY_API_KEY,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
  REACT_APP_RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
  REACT_APP_AUTH_WEB_URL: process.env.REACT_APP_AUTH_WEB_URL,

  isDevelopment() {
    return process.env.REACT_APP_ENV === 'development';
  },

  isStaging() {
    return process.env.REACT_APP_ENV === 'staging';
  },

  isProduction() {
    return process.env.REACT_APP_ENV === 'production';
  }
};

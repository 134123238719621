import React from 'react';
import PropTypes from 'prop-types';
import { IconWarning } from '@udacity/veritas-icons';

import { errorTypes } from 'app/helpers/error-helper';
import styles from './error-message.module.scss';

const ErrorMessage = ({ error }) => {
  const errorClass =
    error.type === errorTypes.FULL_PAGE
      ? styles.full_page_error
      : styles.error_message;
  const MessageTag = error.type === errorTypes.FULL_PAGE ? 'h1' : 'div';

  /* Message could contain anchors, hence the dangerouslySetInnerHTML */
  return (
    <div className={errorClass} data-testid="error-message-div">
      <div className={styles.error_icon}>
        <IconWarning title={'Error'} />
      </div>
      <MessageTag dangerouslySetInnerHTML={{ __html: error.message }} />
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
  }).isRequired
};

export default ErrorMessage;

import _get from 'lodash/get';
import errorHelper from 'app/helpers/error-helper';
import { CheckoutStrategies } from '../checkout-strategies';
import { IPaymentPlan } from './payment-plan';

export enum LineItemGroup {
  ABOVE_LINE = 0, // shown above the horizontal divider
  BELOW_LINE = 1, // shown below the horizontal divider
}

/**
 * Checkout Lines returned by Braavos
 */
export interface LineItem {
  key: string;
  description: string | undefined;
  value: number | undefined;
  value_display: string | undefined;
  group: LineItemGroup;
}

export interface AmountDisplay {
  amount: number;
  display: string;
}

export interface Coupon {
  code: string;
  description: string;
  discount: AmountDisplay;
  duration: 'once' | 'repeating' | 'forever';
  durationInMonths: number | undefined;
  percentOff: number | undefined;
}

interface BaseLineItems {
  subtotal: AmountDisplay;
  total: AmountDisplay;
  grandTotal: AmountDisplay;
}

export interface Promotion {
  [kind: string]: object;
}

export enum CampaignParticipation {
  JOIN = 'join',
  SKIP = 'skip'
}

export enum CheckoutStrategyType {
  // recurring refers to renewal orders
  RECURRING = 'recurring',
  // recurring_first refers to initial checkout orders
  RECURRING_FIRST = 'recurring_first'
}

export interface Order {
  name: string;
  category: string;
  nanodegreeKey: string;

  rechargeInvoiceUrn: string | null;

  skuUrn: string;
  checkoutStrategyType: CheckoutStrategyType;

  currency: string;

  baseLineItems: BaseLineItems;

  lineItems: LineItem[];

  coupon: Coupon | undefined;
  couponAllowed: boolean;

  autoRenewEnabled: boolean;
  // Payment methods available to the user. The values are listed in PaymentProvider.paymentMethodType.
  paymentOptions: string[];
  paymentPlan: IPaymentPlan;
  campaignParticipation: CampaignParticipation;
  promotion: Promotion;
}

export class Orders {
  static fromOrderHistorySubscription(subscription: any): Order {
    const productName = _get(subscription, 'next_payment.description');
    const product = _get(subscription, 'purchased_products[0]');
    const rechargeInvoiceUrn = _get(subscription, 'next_payment.urn');
    const invoiceStatus = _get(subscription, 'next_payment.status');

    if (invoiceStatus !== 'open' || !rechargeInvoiceUrn) {
      throw errorHelper.subscriptionIsClosed({ productName });
    }

    const totalAmount = _get(
      subscription,
      'next_payment.total_amount.minor',
      0
    );
    const totalDisplay = _get(
      subscription,
      'next_payment.total_amount.display'
    );

    const order: Order = {
      name: productName,
      category: _get(product, 'type') === 'NdUnit' ? 'nanodegree' : 'unknown',
      nanodegreeKey: _get(product, 'nanodegree_key'),

      rechargeInvoiceUrn,

      skuUrn: _get(product, 'urn'),
      checkoutStrategyType: CheckoutStrategyType.RECURRING,

      currency: _get(subscription, 'currency', '').toUpperCase(),
      lineItems: _get(subscription, 'renewal_line_items', []),
      baseLineItems: {
        subtotal: {
          amount: totalAmount,
          display: totalDisplay
        },
        total: {
          amount: totalAmount,
          display: totalDisplay
        },
        grandTotal: {
          amount: totalAmount,
          display: totalDisplay
        }
      },

      coupon: undefined,
      couponAllowed: false,

      autoRenewEnabled: _get(
        subscription,
        'next_payment.can_auto_renew',
        false
      ),
      paymentOptions: _get(subscription, 'available_payment_methods'),
      paymentPlan: _get(subscription, 'payment_plan'),
      campaignParticipation: CampaignParticipation.SKIP,
      promotion: {} // Not needed right now for recharge checkout page.
    };

    return order;
  }

  static fromPrice(price: any, checkoutInput: any): Order {
    const paymentPlans = _get(price, 'payment_plans', {});

    const paymentPlan: IPaymentPlan = checkoutInput['payment_plan'] === 'upfront_recurring' ?
      paymentPlans['upfront_recurring'] : paymentPlans['recurring'];

    const selectedAutoRenewState = _get(
      checkoutInput,
      'selectedAutoRenewState',
      false
    );
    const paymentPlanType = _get(paymentPlan, 'type');
    const checkoutStrategyType = _get(checkoutInput, 'checkoutStrategyType');
    const checkoutStrategy = CheckoutStrategies.fromStrategyType(
      checkoutStrategyType
    );

    const amountPath = checkoutStrategy.getSkuAmountPathForPaymentPlanType(
      paymentPlanType
    );
    const amountDetails = _get(price, amountPath);

    if (!amountDetails) {
      throw new Error(
        `Could not find price data for ${checkoutStrategyType} ${paymentPlanType}`
      );
    }

    const hasCoupon =
      _get(price, 'coupon.key') &&
      _get(price, 'price.coupon_applied', false) &&
      _get(price, 'price.coupon_applied_to_payment_plan', false);
    const coupon: Coupon | undefined = hasCoupon
      ? {
        discount: {
          amount: _get(amountDetails, 'discount_amount'),
          display: _get(amountDetails, 'discount_amount_display')
        },
        code: _get(price, 'coupon.key'),
        description: _get(price, 'coupon.metadata.description'),
        duration: _get(price, 'coupon.duration'),
        durationInMonths: _get(price, 'coupon.duration_in_months'),
        percentOff: _get(price, 'coupon.percent_off'),
      }
      : undefined;

    const campaignParticipation = (): CampaignParticipation => {
      const paramVal = _get(checkoutInput, 'campaign_participation', CampaignParticipation.JOIN); // join campaign by default
      return paramVal === CampaignParticipation.JOIN ? CampaignParticipation.JOIN : CampaignParticipation.SKIP;
    };

    const order: Order = {
      name: _get(price, 'sku.name'),
      category: _get(price, 'sku.metadata.category'),
      nanodegreeKey: _get(price, 'sku.metadata.sku'),

      rechargeInvoiceUrn: null,

      skuUrn: _get(price, 'sku.urn'),
      checkoutStrategyType: CheckoutStrategyType.RECURRING_FIRST,

      currency: _get(price, 'price.currency', '').toUpperCase(),
      lineItems: _get(price, 'line_items', []),
      baseLineItems: {
        subtotal: {
          amount: _get(amountDetails, 'original_amount'),
          display: _get(amountDetails, 'original_amount_display')
        },
        total: {
          amount: _get(amountDetails, 'payable_amount'),
          display: _get(amountDetails, 'payable_amount_display')
        },
        grandTotal: {
          amount: _get(amountDetails, 'payable_amount'),
          display: _get(amountDetails, 'payable_amount_display')
        }
      },

      coupon,
      couponAllowed:
        _get(price, 'sku.coupon_restrictions') !== 'disallow_coupons',

      autoRenewEnabled: selectedAutoRenewState,
      paymentOptions: _get(price, 'payment_methods'),
      paymentPlan,
      campaignParticipation: campaignParticipation(),
      promotion: _get(price, 'promotion')
    };

    return order;
  }
}

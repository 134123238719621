import constants from 'app/constants/constants';
import { PaymentProvider } from './payment-provider';
import _get from 'lodash/get';
import { Address } from 'app/models/address';
import { PurchaseDataKind } from './purchase-data-kind';

export class PaypalPaymentProvider extends PaymentProvider {
  get providerName(): string {
    return constants.providers.PAYPAL;
  }

  get paymentMethodType(): string {
    return 'braintree';
  }

  requiresCardToken(): boolean {
    return false;
  }

  getCreationData(providerData: Object): Object {
    if (!providerData) {
      return {};
    }
    return {
      'device_data': _get(providerData, 'deviceData'),
      'nonce': _get(providerData, 'nonce'),
      'payer_id': _get(providerData, 'payerId'),
      'shipping_address': _get(providerData, 'shippingAddress'),
      'is_vault_flow': true
    };
  }

  supportsRecurringPayments(): boolean {
    return true;
  }

  getAddress(paymentMethodData: any): Address {
    // Paypal does not expose billing address, only shipping address.
    const addressData = _get(
      paymentMethodData,
      'shippingAddress', {}
    );

    // PayPal address format is defined in https://braintree.github.io/braintree-web/current/PayPalCheckout.html#~tokenizePayload
    return new Address({
      line1: addressData.line1,
      line2: addressData.line2,
      city: addressData.city,
      region: addressData.state,
      country: addressData.countryCode,
      postalCode: addressData.postalCode
    });
  }

  canAuthorizeCharge(): boolean {
    return true;
  }

  getPurchaseDataToSubmit(): PurchaseDataKind[] {
    return [PurchaseDataKind.COLLECTED_DATA];
  }
}

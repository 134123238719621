import React from 'react';
import styles from './card.module.scss';

const Card = (props) => {
  return (
    <div className={styles.card}>
      <div className={styles.card_inner}>{props.children}</div>
    </div>
  );
};

export default Card;

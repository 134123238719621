import { createAction, createAsyncAction } from 'app/helpers/action-helper';
import { getGeoLocation } from 'app/helpers/geo-helper';
import Types from './types';

export default {
  resetError() {
    return createAction(Types.RESET_ERROR);
  },

  displayError(error) {
    return createAction(Types.DISPLAY_ERROR, error);
  },

  fetchUserGeoLocation() {
    return createAsyncAction(
      Types.FETCH_USER_GEO_LOCATION,
      Types.FETCH_USER_GEO_LOCATION_COMPLETE,
      () => {
        return getGeoLocation();
      }
    );
  }
};

import React from 'react';
import { Redirect } from 'react-router-dom';

export const withAuthGuard = (Component: any): any => (props: any): any => {
  if (props.isLoading) return null;
  if (!props.isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/auth',
          search: window.location.search
        }}
      />
    );
  }
  return <Component {...props} />;
};

export const withNotAuthGuard = (Component: any): any => (props: any): any => {
  if (props.isLoading) return null;
  if (props.isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/',
          search: window.location.search
        }}
      />
    );
  }
  return <Component {...props} />;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from '@udacity/veritas-components';

import redirectHelper from 'app/helpers/redirect-helper';
import styles from './order-confirmation.module.scss';
import ErrorMessage from 'app/components/common/error-message';
import PhoneNumberEditor from 'app/components/phone-number-editor';
import constants from 'app/constants/constants';
import { reportError } from 'app/helpers/sentry-helper';

class OrderConfirmation extends Component {
  static propTypes = {
    country: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    ndName: PropTypes.string.isRequired,
    ndKey: PropTypes.string.isRequired,
    askPhoneNumber: PropTypes.bool.isRequired,
    updatePhoneNumber: PropTypes.func.isRequired,
    updateNotificationPref: PropTypes.func.isRequired
  };

  state = {
    receiveSms: false,
    phoneNumber: '',
    phoneNumberChanged: false,
    phoneNumberValid: false,
    error: ''
  };

  componentDidMount() {
    const existingPhoneNumber = this.props.user.phoneNumber || '';
    if (existingPhoneNumber) {
      this.setState({
        phoneNumber: existingPhoneNumber
      });
    }
  }

  handlePreferenceChange = (evt) => {
    const pref = evt.target.id;
    const checked = evt.target.checked;
    this.setState({ [pref]: checked });
  };

  handleSaveContactPref = async (phoneNumber, receiveSms) => {
    const {
      user,
      updatePhoneNumber,
      updateNotificationPref,
      ndKey
    } = this.props;
    try {
      await updatePhoneNumber(phoneNumber);
      await updateNotificationPref(user.userId, receiveSms);
      redirectHelper.goToClassroom(ndKey);
    } catch (e) {
      this.setState({ error: { type: '', message: e.message } });
      reportError(e);
    }
  };

  handleUpdatePhoneNumber = (phoneNumber) => {
    this.setState({
      phoneNumber: phoneNumber,
      phoneNumberChanged: true
    });
  };

  renderPhoneNumber = () => {
    const existingNumber = this.props.user.phoneNumber;
    const newNumber = this.state.phoneNumber;
    const numberToDisplay = this.state.phoneNumberChanged
      ? newNumber
      : existingNumber;

    const { error } = this.state;
    const country = this.props.country;
    return (
      <div className={styles.phone_number_editor}>
        <p className={styles.emphasis}>{'Please enter your phone number'}</p>
        <PhoneNumberEditor
          country={country}
          inputClassName={''}
          onChange={this.handleUpdatePhoneNumber}
          value={numberToDisplay}
        />

        {error && <ErrorMessage error={error} />}
      </div>
    );
  };

  renderContinueButton = () => {
    const { ndKey } = this.props;
    return (
      <Button
        variant="primary"
        onClick={() => redirectHelper.goToClassroom(ndKey)}
        label={'Start Learning'}
      />
    );
  };

  renderSaveButton = () => {
    const { receiveSms, phoneNumber } = this.state;
    return (
      <Button
        variant="primary"
        onClick={() => this.handleSaveContactPref(phoneNumber, receiveSms)}
        label="Start Learning"
      />
    );
  };

  renderPhoneNumberCapture = () => {
    const { receiveSms } = this.state;
    const showPhoneNumberInput = receiveSms;

    return (
      <div>
        <Checkbox
          label={'Send me SMS text message payment reminders.'}
          checked={receiveSms}
          id="receiveSms"
          onChange={this.handlePreferenceChange}
        />
        {showPhoneNumberInput && this.renderPhoneNumber()}
        <p className={styles.light}>
          Message and call rates apply. Reply STOP to end. For further
          information on Udacity&apos;s privacy practices see{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
            href={constants.links.TERMS}
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
            href={constants.links.PRIVACY}
          >
            Privacy Policy
          </a>
          .
        </p>
        {showPhoneNumberInput
          ? this.renderSaveButton()
          : this.renderContinueButton()}
      </div>
    );
  };

  render() {
    const { user, ndName, askPhoneNumber } = this.props;
    const welcomeMsg = `${user.firstName} You are now enrolled in the ${ndName}!`;

    return (
      <div className={styles.order_confirmation}>
        <div className={styles.order_header}>
          <h2 className={styles.header_text}>{welcomeMsg}</h2>
        </div>

        <div className={styles.order_body}>
          {askPhoneNumber
            ? this.renderPhoneNumberCapture()
            : this.renderContinueButton()}
        </div>
      </div>
    );
  }
}

export default OrderConfirmation;


type AmountSelector =
  'discount'
  | 'original'
  | 'payable'
  | 'promo_discount'
  | 'strikeout'
  | 'upfront_discount';

export class AmountAtom {

  constructor(
    readonly currency: string,
    readonly amount: number,
    readonly display: string) {
  }

  // Builder for upfront_amount and recurring_amount amounts.
  public static fromAmount(amount: Amount, selector: AmountSelector): AmountAtom {
    return {
      currency: amount.currency,
      amount: amount[selector + '_amount'],
      display: amount[selector + '_amount_display']
    };
  }
}

interface Amount {
  currency: string;
  discount_amount: number;
  discount_amount_display: string;
  original_amount: number;
  original_amount_display: string;
  payable_amount: number;
  payable_amount_display: string;
  strikeout_amount: number;
  strikeout_amount_display: string;
}

export enum PaymentPlanType {
  recurring = 'recurring',
  upfrontRecurring = 'upfront_recurring',
}

/**
 * Shared portion of payment plans.
 */
interface BasePlan {
  description: string;
  next_due_date: string;
  upfront_amount: Amount;
  upfront_interval: string;
  upfront_interval_count: number;
  recurring_amount: Amount;
  recurring_interval: string;
  recurring_interval_count: number;
}

/**
 * A recurring payment plan
 */
export interface RecurringPlan extends BasePlan {
  type: PaymentPlanType.recurring;
}

/**
 * An upfront_recurring payment plan
 */
interface UpfrontRecurringPlan extends BasePlan {
  type: PaymentPlanType.upfrontRecurring;
}

/**
 * An upfront_recurring payment plan data that includes attributes to show the upfront savings
 * compared to purchasing the recurring plan for the upfront duration.
 *
 * If the SKU does not have a recurring payment plan, then upfront_discount is 0.
 */
interface AugmentedUpfrontRecurringPlan extends UpfrontRecurringPlan {
  type: PaymentPlanType.upfrontRecurring;
  upfront_discount: number;
  upfront_discount_per_interval: number;
  upfront_subtotal: number;
  upfront_subtotal_display: string;
  upfront_discount_display: string;
  upfront_amount_per_interval: number;
  upfront_amount_per_interval_display: string;
  upfront_discount_per_interval_display: string;
  upfront_payable_amount_per_interval: number;
  upfront_payable_amount_per_interval_display: string;
  upfront_recurring_discount_percentage: number;
}

export type IPaymentPlan = RecurringPlan | UpfrontRecurringPlan | AugmentedUpfrontRecurringPlan;

export const isAugmentedUpfrontRecurringPlan = (p: IPaymentPlan): p is AugmentedUpfrontRecurringPlan => {
  return 'upfront_discount' in p;
};

export class PaymentPlan {
  public readonly data: IPaymentPlan;

  constructor(plan: IPaymentPlan) {
    this.data = plan;
  }

  getLengthDesc = (): string => {
    switch (this.data.type) {
      case PaymentPlanType.recurring:
        return '1 month access';
      case PaymentPlanType.upfrontRecurring: {
        const durationUnit = this.data.upfront_interval_count === 1 ? this.data.upfront_interval : this.data.upfront_interval + 's';
        return `${this.data.upfront_interval_count} ${durationUnit} access`;
      }
    }
  };

  /**
   * Return the Base Price that should be displayed.
   *
   * If the plan is recurring, then return its original price.
   *
   * If the plan is upfront, then there are two possibilities:
   *  1. If the sku is offered monthly then set base amount to be the amount one would have paid had
   *  they purchased the months separately.
   *  2. If the sku is bundle only, then simply return the bundle's actual price.
   *
   * @param type type of payment plan
   */
  getBaseAmount = (type: PaymentPlanType): AmountAtom => {
    switch (type) {
      case PaymentPlanType.recurring: {
        const recurringAmount = this.data.recurring_amount;
        return AmountAtom.fromAmount(recurringAmount, 'original');
      }
      case PaymentPlanType.upfrontRecurring: {
        const {
          upfront_discount,
          upfront_subtotal,
          upfront_subtotal_display
        } = this.data as AugmentedUpfrontRecurringPlan;
        if (upfront_discount > 0) {
          return new AmountAtom(this.data.upfront_amount.currency, upfront_subtotal, upfront_subtotal_display);
        } else {
          return AmountAtom.fromAmount(this.data.upfront_amount, 'original');
        }
      }
    }
  };
}

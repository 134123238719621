import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../../config';
import constants from '../../constants/constants';
import ProviderButton from './_provider-button';
import styles from './index.module.scss';
import analytics from 'app/helpers/analytics-helper';
import { CRUMB_MAP, PageId } from 'app/components/common/breadcrumb-bar';

export default class ProviderButtons extends Component {
  static displayName = 'components/provider-buttons';

  static propTypes = {
    next: PropTypes.string.isRequired,
    type: PropTypes.oneOf([constants.auth.SIGN_UP, constants.auth.SIGN_IN])
      .isRequired,
    providers: PropTypes.arrayOf(PropTypes.string).isRequired
  };

  state = {
    provider: ''
  };

  _isSignUp() {
    return this.props.type === constants.auth.SIGN_UP;
  }

  _getUrl() {
    return `${config.REACT_APP_USER_API_URL}/oauth/${
      this._isSignUp() ? 'signup' : 'signin'
    }`;
  }

  _getProviderLabel(provider) {
    switch (provider) {
      case constants.authProviders.FACEBOOK:
        return 'Facebook';
      case constants.authProviders.GOOGLE:
        return 'Google';
      default:
        return '';
    }
  }

  handleProviderClick = (provider) => {
    const intention = this._isSignUp() ? 'sign_up' : 'sign_in';
    analytics.trackAction(
      `${intention}_with_${provider}_button_clicked`,
      CRUMB_MAP[PageId.authentication].analyticsName
    );
    return this.setState({ provider }, () => this.refs.providerForm.submit());
  };

  render() {
    let { provider } = this.state;

    let { next, providers } = this.props;

    return (
      <div className={styles.formContainer}>
        <form method="post" action={this._getUrl()} ref="providerForm">
          <input type="hidden" name="next" value={next} />
          <input type="hidden" name="provider" value={provider} />
        </form>

        <div className={styles.buttons}>
          {providers.map((provider) => {
            const providerLabel = this._getProviderLabel(provider);
            const label = this._isSignUp()
              ? `Sign up with ${providerLabel}`
              : `Sign in with ${providerLabel}`;
            return (
              <ProviderButton
                key={provider}
                onClick={this.handleProviderClick}
                provider={provider}
                label={label}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

import { reportError } from 'app/helpers/sentry-helper';
import { CreditCardBrand } from './credit-card-brand';

class CreditCardBrands {
  static readonly amex = new CreditCardBrand(
    'American Express',
    'american-express'
  );
  static readonly diners = new CreditCardBrand('Diners Club', 'diners-club');
  static readonly discover = new CreditCardBrand('Discover', 'discover');
  static readonly jcb = new CreditCardBrand('JCB', 'jcb');
  static readonly mastercard = new CreditCardBrand('MasterCard', 'mastercard');
  static readonly unionPay = new CreditCardBrand('UnionPay', 'union-pay');
  static readonly visa = new CreditCardBrand('Visa', 'visa');
  static readonly unknown = new CreditCardBrand('Unknown', 'unknown');

  /**
   * Convert the Stripe brand to a CreditCardBrand. Use this for saved cards.
   * @param brand one of the brand string from https://stripe.com/docs/api/cards/object#card_object-brand
   * @return brand object, 'unknown' brand if not found
   */
  static fromBrand(brand: string): CreditCardBrand {
    const creditCardBrand = CreditCardBrands.brandsByType[brand];

    if (creditCardBrand) {
      return creditCardBrand;
    } else {
      reportError(new Error(`the credit card brand ${brand} is not recognized`));
      return this.unknown;
    }
  }

  /**
   * Convert the Ebanx payment_type_code to a CreditCardBrand. Use this for saved cards.
   * @param ebanxType one of the payment_type_code string from https://ebanx.github.io/api-reference/#operation/token
   * @return brand object, 'unknown' brand if not found
   */
  static fromEbanxType(ebanxType: string): CreditCardBrand {
    // 'diners' is the only name that's different from our names
    const ourType = ebanxType === 'diners' ? CreditCardBrands.diners.type : ebanxType;
    return this.fromPaymentLibType(ourType);
  }

  /**
   * Convert the type from the payment form to a CreditCardBrand. Use this for form data.
   * @param type one of the type in https://github.com/jessepollak/payment/blob/master/src/index.coffee
   */
  static fromPaymentLibType(type: string): CreditCardBrand {
    const creditCardBrand = CreditCardBrands.brandsByPaymentLibType[type];

    return creditCardBrand ? creditCardBrand : this.unknown;
  }

  private static brandsByType: { [brandName: string]: CreditCardBrand } = {
    [CreditCardBrands.amex.type]: CreditCardBrands.amex,
    [CreditCardBrands.diners.type]: CreditCardBrands.diners,
    [CreditCardBrands.discover.type]: CreditCardBrands.discover,
    [CreditCardBrands.jcb.type]: CreditCardBrands.jcb,
    [CreditCardBrands.mastercard.type]: CreditCardBrands.mastercard,
    [CreditCardBrands.unionPay.type]: CreditCardBrands.unionPay,
    [CreditCardBrands.visa.type]: CreditCardBrands.visa,
    [CreditCardBrands.unknown.type]: CreditCardBrands.unknown
  };

  private static brandsByPaymentLibType: { [type: string]: CreditCardBrand } = {
    'amex': CreditCardBrands.amex,
    'dinersclub': CreditCardBrands.diners,
    'discover': CreditCardBrands.discover,
    'jcb': CreditCardBrands.jcb,
    'mastercard': CreditCardBrands.mastercard,
    'unionpay': CreditCardBrands.unionPay,
    'visa': CreditCardBrands.visa,
    'unknown': CreditCardBrands.unknown
  };

}

export { CreditCardBrands, CreditCardBrand };

import get from 'lodash/get';
import orderService from 'app/services/order-service';
import { createAsyncAction } from 'app/helpers/action-helper';
import errorHelper from 'app/helpers/error-helper';
import Types from './types';
import store from 'app/store';

const handleInvoiceError = (error) => {
  if (errorHelper.isApplicationError(error)) {
    return { error };
  }

  let message = null;
  if (error && error.status === 401) {
    message = 'You must sign in to view this page.';
  }

  return { error: errorHelper.invoiceFailedError({ cause: error, message }) };
};

export default {
  fetchInvoiceData(invoiceUrn) {
    const userId = get(store.getState(), 'user.user.userId');

    return createAsyncAction(
      Types.FETCH_INVOICE_DATA,
      Types.FETCH_INVOICE_DATA_COMPLETE,
      () => {
        return orderService
          .fetchInvoiceData({ invoiceUrn, userId })
          .catch(handleInvoiceError);
      }
    );
  }
};

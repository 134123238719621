import _ from 'lodash';
import Actions from '../actions';

export function actionsBinder(...actionNames) {
  /* Bind action creators to dispatch. This is intended to be used as the `mapDispatchToProps`, example:
     ```
     var mapDispatchToProps = actionsBinder('fetchNanodegree', 'addAlert')
     ```
     And now in the connected component, `this.props.fetchNanodegree()` and `this.props.addAlert()`
     are available.
  */
  return (dispatch) => {
    return _.reduce(
      actionNames,
      (bound, actionName) => {
        var actionCreator = Actions[actionName];
        if (!_.isFunction(actionCreator)) {
          throw new Error(`Unknown action creator: ${actionName}`);
        }

        bound[actionName] = function() {
          return dispatch(actionCreator.apply(Actions, arguments));
        };

        return bound;
      },
      {}
    );
  };
}

export function createAction(type, payload) {
  const error = payload instanceof Error;
  return {
    type,
    payload: error
      ? {
          stack: payload.stack,
          message: payload.message,
          status: payload.status,
          type: payload.type || null
        }
      : payload,
    error
  };
}

export function createAsyncAction(startType, completeType, asyncFn) {
  return (dispatch) => {
    dispatch(createAction(startType));

    let actionCompleted = _.curry(createAction)(completeType);
    return asyncFn(dispatch)
      .then((data) => {
        dispatch(actionCompleted(data));
        return data;
      })
      .catch((error) => {
        dispatch(actionCompleted(error));
        throw error;
      });
  };
}

import _ from 'lodash';

import errorHelper from 'app/helpers/error-helper';
import { reportError } from 'app/helpers/sentry-helper';
import redirectHelper from 'app/helpers/redirect-helper';
import URN from 'app/helpers/urn-helper';
import braavosService from 'app/services/braavos-service';
import { CheckoutStrategies } from 'app/models/checkout-strategies';
import { PaymentProviders } from 'app/models/payment-providers';
import { CreditCardBrands } from 'app/models/credit-card-brands';
import { Orders } from 'app/models/orders';
import constants from 'app/constants/constants';
import experimentsService from 'app/services/experiments-service';
import { getZeroAmountDisplay, getTrialData } from 'app/models/promotions';

const PROVIDERS = constants.providers;

const ALLOWED_ORDER_PARAMS = [
  'campaign_name',
  'campaign_participation',
  'checkoutStrategyType',
  'coupon',
  'payment_method',
  'payment_plan',
  'rechargeSubscriptionUrn',
  'referrer',
  'selectedAutoRenewState',
  'sku',
  'talkable_visitor_uuid',
  'userId'
];

const PARAMS_TO_ADD_TO_PURCHASE_METADATA = [
  'referrer',
  'talkable_visitor_uuid'
];

const buildOrderFromSubscription = async (orderInput) => {
  const { userId, rechargeSubscriptionUrn: urn } = orderInput;
  const subscription = await braavosService.getOrderHistory(userId, urn);

  return {
    ...Orders.fromOrderHistorySubscription(subscription, orderInput),
    input: orderInput
  };
};

const buildOrderFromSkuPrice = async (orderInput) => {
  const priceInput = {
    user_id: orderInput.userId,
    anonymous_id: await experimentsService.getAnonymousId()
  };

  const toUrn = (prefix, id) => {
    if (_.startsWith(id, prefix)) {
      return id;
    }

    return `${prefix}:${id}`;
  };

  if (orderInput.campaign_name) {
    priceInput.campaign_name = orderInput.campaign_name;
  }

  if (orderInput.coupon) {
    priceInput.coupon = orderInput.coupon;
  }

  if (orderInput.payment_plan) {
    priceInput.payment_plan_type = orderInput.payment_plan;
  }

  if (orderInput.campaign_participation) {
    priceInput.campaign_participation = orderInput.campaign_participation;
  }

  priceInput.timezone_offset = new Date().getTimezoneOffset();

  if (orderInput.sku) {
    priceInput.sku = toUrn('urn:x-udacity:sku:simple', orderInput.sku);
  } else {
    throw errorHelper.invalidSkuError();
  }

  const price = await braavosService.getPrice(priceInput);
  const newOrderInput = {
    ...orderInput,
    sku: _.get(price, 'sku.urn')
  };

  const order = Orders.fromPrice(price, newOrderInput);

  return {
    ...order,
    input: newOrderInput
  };
};

const mergeOrderInput = (newInput = {}, previousInput = {}) => {
  const allowed = (v, name) => ALLOWED_ORDER_PARAMS.includes(name);
  const input = _.pickBy({ ...previousInput, ...newInput }, allowed);

  return input;
};

const getExistingCardData = (paymentMethod) => {
  const card = _.get(paymentMethod, 'saved_credit_card', {});

  if (_.isEmpty(card)) {
    return null;
  }

  const brand = CreditCardBrands.fromBrand(card.brand);
  const addr = _.get(card, 'address', {});

  return {
    line1: addr.line1,
    line2: addr.line2,
    city: addr.city,
    country: addr.country,
    region: addr.region,
    postalCode: addr.postal_code,
    last4: card.last4,
    name: card.name,
    brand: brand
  };
};

const availablePaymentMethodsForOrder = (order, existingData) => {
  return order.paymentOptions.map((methodType) => {
    const provider = PaymentProviders.fromPaymentMethodType(methodType);
    const matchingExistingData = existingData.find((method) => {
      const type = URN.fromString(method.urn).subtype;
      return methodType === type;
    });
    const savedCard = getExistingCardData(matchingExistingData);

    return {
      data: {
        card: savedCard,
        valid: !_.isEmpty(savedCard),
        urn: _.get(matchingExistingData, 'urn')
      },
      provider: provider.providerName
    };
  });
};

const getPaymentMethodUrn = (userId, providerObj, providerData) => {
  try {
    const existingUrn = _.get(providerData, 'urn');

    if (existingUrn) {
      return Promise.resolve(existingUrn);
    }
    return braavosService
      .createPaymentMethod(userId, providerObj, undefined, {}, providerData)
      .then((response) => _.get(response, 'payment_method.urn'));
  } catch (error) {
    return Promise.reject(error);
  }
};

const buildPurchaseSkuInput = ({
  order,
  paymentMethodUrn,
  provider,
  anonymousId,
  experiments
}) => {
  const skuUrn = _.get(order, 'input.sku');
  const coupon = _.get(order, 'coupon.code');
  const paymentPlanType = _.get(order, 'paymentPlan.type');
  const campaignParticipation = _.get(
    order,
    'input.campaign_participation',
    'join'
  );
  const expectedPrice = _.get(order, 'baseLineItems.grandTotal.amount');
  const metadata = _.reduce(
    PARAMS_TO_ADD_TO_PURCHASE_METADATA,
    (meta, param) => {
      const value = _.get(order, ['input', param]);
      if (!_.isEmpty(value)) {
        meta[param] = value;
      }
      return meta;
    },
    {}
  );
  const promotionKeys = Object.keys(order.promotion);
  metadata.segment_anonymous_id = anonymousId;
  metadata.optimizely_experiments = experiments;

  const inputData = {
    sku: { urn: skuUrn }
  };

  if (paymentPlanType) {
    inputData.payment_plan_type = paymentPlanType;
  }

  if (campaignParticipation === 'join' && promotionKeys.length > 0) {
    inputData.campaign_name = promotionKeys[0];
  }

  if (provider) {
    const paymentProvider = PaymentProviders.fromProviderType(provider);
    if (paymentProvider.supportsRecurringPayments()) {
      inputData.auto_renew = true;
    }
  }

  if (paymentMethodUrn) {
    inputData.payment_method = { urn: paymentMethodUrn };
  }

  if (metadata) {
    inputData.metadata = metadata;
  }

  if (coupon) {
    inputData.coupon_code = coupon;
  }

  if (expectedPrice) {
    inputData.expected_price = expectedPrice;
  }

  return inputData;
};

const purchaseSku = ({
  order,
  data: paymentMethodData,
  paymentMethodUrn,
  provider,
  anonymousId,
  experiments
}) => {
  const userId = _.get(order, 'input.userId');
  const checkoutStrategyType = _.get(order, 'input.checkoutStrategyType');

  const inputData = buildPurchaseSkuInput({
    order,
    paymentMethodData,
    paymentMethodUrn,
    provider,
    anonymousId,
    experiments
  });

  const checkoutStrategy = CheckoutStrategies.fromStrategyType(
    checkoutStrategyType
  );

  return checkoutStrategy.createPurchase(userId, inputData);
};

const checkCanPurchase = async (checkoutStrategy, order, userId) => {
  const canPurchase = { canPurchase: true };
  const inputData = buildPurchaseSkuInput({ order });

  const response = await checkoutStrategy.validatePurchase(userId, inputData);

  if (response && response.issues) {
    // The subscription/validate API will return an issue with
    // code == REDIRECT_TO_CLASSROOM if the term has already been purchased
    if (
      _.some(
        response.issues,
        (issue) => _.get(issue, 'code') === 'REDIRECT_TO_CLASSROOM'
      )
    ) {
      return { canPurchase: false, alreadyPurchased: true, ...response };
    }
  }

  return canPurchase;
};

const validateOrder = async (order, userId) => {
  const checkoutStrategy = CheckoutStrategies.fromStrategyType(
    order.input.checkoutStrategyType
  );

  if (checkoutStrategy.validatePurchase) {
    const orderValidation = await checkCanPurchase(
      checkoutStrategy,
      order,
      userId
    );

    if (orderValidation.alreadyPurchased) {
      redirectHelper.goToClassroom(order.nanodegreeKey);
      return { isRedirectingToClassroom: true };
    }

    if (!orderValidation.canPurchase) {
      const message = _.get(orderValidation, 'issues[0].title');
      const error = new Error(message);
      error.name = 'ValidatePurchaseError';
      reportError(error, { severity: 'warning' });
      throw error;
    }
  }
  return null;
};

const ebanxBillingDetails = (user, paymentMethodData) => {
  const zipcode = paymentMethodData.postalCode.replace(/[^\d]/g, '');

  return {
    user_info: {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      document: paymentMethodData.cpf,
      phone_number: paymentMethodData.phoneNumber,
      zipcode: zipcode,
      address: paymentMethodData.streetName,
      street_number: paymentMethodData.streetNumber,
      street_complement: paymentMethodData.line2,
      city: paymentMethodData.city,
      state: paymentMethodData.region,
      country: 'br'
    }
  };
};

const getAuthorizationData = (order, provider, data, user) => {
  switch (provider) {
    case PROVIDERS.STRIPE:
      return { authorization: null };
    case PROVIDERS.CKO:
      return {
        authorization: data['tokenResponse']['token'],
        use_3dsecure: true
      };
    case PROVIDERS.PAYPAL:
      return { authorization: data.nonce };
    case PROVIDERS.EBANX_CARD:
      return ebanxBillingDetails(user, data);
    default:
      return null;
  }
};

const handleSelectedAutoRenewState = (previousOrder, ackAutoRenew) => {
  return {
    ...previousOrder,
    input: {
      ...previousOrder.input,
      ackAutoRenew
    }
  };
};

export default {
  async buildOrder({ orderInput, previousOrder = null, userId = null }) {
    // If the input is ackAutoRenew just update the order state without calling full buildOrder flow
    if (previousOrder && _.has(orderInput, 'ackAutoRenew')) {
      return handleSelectedAutoRenewState(
        previousOrder,
        orderInput.ackAutoRenew
      );
    }

    const input = mergeOrderInput(orderInput, _.get(previousOrder, 'input'));
    input.userId = userId;

    const orderBuilder = input.rechargeSubscriptionUrn
      ? buildOrderFromSubscription
      : buildOrderFromSkuPrice;

    const order = await orderBuilder(input);
    let braavosPaymentMethods = [];

    if (userId) {
      braavosPaymentMethods = await braavosService.getPaymentMethods(userId);
      const invalid = await validateOrder(order, userId);
      if (invalid) {
        return invalid;
      }
    }

    const availablePaymentMethods = availablePaymentMethodsForOrder(
      order,
      braavosPaymentMethods
    );

    return {
      ...order,
      availablePaymentMethods
    };
  },

  async updateOrderWithUser(order, user) {
    await validateOrder(order, user.userId);
    const braavosPaymentMethods = await braavosService.getPaymentMethods(
      user.userId
    );
    const availablePaymentMethods = availablePaymentMethodsForOrder(
      order,
      braavosPaymentMethods
    );

    return {
      ...order,
      input: {
        ...order.input,
        // TODO: Stop using userId from order input....but we need it for now,
        // so update it
        userId: user.userId
      },
      availablePaymentMethods
    };
  },

  /**
   * Return the created purchase and its active invoice urn.
   * @param anonymousId Segment anonymous id
   * @param experiments the experiment object in the format Braavos' metadata expects.
   */
  async submitPurchase(
    order,
    user,
    anonymousId,
    experiments,
    { data, provider }
  ) {
    const userId = user.userId;

    const providerObj = provider
      ? PaymentProviders.fromProviderType(provider)
      : null;
    const paymentMethodUrn = provider
      ? await getPaymentMethodUrn(userId, providerObj, data)
      : null;

    if (!providerObj || !paymentMethodUrn) {
      throw new Error('Missing payment method');
    }

    const checkoutStrategyType = _.get(order, 'input.checkoutStrategyType');
    const checkoutStrategy = CheckoutStrategies.fromStrategyType(
      checkoutStrategyType
    );

    const createPurchaseAndGetActivateInvoice = async () => {
      const purchase = await purchaseSku({
        order,
        data,
        paymentMethodUrn,
        provider,
        anonymousId,
        experiments
      });
      const invoiceUrn = _.get(purchase, 'active_invoice.urn');

      if (!getTrialData(order)) {
        checkAmount(order, purchase.active_invoice, anonymousId);
      }

      if (!invoiceUrn) {
        throw new Error('Purchase missing invoice object');
      }

      return { purchase, invoiceUrn };
    };

    const checkAmount = (order, invoice, anonymousId) => {
      if (!order || !invoice) {
        return;
      }
      const expected = _.get(order, 'baseLineItems.grandTotal.amount');
      const actual = invoice.amount;
      if (expected !== actual) {
        const error = new Error(
          `The invoice amount ${actual} does not match expected amount ${expected}.`
        );
        error.name = 'AmountMismatchError';
        error.endUserMessage =
          'We are having difficulties processing your order. If this error keeps appearing please contact payment-support@udacity.com. Error code: 142.';
        error.extraData = {
          invoice_urn: invoice.urn,
          invoiceable_urn: invoice.subject,
          anonymous_id: anonymousId
        };
        throw error;
      }
    };

    const getSubscriptionUpdatePaymentMethodAndGetActiveInvoice = async () => {
      const subscriptionUrn = _.get(order, 'input.rechargeSubscriptionUrn');

      if (!subscriptionUrn) {
        throw new Error('Missing required rechargeSubscriptionUrn input');
      }

      await braavosService.updateSubscription(
        subscriptionUrn,
        paymentMethodUrn
      );

      const subscription = await braavosService.getOrderHistory(
        userId,
        subscriptionUrn
      );
      const invoiceUrn = _.get(subscription, 'next_payment.urn');

      if (!invoiceUrn) {
        throw new Error('Subscription is missing invoice object');
      }

      return { purchase: subscription, invoiceUrn };
    };

    const canPurchase = !!checkoutStrategy.createPurchase;
    const purchaseData = await (canPurchase
      ? createPurchaseAndGetActivateInvoice()
      : getSubscriptionUpdatePaymentMethodAndGetActiveInvoice());

    const invoice = await braavosService.updateInvoice(
      purchaseData.invoiceUrn,
      { status: 'paid' }
    );
    const activeCharge = invoice.charge;

    const invoiceData = { purchase: purchaseData.purchase, invoice };

    if (!activeCharge) {
      return invoiceData;
    }

    if (!providerObj.canAuthorizeCharge()) {
      return { ...invoiceData, charge: activeCharge };
    }

    const authorizationData = getAuthorizationData(order, provider, data, user);

    const charge = await braavosService.updateCharge(
      activeCharge.urn,
      authorizationData
    );
    const redirectUrl = _.get(charge, 'provider_data.redirect_url');

    if (redirectUrl) {
      return { redirectUrl };
    } else {
      return { ...invoiceData, charge };
    }
  },

  getRazorpayOrderId(order, user, anonymousId, experiments) {
    return this.submitPurchase(order, user, anonymousId, experiments, {
      provider: PROVIDERS.RAZORPAY
    });
  },

  /**
   * Mark the charge to be paid.
   * @param purchaseData the created purchase object
   * @param razorpayResponse response from Razorpay modal
   */
  async submitRazorpayPurchase(purchaseData, razorpayResponse) {
    const chargeUrn = _.get(purchaseData, 'charge.urn');
    const paymentId = razorpayResponse['razorpay_payment_id'];
    await braavosService.updateCharge(chargeUrn, {
      authorization: paymentId
    });
  },

  async fetchInvoiceData({ userId, invoiceUrn }) {
    if (!userId) {
      return null;
    }

    const isFlexSubscriptionUrn = (urn) =>
      _.startsWith(urn, 'urn:x-udacity:subscription:flex:');

    const invoice = await braavosService.getInvoice(invoiceUrn);
    const currency = _.get(invoice, 'currency');
    const subjectUrn = _.get(invoice, 'subject');
    const isFlexSubscription = isFlexSubscriptionUrn(subjectUrn);
    const subscription = isFlexSubscription
      ? await braavosService.getSubscription(subjectUrn)
      : null;

    return {
      name: _.get(invoice, 'sku.name'),
      ndKey: _.get(invoice, 'sku.metadata.sku', null),
      askForPhoneNumber: isFlexSubscription,
      autoRenew: _.get(subscription, 'auto_charge'),
      currency,
      freeTrialFirstInvoice: _.get(
        invoice,
        'metadata.free_trial_first_invoice',
        false
      ),
      nextChargeDueDate: _.get(subscription, 'active_invoice.due_date'),
      nextChargeAmountDisplay: _.get(
        subscription,
        'next_charge.amount_display'
      ),
      periodStartDate: _.get(invoice, 'period_start_date'),
      periodEndDate: _.get(invoice, 'period_end_date'),
      grandTotal: {
        amount: _.get(invoice, 'charge.amount', 0),
        display: _.get(
          invoice,
          'charge.amount_display',
          getZeroAmountDisplay(currency)
        )
      },
      invoiceableUrn: _.get(invoice, 'subject'),
      invoiceUrn: _.get(invoice, 'urn'),
      chargeUrn: _.get(invoice, 'charge.urn', null),
      paymentPlanType: _.get(subscription, 'sku_data.payment_plan.type'),
      upfrontInterval: _.get(
        subscription,
        'sku_data.payment_plan.upfront_interval'
      ),
      upfrontIntervalCount: _.get(
        subscription,
        'sku_data.payment_plan.upfront_interval_count'
      ),
      status: _.get(invoice, 'status')
    };
  }
};

export const __test__ = {
  buildPurchaseSkuInput
};

import { PaymentProvider } from 'app/models/payment-providers/payment-provider';
import { Order } from 'app/models/orders';
import { CheckoutStrategy } from './checkout-strategy';
import constants from 'app/constants/constants';

export class RechargeSubscriptionCheckoutStrategy extends CheckoutStrategy {
  get type(): string {
    return 'recurring';
  }

  get purchaseButtonText(): string {
    return 'Confirm Purchase';
  }

  get displayPlanSelection(): boolean {
    return false;
  }

  get showAutoRenewTerms(): boolean {
    return false;
  }

  heroTextLarge(): string {
    return 'Renew Subscription';
  }

  heroTextMedium(_order: Order): string {
    return '';
  }

  heroTextSmall(order: Order): string {
    return order.name;
  }

  continueButtonLabel: string = 'Continue with Renewal';

  submitOrderButtonLabel: string = 'Complete Renewal';

  reviewPageHeroTextLarge: string = 'Confirm Renewal';

  getSkuAmountPathForPaymentPlanType(): string {
    return 'payment_plan.recurring_amount';
  }

  supportsRouterPath(routerPath: string): boolean {
    return routerPath.startsWith('/recharge/');
  }

  supportsPaymentProvider(provider: PaymentProvider): boolean {
    return provider.paymentMethodType !== constants.providers.AFFIRM_CREDIT;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormValidation,
  Loading,
  TextInput
} from '@udacity/veritas-components';
import config from 'config';

import UserService from '../../services/user-service';
import analytics from '../../helpers/analytics-helper';
import CookieHelper from '../../helpers/cookie-helper';
import RedirectHelper from '../../helpers/redirect-helper';
import { reportError } from '../../helpers/sentry-helper';
import AuthTou from '../common/auth-tou';
import { CRUMB_MAP, PageId } from '../common/breadcrumb-bar';
import RecaptchaForm from './recaptcha-form';
import styles from './sign-in-form.module.scss';

export default class SignInForm extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    accountExisted: PropTypes.bool.isRequired
  };

  state = {
    error: this.props.accountExisted
      ? 'This email is already associated with an account. Please use your password to sign in.'
      : null,
    errorCategory: this.props.accountExisted ? 'warning' : null,
    fieldErrors: {},
    showRecaptcha: false,
    loading: false
  };

  signin = (email, password, recaptcha) => {
    this.setState({ showRecaptcha: false, loading: true });

    UserService.signin(email, password, recaptcha)
      .then((data) => {
        if (!data.jwt) {
          // If user-api needs another step for them to get a JWT, it returns
          // a 200 with a `req` and a `location`
          if (data.req === 'WEAKPASSWORD') {
            const next = encodeURI(window.location.href);
            const url = data.location + '&next=' + next;
            RedirectHelper.goToUdacityUrl(url);
            return;
          }
          // I'm assuming we can ignore 2FA and Ent TOU reqs
          throw new Error(`unsupported signin method: ${data.req}`);
        }
        CookieHelper.setJwtCookie(data.jwt);
        this.props.onSuccess();
      })
      .catch((error) => {
        let errorText;

        if (error.status === 401) {
          // rate-limiting on signin still returns a 401 so require a recaptcha
          // on retry
          this.setState({ showRecaptcha: true });
          errorText = 'Invalid credentials';
        } else {
          errorText = 'Unable to sign in at this time';
          reportError(error);
        }
        this.setState({
          error: errorText,
          errorCategory: 'error',
          loading: false
        });
      });
  };

  handleEmailChange = (e) => {
    e.preventDefault();
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = (e) => {
    e.preventDefault();
    this.setState({ password: e.target.value });
  };

  handleSubmit = () => {
    const recaptcha = this.recaptcha;
    const { email, password } = this.state;

    analytics.trackAction(
      'sign_in_button_clicked',
      CRUMB_MAP[PageId.authentication].analyticsName
    );

    this.setState({ error: null, errorCategory: null });

    if (!(email && password)) {
      this.setState({
        error: 'Must specify an email and password',
        errorCategory: 'error'
      });
      return;
    }

    this.signin(email, password, recaptcha);
  };

  handleRecaptchaChange = (recaptchaValue) => {
    this.recaptcha = recaptchaValue;
  };

  render() {
    const {
      error,
      errorCategory,
      showRecaptcha,
      loading,
      email,
      password
    } = this.state;

    return (
      <Loading busy={loading}>
        <RecaptchaForm
          buttonLabel={'Sign In'}
          onSubmit={this.handleSubmit}
          showRecaptcha={showRecaptcha}
          onRecaptchaChange={this.handleRecaptchaChange}
        >
          <TextInput
            type="email"
            id="email"
            label=""
            required
            hiddenLabel
            value={email}
            placeholder="Email Address"
            onChange={this.handleEmailChange}
          />

          <TextInput
            type="password"
            id="password"
            label=""
            required
            hiddenLabel
            value={password}
            placeholder="Password"
            onChange={this.handlePasswordChange}
          />

          <div className={styles.link_container}>
            <a
              className={styles.forgot_password}
              href={`${config.REACT_APP_AUTH_WEB_URL}/reset-password-email`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Forgot password
            </a>
          </div>

          {error && <FormValidation variant={errorCategory} message={error} />}
        </RecaptchaForm>

        <AuthTou />
      </Loading>
    );
  }
}

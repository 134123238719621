import Types from '../actions/types';

export const defaultState = {
  grandTotal: null,
  name: '',
  isInvoiceFetched: false
};

export default (state = defaultState, action) => {
  if (action.error || !action.payload) {
    return state;
  }

  switch (action.type) {
    case Types.FETCH_INVOICE_DATA_COMPLETE:
      return {
        ...state,
        ...action.payload,
        isInvoiceFetched: true
      };

    default:
      return state;
  }
};

import constants from 'app/constants/constants';
import { PaymentProvider } from './payment-provider';
import { Address } from 'app/models/address';
import _get from 'lodash/get';
import { PurchaseDataKind } from './purchase-data-kind';

export class AffirmCreditPaymentProvider extends PaymentProvider {
  get providerName(): string {
    return constants.providers.AFFIRM_CREDIT;
  }

  get paymentMethodType(): string {
    return 'affirm_credit';
  }

  requiresCardToken(): boolean {
    return false;
  }

  canAuthorizeCharge(): boolean {
    return false;
  }

  getAddress(paymentMethodData: any): Address {
    const addressData = _get(
      paymentMethodData,
      'address', {}
    );
    return new Address(addressData);
  }

  getPurchaseDataToSubmit(): PurchaseDataKind[] {
    return [PurchaseDataKind.COLLECTED_DATA];
  }
}

export const AFFIRM_COUNTRIES = ['PR', 'US'];

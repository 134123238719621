import React, { ReactNode } from 'react';
import styles from './multi-step-checkout.module.scss';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as H from 'history';
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import {
  BreadCrumb,
  BreadcrumbBar,
  CRUMB_MAP,
  getCrumbByHash,
  getCrumbById,
  PageId
} from 'app/components/common/breadcrumb-bar';
import { Order } from '../models/orders';
import _ from 'lodash';
import { CheckoutStrategies } from '../models/checkout-strategies';
import { CheckoutStrategy } from '../models/checkout-strategies/checkout-strategy';
import { PartialCheckOutStepProps } from './checkout-step';
import analytics, { events } from 'app/helpers/analytics-helper';
import { LocationDescriptorObject } from 'history';

type Props = RouteComponentProps<{}> & typeof mapDispatchToProps & {
  authenticated: boolean,
  order: Order;
  checkoutStrategy: CheckoutStrategy;
  updateTitle: (title: string) => void;
}

interface State {
  isCreditCardFormProcessing: boolean;
}

const mapStateToProps = (state: any): object => {
  return {
    authenticated: state.user.authenticated,
    order: state.checkout.order,
    checkoutStrategy: CheckoutStrategies.fromStrategyType(
      _.get(state.checkout.order, 'input.checkoutStrategyType')
    )
  };
};

const mapDispatchToProps = {};

/**
 * The entry point for a checkout UI that has multiple screens.
 */
class MultiStepCheckout extends React.Component<Props, State> {

  state: Readonly<State> = {
    isCreditCardFormProcessing: false
  };

  componentDidMount(): void {
    analytics.trackEvent(events.CHECKOUT_VIEWED_STEP);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const prevHash = prevProps.location.hash;
    const currHash = this.props.location.hash;
    const breadcrumb = getCrumbByHash(currHash);
    const prevCrumb = getCrumbByHash(prevHash);
    if (prevHash !== currHash && breadcrumb) {
      this.sendPageViewEvent(breadcrumb, prevCrumb);
    }
  }

  /**
   * Send Page View event, including the previous page's name.
   */
  private sendPageViewEvent(currPage: BreadCrumb, prevPage?: BreadCrumb): void {
    const prevPageName = prevPage && prevPage.analyticsName;
    // Intentionally setting category and name to be the same.
    analytics.trackPageView(currPage.analyticsName,
      currPage.analyticsName,
      { previous_page_name: prevPageName });
  }

  /**
   * Navigate to a checkout page.
   *
   * @param pageId the page to navigate to
   * @param history the history of the router that contains the pages
   * @param replacePage if set to true the new page replaces this page on the stack
   */
  private toPage = (pageId: PageId, history: H.History, replacePage: boolean): void => {
    const crumb = getCrumbById(pageId);
    if (replacePage) {
      history.replace(crumb.hash);
      return;
    }
    history.push(crumb.hash); // Cannot use this component's history because these are sub routes
  };

  /**
   * Navigate to a page that's outside of the checkout flow.
   *
   * The child components cannot use their instance of props.history to push,
   * because that history belongs to the sub, not the top level router.
   *
   * @param location location of the page
   */
  private toExternalPage = (location: LocationDescriptorObject): void => {
    this.props.history.push(location);
  };

  private updateTitle = (title: string): void => {
    document.title = title;
  };

  private getCurrentPage = (): PageId => {
    const crumb = getCrumbByHash(this.props.location.hash);
    return crumb ? crumb.id : PageId.authentication;
  };
  
  private setCreditCardProcessing = (isCreditCardFormProcessing: boolean): void => {
    this.setState({ isCreditCardFormProcessing });
  };

  /**
   * Build a child route for one of the checkout step.
   * @param crumb
   * @param nonRouterProps props needed by child components, excluding router injected ones.
   */
  private getChildRoute = (crumb: BreadCrumb, nonRouterProps: PartialCheckOutStepProps): JSX.Element => {
    return (
      <Route key={crumb.id}
             path={crumb.hash}
             render={
               (routerProps: RouteComponentProps): JSX.Element => {
                 const combinedProps = {
                   ...nonRouterProps,
                   ...routerProps
                 };
                 return <crumb.component {...combinedProps} />;
               }
             } />);
  };

  render(): ReactNode {
    const {  isCreditCardFormProcessing } = this.state;
    const {
      authenticated,
      location,
      order
    } = this.props;
    const childProps: PartialCheckOutStepProps = {
      order,
      checkoutStrategy: this.props.checkoutStrategy,
      parentComponentLocation: location,
      toPage: this.toPage,
      toExternalPage: this.toExternalPage,
      updateTitle: this.updateTitle,
      submitCreditCardForm: false,
      resetCreditCardForm: () => {},
      onCreditCardFormCompletion: () => {},
      isCreditCardFormProcessing,
      setCreditCardSubmitProcessing: this.setCreditCardProcessing
    };
    const currPage = this.getCurrentPage();
    
    const pages = authenticated ?
      [CRUMB_MAP[PageId.cart], CRUMB_MAP[PageId.payment], CRUMB_MAP[PageId.review]] :
      [CRUMB_MAP[PageId.authentication]];

    const nonMatchingPageHash = pages[0].hash;

    return (
      <div className={styles.wrapper}>
        {authenticated && <BreadcrumbBar currPage={currPage} />}
        <HashRouter>
          <Switch>
            {pages.map((crumb) => this.getChildRoute(crumb, childProps))}
            <Redirect to={nonMatchingPageHash} />
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

const MultiStepCheckoutWithRouter = withRouter(MultiStepCheckout);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiStepCheckoutWithRouter);

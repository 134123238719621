import { Address } from 'app/models/address';
import { PurchaseDataKind } from './purchase-data-kind';

/**
 * Represent a type of payments from a front end perspective. Multiple Payment
 * Providers may map to a single Payment Method.
 */
export abstract class PaymentProvider {
  // Get the name of this provider.
  abstract get providerName(): string;

  /**
   * When submitting a purchase, what data should be included in the submit call?
   *
   * @return an array of PurchaseDataKind. Element position does not matter.
   */
  abstract getPurchaseDataToSubmit(): PurchaseDataKind[]

  // Get the payment method type Braavos expects.
  abstract get paymentMethodType(): string;

  /**
   * When creating a payment method, do we need to persist the card token?
   */
  abstract requiresCardToken(): boolean;

  /**
   * Get additional data from the provider necessary for the
   * creation of the payment method.
   */
  getCreationData(_providerData: Object): Object {
    return {};
  }

  /**
   * When creating a payment method, do we need to persist the card token
   * response with the method?
   */
  savesTokenData(): boolean {
    return false;
  }

  /**
   * Does this payment provider supports creating recurring payments?
   */
  supportsRecurringPayments(): boolean {
    return false;
  }

  /**
   * Get user's address from the checkout state.
   *
   * @param paymentMethodData the provider specific paymentMethodData, usually under the Redux state tree path
   * checkout.paymentMethodData.${provider}
   * @returns the address in standard format
   */
  getAddress(paymentMethodData: any): Address | undefined {
    return paymentMethodData ? paymentMethodData.address : undefined;
  }

  /**
   * Does this provider support the authorize funds step?
   */
  abstract canAuthorizeCharge(): boolean;
}

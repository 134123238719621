import queryString from 'query-string';
import config from 'config';

export default {
  getPDFUrl(invoiceData) {
    const data = {
      ...invoiceData
    };

    const query = queryString.stringify(data);
    return `${config.REACT_APP_PRINTER_URL}/v2/invoice?${query}`;
  }
};

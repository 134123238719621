import React from 'react';
import logo from 'assets/images/logo.svg';
import styles from './header.module.scss';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.center}>
        <img src={logo} alt="Udacity" />
      </div>
    </header>
  );
};

export default Header;

import { Authentication } from '../authentication';
import flowRight from 'lodash/flowRight';
import { withNotAuthGuard } from './utils';
import { getCheckboxTerms } from 'app/reducers/ui';
import actions from 'app/actions';
import { connect } from 'react-redux';

const mapDispatchToProps = {
  fetchCurrentUser: actions.fetchCurrentUser,
  updateOrderWithUser: actions.updateOrderWithUser
};

const mapStateToProps = (state: any): any => {
  const loadingGeo = state.ui.loading,
    loadingUser = state.user.loading.user,
    loadingExperiments = state.user.loading.experiments;
  return {
    checkboxTerms: getCheckboxTerms(state),
    isAuthenticated: state.user.authenticated,
    isLoading: loadingGeo || loadingUser || loadingExperiments
  };
};

const GuardedAuthentication = flowRight(
  connect(mapStateToProps, mapDispatchToProps),
  withNotAuthGuard
)(Authentication);

export default GuardedAuthentication;

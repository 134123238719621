/**
 * When submitting a purchase, the following kinds of data can be included in the submit order call.
 */
export enum PurchaseDataKind {
  // The existing payment method's URN
  PAYMENT_METHOD_URN,

  // Additional data collected during the checkout flow
  COLLECTED_DATA,
}

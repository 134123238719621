import React, { ReactNode } from 'react';
import { Button, Loading } from '@udacity/veritas-components';
import { connect } from 'react-redux';
import { CRUMB_MAP, PageId } from 'app/components/common/breadcrumb-bar';
import { CheckOutStepProps } from './checkout-step';
import { CourseFeature } from 'app/components/course-feature';
import { GenericLines } from '../components/generic-lines';
import CouponCodeForm from 'app/components/forms/coupon-code-form';
import styles from './cart.module.scss';
import { HeroText } from '../components/common/hero-text';
import analytics from 'app/helpers/analytics-helper';
import { Order } from 'app/models/orders';
import { getMonthsUntilDue } from '../reducers/ui';
import {
  isOrderLoading,
  getExistingCardData
} from 'app/reducers/checkout';
import { SavedCreditCard } from 'app/models/saved-credit-card';
import get from 'lodash/get';
import checkoutActions from 'app/actions/checkout';

type Props = DerivedProps & typeof mapDispatchToProps & CheckOutStepProps;

interface DerivedProps {
  order: Order;
  monthsUntilDue: number;
  isLoading: boolean;
  isNewPurchase: boolean;
  existingCard?: SavedCreditCard;
}

interface State {}

const mapStateToProps = (state: any): DerivedProps => {
  return {
    order: state.checkout.order,
    monthsUntilDue: getMonthsUntilDue(state),
    isLoading: isOrderLoading(state),
    isNewPurchase:
      get(state.checkout.order, 'checkoutStrategyType') === 'recurring_first',
    existingCard: getExistingCardData(state)
  };
};

const mapDispatchToProps = {
  updateSelectedPaymentMethod: checkoutActions.updateSelectedPaymentMethod,
  useExistingCard: checkoutActions.useExistingCard
};

export class Cart extends React.Component<Props, State> {
  componentDidMount(): void {
    if (!this.props.isSinglePage) {
      this.props.updateTitle('Order Details');
    }

    if (this.props.existingCard) {
      this.setExistingCardAsPaymentMethod();
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      typeof prevProps.existingCard === 'undefined' &&
      Boolean(this.props.existingCard)
    ) {
      this.setExistingCardAsPaymentMethod();
    }
  }

  private setExistingCardAsPaymentMethod = (): void => {
    this.props.updateSelectedPaymentMethod('stripe');
    this.props.useExistingCard(true);
  }

  private goToPayment = (): void => {
    analytics.trackAction(
      'continue_button_clicked',
      CRUMB_MAP[PageId.cart].analyticsName
    );

    const skipToReviewOrder =
      this.props.isNewPurchase &&
      this.props.existingCard;

    this.props.toPage(
      skipToReviewOrder ? PageId.review : PageId.payment,
      this.props.history,
      false
    );
  };

  render(): ReactNode {
    const { checkoutStrategy, order, isLoading, isSinglePage } = this.props;
    const showCouponCodeForm = checkoutStrategy.type === 'recurring_first';

    return (
      <>
        <Loading busy={isLoading}>
          <div className={styles.cartContainer}>
            <div className={styles.cartDetails}>
              <HeroText
                heroText={checkoutStrategy.heroTextLarge()}
                subHeroText={checkoutStrategy.heroTextMedium(order)}
              />
              <GenericLines
                checkoutStrategy={this.props.checkoutStrategy}
                order={order}
              />
              {showCouponCodeForm && (
                <div className={styles.couponContainer}>
                  <CouponCodeForm />
                </div>
              )}
            </div>
            {!isSinglePage && (
              <div className={styles.continueBtn}>
                <Button
                  testID={'continueBtn'}
                  label={checkoutStrategy.continueButtonLabel}
                  variant="primary"
                  onClick={this.goToPayment}
                />
              </div>
            )}
          </div>
        </Loading>
        {!isSinglePage && <CourseFeature />
        }
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

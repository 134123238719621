import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconCaretDown } from '@udacity/veritas-icons';
import { TextInput } from '@udacity/veritas-components';
import { Button } from '@udacity/veritas-components';

import analytics, { events } from 'app/helpers/analytics-helper';
import redirectHelper from 'app/helpers/redirect-helper';
import printerService from 'app/services/printer-service';
import employerReceiptFields from './employer-receipt-form-fields';
import styles from './employer-receipt-form.module.scss';

const INVOICE_TEMPLATE = 'nd-term-invoice';
const DEFAULT_LANG = 'en-us';

export class EmployerReceiptForm extends Component {
  static propTypes = {
    invoiceUrn: PropTypes.string.isRequired,
    name: PropTypes.string
  };

  state = {
    open: false,
    receiptForm: {
      ..._.mapValues(employerReceiptFields, () => ''),
      name: this.props.name
    },
    validation: _.mapValues(employerReceiptFields, () => true),
    valid: false
  };

  downloadReceipt = () => {
    const { invoiceUrn } = this.props;
    const { receiptForm } = this.state;
    const downloadUrl = printerService.getPDFUrl({
      ...receiptForm,
      locale: DEFAULT_LANG,
      invoice_id: invoiceUrn.replace(/.*:/, ''),
      template: INVOICE_TEMPLATE
    });
    redirectHelper.openWindowWithUdacityUrl(downloadUrl);
    analytics.trackEvent(events.RECEIPT_DOWNLOADED, {
      ...receiptForm,
      invoiceUrn
    });
  };

  updateText = (event) => {
    this.setState({
      receiptForm: {
        ...this.state.receiptForm,
        [event.target.id]: event.target.value
      }
    });
  };

  isFormComplete() {
    const requiredReceiptFields = ['name'];
    return _.every(requiredReceiptFields, (field) => {
      return !_.isEmpty(this.state.receiptForm[field]);
    });
  }

  toggleForm = () => {
    this.setState({ open: !this.state.open }, () => {
      if (this.state.open) {
        analytics.trackEvent(events.RECEIPT_FORM_VIEWED, {
          invoiceUrn: this.props.invoiceUrn
        });
      }
    });
  };

  buildFormFields() {
    return _.map(employerReceiptFields, (opts, key) => {
      return (
        <div key={key}>
          <TextInput
            id={key}
            label={opts.label}
            value={this.state.receiptForm[key]}
            onChange={this.updateText}
            placeholder={opts.placeholder}
          />
        </div>
      );
    });
  }

  render() {
    const { open } = this.state;
    const iconClass = open ? styles.icon_up : styles.icon_down;

    return (
      <div className={styles.employer_receipt_form}>
        <div className={styles.link} onClick={this.toggleForm}>
          <span>{'Download receipt for employer'}</span>
          <span className={iconClass}>
            <IconCaretDown size="sm" color="cerulean-dark" />
          </span>
        </div>

        {open && (
          <div>
            {this.buildFormFields()}

            <Button
              label={'Download'}
              variant="primary"
              small
              disabled={!this.isFormComplete()}
              onClick={this.downloadReceipt}
            />
          </div>
        )}
      </div>
    );
  }
}

export default EmployerReceiptForm;

import React from 'react';
import styles from './icon.module.scss';
import { CreditCardBrand } from 'app/models/credit-card-brands';

interface IconProps {
  brand: CreditCardBrand;
}

const Icon: React.FunctionComponent<IconProps> = (props: IconProps) => {
  const className = props.brand ? styles[props.brand.cssClassName] : styles.unknown;
  return <div data-testid='creditCardIcon' className={className} />;
};

export default Icon;

import checkout from './checkout';
import invoice from './invoice';
import ui from './ui';
import user from './user';
import Types from './types';

export default {
  Types,
  ...checkout,
  ...invoice,
  ...ui,
  ...user
};

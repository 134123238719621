import _ from 'lodash';
import config from '../../config';
import HttpHelper from 'app/helpers/http-helper';
import _trim from 'lodash/trim';

const userAPI = new HttpHelper(config.REACT_APP_USER_API_URL);
const E_164_REGEX = '^\\+[1-9]\\d{1,14}$';
const RECAPTCHA_HEADER_NAME = 'X-Udacity-ReCAPTCHA-UserAPI';

export default {
  getUserAccountInfo() {
    return userAPI
      .get('/me')
      .then((user) => ({
        displayName: _.get(user, 'display_name'),
        email: _.get(user, 'email'),
        firstName: _.get(user, 'first_name'),
        lastName: _.get(user, 'last_name'),
        phoneNumber: _.get(user, 'phone_number'),
        phoneNumberVerifiedAt: _.get(user, 'phone_number_verified_at'),
        userId: _.get(user, 'id')
      }))
      .catch(() => ({}));
  },

  async getUserPreferences() {
    return userAPI.get('/me/preferences').then((result) => result.objects);
  },

  updatePhoneNumber(phoneNumber) {
    const trimmed = _trim(phoneNumber);
    if (!trimmed || !trimmed.match(E_164_REGEX)) {
      throw new Error('Invalid phone number.');
    }

    return userAPI.put('/me', { phone_number: trimmed });
  },

  register(email, recaptcha = '') {
    const extraHeaders = {};
    extraHeaders[RECAPTCHA_HEADER_NAME] = recaptcha;
    return userAPI.post('/signup_no_password', { email }, extraHeaders);
  },

  signin(email, password, recaptcha = '') {
    const extraHeaders = {};
    extraHeaders[RECAPTCHA_HEADER_NAME] = recaptcha;
    return userAPI.post('/signin', { email, password }, extraHeaders);
  }
};

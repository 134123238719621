import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Checkbox,
  FormValidation,
  Loading,
  TextInput
} from '@udacity/veritas-components';

import constants from '../../constants/constants';
import UserService from '../../services/user-service';
import { reportError } from '../../helpers/sentry-helper';
import analytics from '../../helpers/analytics-helper';
import CookieHelper from '../../helpers/cookie-helper';
import AuthTou from '../common/auth-tou';
import { CRUMB_MAP, PageId } from '../common/breadcrumb-bar';
import RecaptchaForm from './recaptcha-form';
import styles from './sign-up-form.module.scss';

const TermsAcceptanceLabel = ({ textOnly }) => (
  <div className={styles.tos}>
    {textOnly ? (
      `I agree to Udacity's Terms of Use, Privacy Policy, and to receive promotional materials from Udacity.`
    ) : (
      <>
        I agree to Udacity&apos;s{' '}
        <a href={constants.links.TERMS}>Terms of Use</a>,{' '}
        <a href={constants.links.PRIVACY}>Privacy Policy</a>, and to receive
        promotional materials from Udacity.
      </>
    )}
  </div>
);

export default class SignUpForm extends Component {
  static propTypes = {
    checkboxTerms: PropTypes.bool,
    onSuccess: PropTypes.func.isRequired,
    onAccountExists: PropTypes.func.isRequired
  };

  static defaultProps = {
    checkboxTerms: false
  };

  state = {
    error: null,
    fieldErrors: {},
    showRecaptcha: false,
    termsChecked: false,
    loading: false
  };

  signup = (email, recaptcha) => {
    this.setState({ showRecaptcha: false, loading: true });

    UserService.register(email, recaptcha)
      .then((data) => {
        CookieHelper.setJwtCookie(data.jwt);
        this.props.onSuccess();
      })
      .catch((error) => {
        let errorText;

        // TODO: there are a bunch of 400 scenarios that exist for
        // auth-web's signup view. But it would seem these didn't
        // get implemented for /signup_no_password in user-api.
        if (error.status === 409) {
          this.props.onAccountExists();
        } else if (error.status === 429) {
          // they were rate limited, enable ReCAPTCHA
          this.setState({ showRecaptcha: true });
        } else {
          errorText = 'Unable to sign up at this time';
          reportError(error);
        }
        this.setState({ error: errorText, loading: false });
      });
  };

  handleSubmit = () => {
    const recaptcha = this.recaptcha;
    const { checkboxTerms } = this.props;
    const { termsChecked, email } = this.state;

    analytics.trackAction(
      'continue_with_checkout_button_clicked',
      CRUMB_MAP[PageId.authentication].analyticsName
    );

    this.setState({ error: null });

    const fieldErrors = this.validateForm(email) || {};
    if (checkboxTerms && !termsChecked) {
      fieldErrors.termsRequired = true;
      this.setState({ error: 'You must agree to Terms to continue.' });
    }

    if (_.some(fieldErrors)) {
      this.setState({ fieldErrors });
      return;
    }

    this.signup(email, recaptcha);
  };

  handleRecaptchaChange = (recaptchaValue) => {
    this.recaptcha = recaptchaValue;
  };

  handleEmailChange = (e) => {
    e.preventDefault();
    this.setState({ email: e.target.value });
  };

  handleTermsChange = () => {
    this.setState({ termsChecked: !this.state.termsChecked });
  };

  /* http://www.regular-expressions.info/email.html */
  EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  isValidEmail = (email) => {
    return this.EMAIL_REGEX.test(email);
  };

  validateForm = (email) => {
    let fieldErrors = {};

    if (!this.isValidEmail(email)) {
      fieldErrors.email = 'Is not a valid email';
    }

    return fieldErrors;
  };

  render() {
    const { checkboxTerms } = this.props;
    const { error, fieldErrors, loading, showRecaptcha, email } = this.state;

    const emailValidation = fieldErrors.email ? (
      <FormValidation variant="error" message={fieldErrors.email} />
    ) : null;

    return (
      <Loading busy={loading}>
        <RecaptchaForm
          buttonLabel={'Continue With Checkout'}
          onSubmit={this.handleSubmit}
          showRecaptcha={showRecaptcha}
          onRecaptchaChange={this.handleRecaptchaChange}
        >
          <TextInput
            type="email"
            value={email}
            id="email"
            label=""
            required
            hiddenLabel
            placeholder={'Email Address'}
            onChange={this.handleEmailChange}
            validation={emailValidation}
          />

          {checkboxTerms && (
            <div className={styles.terms_line}>
              <div className={styles.checkbox}>
                <Checkbox
                  hiddenLabel
                  label={<TermsAcceptanceLabel textOnly />}
                  id="terms"
                  checked={this.state.termsChecked}
                  onChange={this.handleTermsChange}
                />
              </div>
              <TermsAcceptanceLabel />
            </div>
          )}
          {error && <FormValidation variant="error" message={error} />}
        </RecaptchaForm>
        <div className={styles.footer}>{!checkboxTerms && <AuthTou />}</div>
      </Loading>
    );
  }
}

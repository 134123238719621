import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { Checkbox } from '@udacity/veritas-components';
import constants from 'app/constants/constants';
import styles from './terms-conditions.module.scss';
import { AmountAtom, IPaymentPlan, Order } from 'app/models/orders';
import { Address } from 'app/models/address';
import { PaymentProvider } from 'app/models/payment-providers/payment-provider';
import {
  getTrialEndDateStringMmDdYyyy,
  getTrialData, TrialData, hasFirstMonthFreeTrial, getZeroAmountDisplay, promotionsFromOrder
} from 'app/models/promotions';

export interface Props {
  selectedPaymentProvider: PaymentProvider;
  address: Address | undefined;
  ackAutoRenew: boolean;
  submitOrderButtonLabel: string;
  monthsUntilDue: number;
  paymentPlan: IPaymentPlan;
  grandTotal: AmountAtom;
  order: Order;
  updateAutoRenew: (value: boolean) => void;
}

export const FreeTrialTerms = (props: {
  trialData: TrialData,
  price: string,
  monthsUntilDue: number
}): JSX.Element => {
  return <span data-testid="trialDescription">You will have access to your program at no cost for {props.trialData.trialIntervalCountWord} ({props.trialData.trialIntervalCount}) days. On {getTrialEndDateStringMmDdYyyy(props.trialData)} you will be charged {props.price} for {props.monthsUntilDue}-month access to the program.</span>;
};

// Return the first charge's description.
export const NonFreeTrialBundleTerms = (props: { price: string, monthsUntilDue: number }): JSX.Element => {
  return <span>One time purchase of {props.price} for {props.monthsUntilDue}-month access to the program</span>;
};

const CommonMonthlyTerms = (
  props: {
    dueDate: string,
    trialSecondPaymentDate: string,
    trialData: TrialData | undefined,
    recurringPrice: string,
    hasFirstMonthFreeTrialPromotion?: boolean
  }
): JSX.Element => {
  const automaticChargeDateDisplay = props.trialData
    ? (
      props.hasFirstMonthFreeTrialPromotion
        ? getTrialEndDateStringMmDdYyyy(props.trialData)
        : props.trialSecondPaymentDate
    )
    : props.dueDate;

  return <>
    <strong>with automatic charges of {props.recurringPrice} each month starting on {automaticChargeDateDisplay} until you either cancel or complete your graduation requirements. </strong>
    {
      props.hasFirstMonthFreeTrialPromotion
        ? <>Cancel or stop these monthly extensions at any time from your account settings page. No partial refunds. Students cannot receive a graduation certificate until they have made a minimum of one payment. Students may complete up to 3 projects during the free trial period. Find subscription details in our {autoRenewFaqLink} and in our <OneMonthFreeTermsLink />.</>
        : <>Cancel your subscription at any time from your account settings page. No partial refunds. Find auto-renew details in our {autoRenewFaqLink}.</>
    }
  </>;
};

export const MonthlyPlanTerms = (props: {
  submitOrderButtonLabel: string,
  dueDate: string,
  trialSecondPaymentDate: string,
  trialData: TrialData | undefined,
  price: string,
  recurringPrice: string,
  currency: string,
  hasFirstMonthFreeTrialPromotion?: boolean
}): JSX.Element => {
  const totalPriceToday = props.hasFirstMonthFreeTrialPromotion ? getZeroAmountDisplay(props.currency) : props.price;

  return (
    <>
      {
          <span><strong>By clicking the &quot;{props.submitOrderButtonLabel}&quot; button, you agree to the total price of {totalPriceToday} now </strong><CommonMonthlyTerms {...props} /></span>
      }
    </>
  );
};

const autoRenewFaqLink = <a target="_blank" rel="noopener noreferrer" className={styles.link} href={constants.links.AUTO_RENEW_FAQ}>FAQ</a>;
const OneMonthFreeTermsLink = (): JSX.Element => <a target="_blank" rel="noopener noreferrer" className={styles.link} href={constants.links.ONE_MONTH_FREE_TERMS}>One Month Free Access Offer Terms and Conditions</a>;

export const FreeTrialMonthlyTerms = (props: {
  dueDate: string,
  trialSecondPaymentDate: string,
  trialData: TrialData | undefined,
  price: string,
  recurringPrice: string
}): JSX.Element => {
  return (
    <>
      <strong>
        After the {props.trialData?.trialIntervalCount} day free trial:
      </strong>
      <ul>
        <li>
          <strong>You will be charged {props.price} for 1 month access to the program </strong><CommonMonthlyTerms {...props} />
        </li>
      </ul>
    </>
  );
};

export const BundlePlanTerms = (props: {
  dueDate: string;
  monthsUntilDue: number;
  price: string;
  recurringPrice: string;
  showAltAutoRenewMessaging: boolean;
  hasFirstMonthFreeBundlePromotion: boolean;
}): JSX.Element => {
  const renewMessaging = (text: TemplateStringsArray, prefix: string): string => {
    const altMessaging = `Renewal charges of ${props.recurringPrice} per month are due at the end of each month`
    return (
      (props.showAltAutoRenewMessaging ? altMessaging : prefix) + text.join('')
    );
  };
  
  return (
    <>
      <strong data-testid={'bundleDurationDesc'}>
        After the {props.monthsUntilDue}-month period:
      </strong>
      <ul>
        {
          props.hasFirstMonthFreeBundlePromotion
            ? <li>
                <strong>Should you require more time to complete your Nanodegree program, you will automatically be enrolled in a monthly subscription with charges of {props.recurringPrice} per month. On graduation the billing for your subscription will automatically stop, or you may cancel at any time.</strong>{' '}
                  No partial refunds. Cancel or stop these monthly extensions at any time from your account settings page. Find subscription details in our {autoRenewFaqLink} and in our <OneMonthFreeTermsLink />.
              </li>
            : <li>
                <strong data-testid={'renewDescription'}>
                  {renewMessaging`
                  ${`Monthly automatic-renewals with auto charges of ${props.recurringPrice} per month`}
                  until you either cancel or complete your graduation requirements. `}
                </strong>
                No partial refunds. Cancel or stop these auto-renewals at any time
                from your account settings page. Find auto-renewal details in our{' '}
                {autoRenewFaqLink}.
              </li>
        }
      </ul>
    </>
  );
}

const InitialCheckoutTerms = (
  props: {
    submitOrderButtonLabel: string,
    dueDate: string,
    isBundle: boolean,
    monthsUntilDue: number,
    order: Order,
    price: string,
    recurringPrice: string,
    trialData: TrialData | undefined,
    trialSecondPaymentDate: string,
    showAltAutoRenewMessaging: boolean
  }): JSX.Element => {
  const {
    isBundle,
    monthsUntilDue,
    price,
    trialData,
    order,
  } = props;
  const hasFirstMonthFreeTrialPromotion: boolean = hasFirstMonthFreeTrial(order);
  const hasFirstMonthFreeBundlePromotion: boolean = promotionsFromOrder(order)
    .some(promotion => promotion.type === 'first-month-free');

  return (
    <>
      {
        (isBundle || (trialData && !hasFirstMonthFreeTrialPromotion)) &&
        <>
          <strong>
            By clicking the &quot;{props.submitOrderButtonLabel}&quot; button, you agree to all of the following:
          </strong>
          <ul>
            <li>
              {
                trialData
                  ? <FreeTrialTerms
                    monthsUntilDue={monthsUntilDue}
                    trialData={trialData}
                    price={price} />
                  : <NonFreeTrialBundleTerms
                    monthsUntilDue={monthsUntilDue}
                    price={price} />
              }
            </li>
          </ul>
        </>
      }

      {
        isBundle && <BundlePlanTerms {...props} hasFirstMonthFreeBundlePromotion={hasFirstMonthFreeBundlePromotion} />
      }
      {
        !isBundle && trialData && !hasFirstMonthFreeTrialPromotion && <FreeTrialMonthlyTerms {...props} />
      }
      {
        !isBundle && trialData && hasFirstMonthFreeTrialPromotion && <MonthlyPlanTerms {...props} currency={order.currency} hasFirstMonthFreeTrialPromotion />
      }
      {
        !isBundle && !trialData && <MonthlyPlanTerms {...props} currency={order.currency} />
      }
    </>
  );
};

const RenewTermsComponent = (
  props: {
    submitOrderButtonLabel: string,
    price: string,
  }
): JSX.Element => {
  return (
    <span><strong>By clicking the &quot;{props.submitOrderButtonLabel}&quot; button, you agree to the renewal payment of {props.price}.</strong></span>
  );
};

export const TermsConditions: React.FunctionComponent<Props> = (props: Props) => {
  const {
    ackAutoRenew,
    address,
    monthsUntilDue,
    order,
    paymentPlan,
    selectedPaymentProvider,
    submitOrderButtonLabel,
    grandTotal
  } = props;
  const price = grandTotal.display.replace(/ [A-Z][A-Z][A-Z]$/, '');
  const recurringPrice = _.get(
    paymentPlan,
    'recurring_amount.payable_amount_display',
    ''
  ).replace(/ [A-Z][A-Z][A-Z]$/, '');
  const dueDateObj = moment().add(monthsUntilDue, 'month');
  // First automatic payment's date
  const dueDate = dueDateObj.format('MMMM Do');
  // Second automatic payment's date of a free trial
  const isBundle = monthsUntilDue > 1;
  const isInitialCheckout = order.checkoutStrategyType === 'recurring_first';

  const trialData = getTrialData(order);
  // Second automatic payment's date of a free trial
  const trialSecondPaymentDate = dueDateObj.add(trialData?.trialIntervalCount || 7, 'day').format('MMMM Do');

  const CATerms = (): JSX.Element => {
    return (
      <p className={styles.auto_renew_policy}>
          For California state residents, special regulations apply that may limit your access period and paid time in this program. Please read about <a target="_blank" rel="noopener noreferrer" className={styles.link} href={constants.links.CALIFORNIA_NOTICE}>California Regulations and Udacity Policies</a>.
      </p>
    );
  };

  const country = _.get(address, 'country');
  const region = _.get(address, 'region');
  const isInCA =
    _.toUpper(country) === 'US' &&
    ['CA', 'CALIFORNIA'].includes(_.toUpper(region));

  const showAltAutoRenewMessaging = !selectedPaymentProvider?.supportsRecurringPayments();

  const commonTextProps = {
    submitOrderButtonLabel,
    dueDate,
    trialSecondPaymentDate,
    trialData,
    monthsUntilDue,
    price,
    recurringPrice,
    isBundle
  };

  const countryRequiresOptIn = _.includes(
    constants.ackAutoRenewCountries,
    country
  );

  if (countryRequiresOptIn) {
    return (
      <div className={styles.auto_renew_container}>
        <div className={styles.checkbox}>
          <Checkbox
            id="auto-renew-agree"
            checked={ackAutoRenew}
            label={'Auto Renew Acknowledgement'}
            onChange={(evt): void => {
              if (evt && evt.target) {
                props.updateAutoRenew(evt.target.checked);
              }
            }}
          />
        </div>
        <MonthlyPlanTerms {...commonTextProps} currency={order.currency} />
      </div>
    );
  }

  return (
    <div className={styles.auto_renew_container}>
      {isInCA && <CATerms />}

      <div className={styles.auto_renew_policy}>
        {isInitialCheckout ? (
          <InitialCheckoutTerms
            {...commonTextProps}
            order={order}
            showAltAutoRenewMessaging={showAltAutoRenewMessaging}
          />
        ) : (
          <RenewTermsComponent {...commonTextProps} />
        )}
      </div>
      <p className={styles.tos_links}>
        By confirming, you agree to Udacity&apos;s{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
          href={constants.links.TERMS}
        >
          Terms of Use
        </a>{' '}
        and{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
          href={constants.links.PRIVACY}
        >
          Privacy Policy
        </a>
        .
      </p>
    </div>
  );
};

// The standard address format.
export class Address {
  readonly line1: string;
  readonly line2: string;
  readonly city: string;
  readonly region: string;
  readonly country: string;
  readonly postalCode: string;
  readonly phoneNumber: string;

  constructor(address: {
    readonly line1?: string;
    readonly line2?: string;
    readonly city?: string;
    readonly region?: string;
    readonly country?: string;
    readonly postalCode?: string;
    readonly phoneNumber?: string;
  }) {
    this.line1 = address.line1 || '';
    this.line2 = address.line2 || '';
    this.city = address.city || '';
    this.region = address.region || '';
    this.country = address.country || '';
    this.postalCode = address.postalCode || '';
    this.phoneNumber = address.phoneNumber || '';
  }

  toSnakeCase(): Object {
    return {
      line1: this.line1,
      line2: this.line2,
      city: this.city,
      region: this.region,
      country: this.country,
      postal_code: this.postalCode,
      phone_number: this.phoneNumber
    };
  }
}

import React from 'react';
import ErrorMessage from 'app/components/common/error-message';
import Header from 'app/components/common/header';
import { errorTypes } from '../helpers/error-helper';

const NotFound = () => {
  const error = new Error('Page not found');
  error.type = errorTypes.FULL_PAGE;

  return (
    <div>
      <Header />
      <main>
        <ErrorMessage error={error} />
      </main>
    </div>
  );
};

export default NotFound;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@udacity/veritas-components';
import constants from '../../constants/constants';
import FacebookLogo from 'assets/images/facebook_logo.svg';
import GoogleLogo from 'assets/images/google_logo.svg';
import styles from './_provider-button.module.scss';

export default class ProviderButton extends Component {
  static displayName = 'components/provider-button';

  static propTypes = {
    onClick: PropTypes.func.isRequired,
    provider: PropTypes.oneOf([
      constants.authProviders.GOOGLE,
      constants.authProviders.FACEBOOK
    ]).isRequired,
    label: PropTypes.string
  };

  static defaultProps = {
    label: null
  };

  _getLogo(provider) {
    switch (provider) {
      case constants.authProviders.FACEBOOK:
        return (
          <img
            src={FacebookLogo}
            alt="logo"
            className={styles['logo']}
            title={provider}
          />
        );
      case constants.authProviders.GOOGLE:
        return (
          <img
            src={GoogleLogo}
            alt="logo"
            className={styles['logo']}
            title={provider}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { onClick, label, provider } = this.props;

    return (
      <div className={styles['provider-button']}>
        <Button
          icon={this._getLogo(provider)}
          label={label}
          onClick={() => onClick(provider)}
        />
      </div>
    );
  }
}

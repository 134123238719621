import React from 'react';
import { PaymentProvider } from '../../models/payment-providers/payment-provider';
import constants from 'app/constants/constants';
import Icon from 'app/components/common/icon';
import PayPalIcon from 'assets/images/paypal.png';
import styles from './payment-method-summary.module.scss';
import { SavedCreditCard } from '../../models/saved-credit-card';
import { Button } from '@udacity/veritas-components';

interface Props {
  selectedCreditCard: SavedCreditCard | undefined
  editPaymentMethod: () => void
  selectedPaymentProvider: PaymentProvider
}

const displayCard = (card?: SavedCreditCard): JSX.Element => {
  if (card) {
    return (
      <div data-testid='pickedCardContainer' className={styles.cardContainer}>
        <div className={styles.cardIconContainer}>
          <Icon brand={card.brand} />
        </div>
        <span>ending in {card.last4}</span>
      </div>
    );
  }
  return <></>;
};

const displayPayPal = (): JSX.Element => {
  return (
    <div>
      <img src={PayPalIcon} alt="PayPal" className={styles.paypalIcon} />
    </div>
  );
};

const getPaymentMethodDisplay = (props: Props): JSX.Element => {
  switch (props.selectedPaymentProvider.providerName) {
    case constants.providers.STRIPE:
    case constants.providers.EBANX_CARD:
    case constants.providers.CKO:
      return displayCard(props.selectedCreditCard);
    case constants.providers.PAYPAL:
      return displayPayPal();
    default:
      return <></>;
  }
};

export const PaymentMethodSummary: React.FunctionComponent<Props> = (props: Props) => {

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <div>Payment Method</div>
        <Button
          testID={'editBtn'}
          onClick={props.editPaymentMethod}
          variant='minimal'
          label='Edit'
          small
        />
      </div>
      {getPaymentMethodDisplay(props)}
    </div>
  );


};

import React from 'react';
import styles from './hero-text.module.scss'

interface Props {
  heroText: string;
  subHeroText: string;
}

export const HeroText: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heroText} data-testid='heroTextLarge'>{props.heroText}</h1>
      <h2 className={styles.subHeroText} data-testid='heroTextMedium'>{props.subHeroText}</h2>
    </div>
  );
};

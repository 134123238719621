import React from 'react';
import styles from './breadcrumb-bar.module.scss';
import Authentication from '../../views/authentication';
import Cart from '../../views/cart';
import PaymentContainer from '../payment-container';
import ReviewOrder from '../../views/review-order';
import { CheckOutStepProps } from '../../views/checkout-step';
import constants from 'app/constants/constants';

interface Props {
  currPage: PageId
}

export interface BreadCrumb {
  id: PageId
  hash: string
  analyticsName: string // The name of the page in analytics events.
  label: string // The name displayed in browser
  component: React.ComponentType<CheckOutStepProps>
}

// The ID of the page. These must be ordered.
export enum PageId {
  authentication,
  cart,
  payment,
  review
}

export const CRUMB_MAP: Record<PageId, BreadCrumb> = {
  [PageId.authentication]: {
    id: PageId.authentication,
    hash: '/auth',
    label: 'Get Started',
    analyticsName: 'Authentication',
    component: Authentication
  },
  [PageId.cart]: {
    id: PageId.cart,
    hash: '/cart',
    label: 'Order Details',
    analyticsName: 'Order Details',
    component: Cart
  },
  [PageId.payment]: {
    id: PageId.payment,
    hash: '/payment',
    label: 'Payment Method',
    analyticsName: 'Payment Method',
    component: PaymentContainer
  },
  [PageId.review]: {
    id: PageId.review,
    hash: '/review',
    label: 'Review Your Order',
    analyticsName: 'Review Order',
    component: ReviewOrder
  }
};

export const getCrumbById = (pageId: PageId): BreadCrumb => {
  return CRUMB_MAP[pageId];
};

/**
 * Convert a hash to a BreadCrumb.
 * @param hash a hash string like '#cart'
 * @return the mapped BreadCrumb, or undefined if none is found
 */
export const getCrumbByHash = (hash: String): BreadCrumb | undefined => {
  const normalized = hash.replace('#', '');
  const breadcrumb = Object.values(CRUMB_MAP).find(part => part.hash === normalized);
  return breadcrumb;
};

export const BreadcrumbBar: React.FunctionComponent<Props> = (props: Props) => {
  /**
   * Return an array of mobile breadcrumb
   * @param pageId the current page id
   */
  const getMobileCrumbs = (pageId: PageId): BreadCrumb[] => {
    if (pageId === PageId.authentication) {
      return [CRUMB_MAP[pageId], CRUMB_MAP[pageId + 1]];
    } else {
      return [CRUMB_MAP[pageId - 1], CRUMB_MAP[pageId]];
    }
  };

  const getCrumb = (crumb: BreadCrumb): JSX.Element => {
    const { currPage } = props;
    const crumbClass = [styles.breadcrumbItem];
    if (crumb.id < currPage) {
      crumbClass.push(styles.past);
    } else if (crumb.id > currPage) {
      crumbClass.push(styles.future);
    } else {
      crumbClass.push(styles.selected);
    }
    return (
      <React.Fragment key={crumb.id}>
        <li className={crumbClass.join(' ')}>
          {crumb.label}
        </li>
      </React.Fragment>
    );
  };

  const isDesktop = window.matchMedia(`(min-width: ${constants.breakpoints.TABLET}px)`).matches;
  const crumbs = isDesktop ? Object.values(CRUMB_MAP) : getMobileCrumbs(props.currPage);
  return (
    <ol className={styles.wrapper}>
      {
        crumbs.map(part => getCrumb(part))
      }
    </ol>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import styles from './form-header.module.scss';

export default function FormHeader({ header, description }) {
  return (
    <div>
      <div className={styles.header}>{header}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
}

FormHeader.propTypes = {
  header: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

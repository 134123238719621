import React, { FC, useState } from 'react';
import flowRight from 'lodash/flowRight';
import { connect } from 'react-redux';
import { CheckoutStrategies } from 'app/models/checkout-strategies';
import { RouteComponentProps } from 'react-router';
import { withAuthGuard } from './utils';
import Cart from '../cart';
import PaymentContainer from 'app/components/payment-container';
import CompleteOrderButton from './_complete-order-button';
import constants from 'app/constants/constants';
import {
  getActiveCreditCard,
  getExistingPaymentMethodUrn,
  getGrandTotal,
  getRazorpayOrderId,
  getSelectedPaymentProvider,
  isPaymentMethodValid,
  shouldCollectPaymentData
} from 'app/reducers/checkout';
import analytics, { events } from '../../helpers/analytics-helper';
import optimizely from '../../services/experiments-service';
import { reportError } from '../../helpers/sentry-helper';
import errorHelper from '../../helpers/error-helper';
import actions from 'app/actions';
import { PurchaseDataKind } from 'app/models/payment-providers/purchase-data-kind';
import { SubmitPaymentData } from 'app/models/submit-payment-data';
import { Severity } from '@sentry/browser';
import {
  RazorpayOrderCreationRequest,
  RazorpayPaymentProvider
} from 'app/models/payment-providers/razorpay-payment-provider';
import { getAddress } from 'app/helpers/checkout-helper';
import { getEmail, getFullName } from 'app/reducers/user';
import styles from './_checkout.module.scss';
import { TermsConditions } from 'app/components/terms-conditions';
import { AmountAtom } from 'app/models/orders';
import { getMonthsUntilDue } from 'app/reducers/ui';
import ErrorMessage from 'app/components/common/error-message';

type DerivedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const Checkout: FC<RouteComponentProps & DerivedProps> = (props) => {
  const [status, setStatus] = useState('ok');
  const [payPalReady, setPayPalReady] = useState(false);
  const [submitCreditCardForm, setSubmitCreditCardForm] = useState(false); // set to true to submit the credit card form
  const [isCreditCardFormProcessing, setCreditCardSubmitProcessing] = useState(false);

  const isCompleteOrderButtonLoading = (): boolean => {
    return status === 'processing' || isCreditCardFormProcessing;
  };

  const checkIsDisabled = (): boolean => {
    if (isCompleteOrderButtonLoading()) return true;

    if (props.selectedProvider === 'paypal') {
      return !payPalReady;
    }

    return false;
  };

  const isDisabled = checkIsDisabled();

  const performPostPurchaseRedirect = (purchaseResponse: any): void => {
    if (purchaseResponse.redirectUrl) {
      window.location.href = purchaseResponse.redirectUrl;
    } else {
      analytics.trackEvent(events.CHECKOUT_COMPLETED_ORDER, {
        order_id: purchaseResponse.invoice.urn,
      });
      window.location.replace(`/confirmation/${purchaseResponse.invoice.urn}`);
    }
  };

  const handlePaymentSubmitRazor = (): void => {
    // Modal closed callback.
    const onModalClosed = (): void => setStatus('ok');

    // Upon payment success, call Braavos to capture the charge.
    const processRazorpayResponse = async (
      razorpayResponse: object
    ): Promise<void> => {
      let success = false;
      try {
        await props.submitRazorpayPurchase(razorpayResponse);
        success = true;
      } catch (e) {
        props.displayError({
          checkout: errorHelper.purchaseFailedError({
            cause: e,
            message: e.endUserMessage
          })
        });
      } finally {
        onModalClosed();
      }
      if (success) {
        performPostPurchaseRedirect(props.purchaseData);
      }
    };

    const {
      address,
      email,
      grandTotal,
      order,
      razorpayOrderId,
      userFullName
    } = props;
    if (!razorpayOrderId || !grandTotal) {
      return;
    }
    const provider = props.selectedPaymentProvider as RazorpayPaymentProvider;
    const req: RazorpayOrderCreationRequest = {
      orderId: razorpayOrderId,
      amount: grandTotal.amount,
      description: order.name,
      name: userFullName,
      email: email,
      address: address,
      handler: processRazorpayResponse,
      ondismiss: onModalClosed
    };
    const requestObj = provider.getRequestObject(req);

    // eslint-disable-next-line
    const rzp = new Razorpay(requestObj);
    rzp.open();
  };

  // Don't need to collect payment data for free purchases.
  // Simply create the self-activating purchase then redirect to order confirm page.
  const handleRazorFreePurchase = async (): Promise<void> => {
    const result = await props.createRazorpayOrder();
    const error = result['error'];
    if (error instanceof Error) {
      setStatus('ok');
      props.displayError({
        checkout: errorHelper.purchaseFailedError({
          cause: error,
          message: error['endUserMessage']
        })
      });
      return;
    }
    performPostPurchaseRedirect(result);
  };

  const sendPurchaseEvents = (): void => {
    try {
      analytics.trackEvent(events.CONFIRM_PURCHASE);
      optimizely.trackEventForAnonymousId(
        constants.optimizelyProjects.PROJECT_ID_GROWTH,
        events.CONFIRM_PURCHASE
      );
    } catch (e) {
      // Don't propagate analytics errors.
      reportError(e);
    }
  };

  const handlePaymentSubmit = async (): Promise<void> => {
    const {
      existingPaymentMethodUrn,
      selectedPaymentProvider,
      selectedPaymentMethodData
    } = props;

    let paymentMethodData = {};
    const dataToSubmit = selectedPaymentProvider.getPurchaseDataToSubmit();

    if (dataToSubmit.includes(PurchaseDataKind.COLLECTED_DATA)) {
      paymentMethodData = selectedPaymentMethodData;
    }

    if (dataToSubmit.includes(PurchaseDataKind.PAYMENT_METHOD_URN)) {
      paymentMethodData['urn'] = existingPaymentMethodUrn;
    }

    const paymentData = { paymentMethodData };
    const performPurchaseResponse = await performPurchase(paymentData);

    if (performPurchaseResponse) {
      performPostPurchaseRedirect(performPurchaseResponse);
    }
  };

  /**
   * Entry point for the Complete Order button action.
   */
  const onClickCompleteOrderBtn = (): void => {
    const { isUsingExistingCard, selectedProvider } = props;
    const isCreditCardFormOpen = selectedProvider === constants.providers.STRIPE && !isUsingExistingCard;
    if (isCreditCardFormOpen) {
      setSubmitCreditCardForm(true);
    } else {
      confirmPayment();
    }
  };

  const confirmPayment = (): void => {
    if (isDisabled) return;
    const { selectedProvider, shouldCollectPaymentData } = props;
    setStatus('processing');
    sendPurchaseEvents();
    if (selectedProvider === constants.providers.RAZORPAY) {
      shouldCollectPaymentData
        ? handlePaymentSubmitRazor()
        : handleRazorFreePurchase();
    } else {
      handlePaymentSubmit();
    }
  };

  const doubleCheckCoupon = async (): Promise<void> => {
    const couponCode = props?.order?.coupon?.code;
    if (couponCode) {
      await props.updateOrder({ coupon: couponCode });
      const doubleCheckedCouponCode = props?.order?.coupon?.code;
      if (couponCode !== doubleCheckedCouponCode) {
        throw errorHelper.couponNoLongerValid();
      }
    }
  };

  const performPurchase = async (
    submitPaymentData: SubmitPaymentData
  ): Promise<any> => {
    const provider = props.selectedPaymentProvider;
    try {
      await doubleCheckCoupon();

      return await props.submitPurchase({
        data: submitPaymentData.paymentMethodData,
        provider: provider.providerName
      });
    } catch (e) {
      e.name = 'SubmitPurchaseError';
      e.extraData = Object.assign({}, e.extraData, {
        end_user_message: e.endUserMessage
      });
      reportError(e, {
        fingerprint: ['{{ type }}'],
        severity: Severity.Debug
      });
      setStatus('ok');
      props.displayError({
        checkout: errorHelper.purchaseFailedError({
          cause: e,
          message: e.endUserMessage
        })
      });
    }
  };

  const updateAutoRenew = (updateAutoRenew: boolean): void => {
    props.updateOrder({ ackAutoRenew: updateAutoRenew });
  };

  const {
    ackAutoRenew,
    address,
    checkoutStrategy,
    monthsUntilDue,
    order,
    grandTotal,
    selectedPaymentProvider,
    error
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.cartWrapper}>
        <Cart isSinglePage {...(props as any)} />
      </div>
      <div>
        <PaymentContainer
          isSinglePage
          isCreditCardFormProcessing={isCreditCardFormProcessing}
          onCreditCardFormCompletion={confirmPayment}
          resetCreditCardForm={(): void => setSubmitCreditCardForm(false)}
          setCreditCardSubmitProcessing={setCreditCardSubmitProcessing}
          submitCreditCardForm={submitCreditCardForm}
          {...(props as any)}
          onPaypalSuccess={(): void => setPayPalReady(true)}
        />
      </div>
      <div className={styles.checkoutBottomContainer}>
        <div className={styles.completeOrderContainer}>
          <CompleteOrderButton
            testID={'completeOrderBtn'}
            variant="primary"
            label="Complete Order"
            selectedProvider={props.selectedProvider}
            disabled={isDisabled}
            onClick={onClickCompleteOrderBtn}
            loading={isCompleteOrderButtonLoading()}
          />
          {error && (
            <div className={styles.errorContainer}>
              <ErrorMessage error={error} />
            </div>
          )}
          <TermsConditions
            ackAutoRenew={ackAutoRenew}
            submitOrderButtonLabel={checkoutStrategy.submitOrderButtonLabel}
            monthsUntilDue={monthsUntilDue}
            address={address}
            grandTotal={grandTotal as AmountAtom}
            paymentPlan={order?.paymentPlan}
            order={order}
            updateAutoRenew={updateAutoRenew}
            selectedPaymentProvider={selectedPaymentProvider}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  createRazorpayOrder: actions.getRazorpayOrderId,
  displayError: actions.displayError,
  submitPurchase: actions.submitPurchase,
  submitRazorpayPurchase: actions.submitRazorpayPurchase,
  updateOrder: actions.updateOrder
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: any) => {
  const loadingGeo = state.ui.loading,
    loadingUser = state.user.loading.user,
    loadingExperiments = state.user.loading.experiments;
  const selectedPaymentMethod = state.checkout.selectedPaymentMethod;

  const selectedPaymentProvider = getSelectedPaymentProvider(state);
  let selectedPaymentMethodData = undefined;
  if (selectedPaymentProvider) {
    selectedPaymentMethodData =
      state.checkout.paymentMethodData[selectedPaymentProvider.providerName];
  }

  return {
    order: state.checkout.order,
    checkoutStrategy: CheckoutStrategies.fromStrategyType(
      state?.checkout?.order?.input?.checkoutStrategyType
    ),
    isAuthenticated: state.user.authenticated,
    isLoading: loadingGeo || loadingUser || loadingExperiments,
    isPaymentMethodValid: isPaymentMethodValid(state),
    isUsingExistingCard: state.checkout.isUsingExistingCard,
    selectedPaymentMethod,
    selectedProvider: selectedPaymentMethod?.['provider'],
    shouldCollectPaymentData: shouldCollectPaymentData(state),
    purchaseData: state?.checkout?.purchaseData || {},
    existingPaymentMethodUrn: getExistingPaymentMethodUrn(state),
    selectedPaymentProvider,
    selectedPaymentMethodData,
    address: getAddress(state.checkout),
    email: getEmail(state),
    grandTotal: getGrandTotal(state),
    razorpayOrderId: getRazorpayOrderId(state),
    userFullName: getFullName(state),
    error: state.ui.errors.checkout,
    selectedCreditCard: getActiveCreditCard(state),
    ackAutoRenew: state?.checkout?.order?.input?.ackAutoRenew,
    monthsUntilDue: getMonthsUntilDue(state)
  };
};

export default flowRight(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthGuard
)(Checkout);

import auth from '@udacity/ureact-hoth';
import errorHelper from 'app/helpers/error-helper';

export const handleErrors = async (response) => {
  if (response.ok) {
    return response;
  }
  let jsonResponse;
  try {
    jsonResponse = await response.json();
  } catch (e) {
    jsonResponse = {};
  }
  throw errorHelper.httpError(
    response.statusText,
    response.status,
    jsonResponse
  );
};

export const sanitizeJson = (textPromise) => {
  return textPromise.then((text) => {
    if (text === '') {
      return {};
    }

    const jsonText = text.match(/^\)\]\}'/)
      ? text
          .split('\n')
          .slice(-1)
          .pop()
      : text;

    return JSON.parse(jsonText);
  });
};

class HttpHelper {
  constructor(url, extraHeaders = {}) {
    this.extraHeaders = extraHeaders;
    this.url = url;
  }

  getHeaders() {
    const jwt = auth.getJWT();
    const authHeader = jwt ? { Authorization: `Bearer ${auth.getJWT()}` } : {};
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authHeader,
      ...this.extraHeaders
    };
  }

  get(path, extraHeaders = {}) {
    const headers = {
      ...this.getHeaders(),
      ...extraHeaders
    };
    return fetch(`${this.url}${path}`, {
      method: 'GET',
      headers
    })
      .then(handleErrors)
      .then((response) => sanitizeJson(response.text()));
  }

  post(path, data, extraHeaders = {}) {
    const headers = {
      ...this.getHeaders(),
      ...extraHeaders
    };
    return fetch(`${this.url}${path}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
      .then(handleErrors)
      .then((response) => sanitizeJson(response.text()));
  }

  put(path, data) {
    return fetch(`${this.url}${path}`, {
      method: 'PUT',
      headers: this.getHeaders(),
      body: JSON.stringify(data)
    })
      .then(handleErrors)
      .then((response) => sanitizeJson(response.text()));
  }

  patch(path, data) {
    return fetch(`${this.url}${path}`, {
      method: 'PATCH',
      headers: this.getHeaders(),
      body: JSON.stringify(data)
    })
      .then(handleErrors)
      .then((response) => sanitizeJson(response.text()));
  }
}

export default HttpHelper;

import React from 'react';
import styles from './auth-tou.module.scss';
import constants from 'app/constants/constants';

const AuthTou = () => {
  return (
    <div className={styles.tou}>
      By continuing, you agree to Udacity&apos;s{' '}
      <a target="_blank" rel="noopener noreferrer" href={constants.links.TERMS}>
        Terms of Use
      </a>
      ,{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={constants.links.PRIVACY}
      >
        Privacy Policy
      </a>
      , and to receive promotional materials from Udacity.
    </div>
  );
};

export default AuthTou;

import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import App from 'app/app';
import GeneralError from 'app/views/general-error';
import Confirmation from 'app/views/confirmation';
import NotFound from 'app/views/not-found';
import store from 'app/store';

export default (
  <Provider store={store}>
    <Router>
      <Switch>
        <Redirect exact from="/" to="/subscribe/flexusd" />

        <Route path="/subscribe/:skuId" component={App} />
        <Route path="/recharge/:subscriptionUrn" component={App} />

        <Route path="/confirmation/:invoiceUrn" component={Confirmation} />

        <Route path="/error" component={GeneralError} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  </Provider>
);

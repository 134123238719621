import _ from 'lodash';
import React, { Suspense, lazy, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Loading } from '@udacity/veritas-components';

import constants from 'app/constants/constants';

import checkoutActions from 'app/actions/checkout';
import {
  getExistingCardData,
  getNewCardData,
  isPaymentMethodValid,
  shouldCollectPaymentData,
} from 'app/reducers/checkout';
import { getUserId } from 'app/reducers/user';
import styles from 'app/components/payment-container.module.scss';
import { Order } from 'app/models/orders';
import { PaymentMethodSelector } from './payment-methods/payment-method-selector';
import { CRUMB_MAP, PageId } from './common/breadcrumb-bar';
import { CheckOutStepProps } from '../views/checkout-step';
import { SavedCreditCard } from '../models/saved-credit-card';
import { HeroText } from './common/hero-text';
import { CreditCardFormData } from 'app/models/credit-card-form';
import analytics from 'app/helpers/analytics-helper';
import { getCountryCode, getMonthsUntilDue } from '../reducers/ui';
import {
  AvailablePaymentMethod,
  SelectedPaymentMethod
} from 'app/models/payment-method-types';

const RazorpayAddress = lazy(() =>
  import(
    /* webpackChunkName: "razorpay-address" */ './payment-methods/razorpay-address'
  )
);

const AffirmCredit = lazy(() =>
  import(
    /* webpackChunkName: "affirm-credit" */ './payment-methods/affirm/affirm-credit'
  )
);

const CkoCard = lazy(() =>
  import(
    /* webpackChunkName: "checkout-card" */ './payment-methods/cko/cko-card'
  )
);

const EbanxCard = lazy(() =>
  import(
    /* webpackChunkName: "ebanx-card" */ './payment-methods/ebanx/ebanx-card'
  )
);

const CreditCard = lazy(() =>
  import(
    /* webpackChunkName: "credit-card" */ './payment-methods/stripe/credit-card'
  )
);

const PaypalCheckout = lazy(() =>
  import(/* webpackChunkName: "paypal" */ './payment-methods/paypal')
);

type Props = DerivedProps & typeof mapDispatchToProps & CheckOutStepProps;

interface DerivedProps {
  availablePaymentMethods: AvailablePaymentMethod[];
  cardFormData: CreditCardFormData;
  existingCard: SavedCreditCard | undefined;
  shouldCollectPaymentData: boolean | undefined;
  selectedPaymentMethod: SelectedPaymentMethod;
  loading: boolean;
  monthsUntilDue: number;
  order: Order;
  prefillCountry: string | undefined;
  isPaymentMethodValid: boolean;
  isUsingExistingCard: boolean;
  userId: string;
  onPaypalSuccess?: () => void;
}

interface State {
  affirmModalOpen: boolean;
  showTabBar: boolean;
}

const mapStateToProps = (state: any): DerivedProps => ({
  availablePaymentMethods: _.get(
    state.checkout,
    'order.availablePaymentMethods',
    []
  ),
  cardFormData: getNewCardData(state),
  existingCard: getExistingCardData(state),
  shouldCollectPaymentData: shouldCollectPaymentData(state),
  selectedPaymentMethod: state.checkout.selectedPaymentMethod,
  loading: _.get(state.checkout, 'loading.orderUpdate') as boolean,
  monthsUntilDue: getMonthsUntilDue(state),
  order: state.checkout.order as Order,
  prefillCountry: getCountryCode(state),
  isPaymentMethodValid: isPaymentMethodValid(state),
  isUsingExistingCard: state.checkout.isUsingExistingCard,
  userId: getUserId(state),
});

const mapDispatchToProps = {
  convertZipCodeToState: checkoutActions.convertZipCodeToState,
  updateSelectedPaymentMethod: checkoutActions.updateSelectedPaymentMethod,
  updatePaymentMethodData: checkoutActions.updatePaymentMethodData,
  replaceAvailablePaymentMethodData:
    checkoutActions.replaceAvailablePaymentMethodData,
  useExistingCard: checkoutActions.useExistingCard
};

export class PaymentContainer extends React.Component<Props, State> {
  state = {
    affirmModalOpen: false,
    showTabBar: false
  };

  componentDidMount(): void {
    if (!this.props.isSinglePage){
      this.props.updateTitle('Payment Method');
    }
    this.selectInitialPaymentMethod();
    this.displaySavedCardIfPossible();
    this.setState({
      showTabBar: this.props.availablePaymentMethods.length > 1
    });
  }

  componentDidUpdate(
    _prevProps: Readonly<Props>,
    _prevState: Readonly<State>,
    _snapshot?: any
  ): void {
    this.possiblySkipPayment();
  }

  selectInitialPaymentMethod = (): void => {
    const { availablePaymentMethods, selectedPaymentMethod } = this.props;
    if (!selectedPaymentMethod || !selectedPaymentMethod.valid) {
      const firstAvailableMethod = availablePaymentMethods[0];
      this._selectPaymentMethod(firstAvailableMethod.provider, false);
    }
  };

  /**
   * If the new vs. saved card preference has not been set, display saved card if there is one.
   */
  displaySavedCardIfPossible = (): void => {
    // Must use null because false means user chose to enter a new card.
    if (this.props.isUsingExistingCard === null) {
      if (this.props.existingCard) {
        this.useExistingCard(true, false);
      }
    }
  };

  possiblySkipPayment = (): void => {
    const { shouldCollectPaymentData } = this.props;
    if (shouldCollectPaymentData === false) {
      this.goToReview(true);
    }
  };

  onCardFormChange = (data: any): void => {
    const { selectedPaymentMethod } = this.props;
    // Only save these changes if the selected payment method is a card.
    // The CC form calls update when mounting, which overrides data when initially selecting
    // a different provider.
    if (
      selectedPaymentMethod &&
      selectedPaymentMethod.provider === constants.providers.STRIPE
    ) {
      this.props.updatePaymentMethodData({
        provider: selectedPaymentMethod.provider,
        data: {
          ...data.card,
          urn: data.urn,
          valid: data.valid
        }
      });
    }
  };

  /**
   * Store the payment method urn in state, and choose to checkout with existing card.
   * Set paymentMethodUrn and savedCard to undefined to use existing payment method.
   *
   * @param paymentMethodUrn the new payment method's urn
   * @param providerName the name of the provider
   * @param savedCard the credit card's data
   */
  continueWithSavedCard = (providerName: string) => (
    paymentMethodUrn: string | undefined,
    savedCard: SavedCreditCard | undefined
  ): void => {
    if (savedCard) {
      this.props.replaceAvailablePaymentMethodData({
        provider: providerName,
        data: {
          card: {
            brand: savedCard.brand,
            last4: savedCard.last4,
            name: savedCard.name
          },
          urn: paymentMethodUrn,
          valid: true
        }
      });
    }
    if (this.props.isSinglePage) {
      this.props.onCreditCardFormCompletion();
    } else {
      this.props.useExistingCard(true);
      this.goToReview();
    }
  };

  afterPaypalAuthorize = (): void => {
    if (this.props.isSinglePage && this.props.onPaypalSuccess){
      this.props.onPaypalSuccess();
    } else {
      this.props.toPage(PageId.review, this.props.history, false);
    }
  };

  _getPaymentComponent(method: any): JSX.Element | undefined {
    switch (method.provider) {
      case constants.providers.CKO:
        return (
          <CkoCard
            userId={this.props.userId}
            onFormSubmit={this.continueWithSavedCard(method.provider)}
          />
        );
      case constants.providers.EBANX_CARD:
        return (
          <EbanxCard
            userId={this.props.userId}
            onFormSubmit={this.continueWithSavedCard(method.provider)}
          />
        );
      case constants.providers.STRIPE: {
        return this._getCreditCardWrapper(this.props.existingCard);
      }
      case constants.providers.PAYPAL:
        return (
          <div className={styles.paypalContainer}>
            <PaypalCheckout afterPaypalAuthorize={this.afterPaypalAuthorize} />
          </div>
        );
      case constants.providers.AFFIRM_CREDIT:
        return <AffirmCredit showMarketingText={true} />;
      case constants.providers.RAZORPAY:
        return <RazorpayAddress onOrderCreationSuccess={this.goToReview} />;
      case constants.providers.NOOP:
        return undefined;
      default:
        return <div />;
    }
  }

  useExistingCard = (use: boolean, userInvoked: boolean): void => {
    this.props.useExistingCard(use);
    if (userInvoked) {
      const eventAction = use ? 'use_saved_card' : 'use_new_card';
      analytics.trackAction(
        eventAction,
        CRUMB_MAP[PageId.payment].analyticsName
      );
    }
  };

  _getCreditCardWrapper(existingData?: SavedCreditCard): JSX.Element {
    const {
      isUsingExistingCard,
      submitCreditCardForm,
      resetCreditCardForm,
      isCreditCardFormProcessing,
      setCreditCardSubmitProcessing
    } = this.props;
    let { checkoutStrategy } = this.props;
    const userToggledExistingCard = (use: boolean): void =>
      this.useExistingCard(use, true);
    const useExistingCard = (): void => userToggledExistingCard(true);
    const useNewCard = (): void => userToggledExistingCard(false);

    const form = (
      <CreditCard
        checkoutStrategy={checkoutStrategy}
        userId={this.props.userId}
        existingCard={existingData}
        isUsingExistingCard={isUsingExistingCard}
        prefillCountry={this.props.prefillCountry}
        useExistingCard={useExistingCard}
        useNewCard={useNewCard}
        submitCreditCardForm={submitCreditCardForm}
        resetCreditCardForm={resetCreditCardForm}
        isCreditCardFormProcessing={isCreditCardFormProcessing}
        setCreditCardSubmitProcessing={setCreditCardSubmitProcessing}
        onFormSubmit={this.continueWithSavedCard('stripe')}
        isSinglePage={this.props.isSinglePage}
      />
    );
    return <>{form}</>;
  }

  private goToReview = (replace: boolean = false): void => {
    const {
      cardFormData,
      convertZipCodeToState,
    } = this.props;
    analytics.trackAction(
      'continue_button_clicked',
      CRUMB_MAP[PageId.payment].analyticsName
    );
    if (
      cardFormData &&
      cardFormData.country === 'US' &&
      cardFormData.postalCode
    ) {
      convertZipCodeToState(cardFormData.postalCode);
    }
    this.props.toPage(PageId.review, this.props.history, replace);
  };

  /**
   * Select the payment method to be displayed.
   * @param provider the method's name
   * @param userInvoked did users choose this method? Don't track the action if they did not
   */
  _selectPaymentMethod = (provider: string, userInvoked: boolean): void => {
    if (userInvoked) {
      analytics.trackAction(
        `select_${provider}_button_clicked`,
        CRUMB_MAP[PageId.payment].analyticsName
      );
    }
    this.props.updateSelectedPaymentMethod(provider);
  };

  _isPaymentMethodOpen(provider: string): boolean {
    const { selectedPaymentMethod } = this.props;
    if (selectedPaymentMethod) {
      return selectedPaymentMethod.provider === provider;
    }
    return false;
  }

  buildPaymentMethod = (method: any): JSX.Element => {
    const paymentMethodOpen = this._isPaymentMethodOpen(method.provider);
    return (
      <React.Fragment key={method.provider}>
        {paymentMethodOpen && (
          <Suspense fallback={<Loading />}>
            {this._getPaymentComponent(method)}
          </Suspense>
        )}
      </React.Fragment>
    );
  };

  buildTabBar = (): JSX.Element => {
    const { availablePaymentMethods, selectedPaymentMethod } = this.props;
    const selectedProvider =
      selectedPaymentMethod && selectedPaymentMethod['provider'];
    return (
      <div className={styles.tabBar}>
        {availablePaymentMethods.map((provider) => {
          const providerName = provider.provider;
          return (
            <PaymentMethodSelector
              key={providerName}
              provider={providerName}
              selected={selectedProvider === providerName}
              doSelect={(): void =>
                this._selectPaymentMethod(providerName, true)
              }
            />
          );
        })}
      </div>
    );
  };

  render(): ReactNode {
    const { order, isSinglePage } = this.props;

    return (
      <div className={styles.paymentContainer}>
        <HeroText heroText={'Payment Method'} subHeroText={isSinglePage ? '' : order.name} />
        {this.state.showTabBar && this.buildTabBar()}
        {_.map(this.props.availablePaymentMethods, this.buildPaymentMethod)}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-google-recaptcha';
import Form from '../common/form';
import config from 'config';
import styles from './recaptcha-form.module.scss';

export default class RecaptchaForm extends Component {
  static displayName = 'components/forms/recaptcha-form';

  static propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showRecaptcha: PropTypes.bool.isRequired,
    onRecaptchaChange: PropTypes.func.isRequired
  };

  recaptchaRef = React.createRef();

  handleSubmit = () => {
    const instance = this.recaptchaRef.current;
    this.props.onRecaptchaChange(instance.getValue());
  };

  render() {
    const { buttonLabel, onSubmit, showRecaptcha } = this.props;
    return (
      <Form buttonLabel={buttonLabel} onSubmit={onSubmit}>
        {this.props.children}
        {showRecaptcha && (
          <div className={styles.recaptcha}>
            <Recaptcha
              ref={this.recaptchaRef}
              sitekey={config.REACT_APP_RECAPTCHA_KEY}
              onChange={this.handleSubmit}
            />
          </div>
        )}
      </Form>
    );
  }
}

import React from 'react';
import errorHelper from 'app/helpers/error-helper';
import ErrorMessage from 'app/components/common/error-message';
import Header from 'app/components/common/header';

const GeneralError = () => {
  const error = errorHelper.orderFailedError({});
  return (
    <div>
      <Header />
      <main>
        <ErrorMessage error={error} />
      </main>
    </div>
  );
};

export default GeneralError;

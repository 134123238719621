export default {
  CREATE_ORDER: 'CREATE_ORDER',
  CREATE_ORDER_COMPLETE: 'CREATE_ORDER_COMPLETE',
  DISPLAY_ERROR: 'DISPLAY_ERROR',
  FETCH_ANONYMOUS_ID: 'FETCH_ANONYMOUS_ID',
  FETCH_ANONYMOUS_ID_COMPLETE: 'FETCH_ANONYMOUS_ID_COMPLETE',
  FETCH_CURRENT_USER: 'FETCH_CURRENT_USER',
  FETCH_CURRENT_USER_COMPLETE: 'FETCH_CURRENT_USER_COMPLETE',
  FETCH_INVOICE_DATA: 'FETCH_INVOICE_DATA',
  FETCH_INVOICE_DATA_COMPLETE: 'FETCH_INVOICE_DATA_COMPLETE',
  FETCH_RAZORPAY_ORDER_ID: 'FETCH_RAZORPAY_ORDER_ID',
  FETCH_RAZORPAY_ORDER_ID_COMPLETE: 'FETCH_RAZORPAY_ORDER_ID_COMPLETE',
  FETCH_USER_GEO_LOCATION: 'FETCH_USER_GEO_LOCATION',
  FETCH_USER_GEO_LOCATION_COMPLETE: 'FETCH_USER_GEO_LOCATION_COMPLETE',
  FORCE_EXPERIMENT_VARIATION: 'FORCE_EXPERIMENT_VARIATION',
  FETCH_EXPERIMENTS: 'FETCH_EXPERIMENTS',
  FETCH_EXPERIMENTS_COMPLETE: 'FETCH_EXPERIMENTS_COMPLETE',
  FETCH_FEATURE: 'FETCH_FEATURE',
  FETCH_FEATURE_COMPLETE: 'FETCH_FEATURE_COMPLETE',
  CONVERT_ZIP_CODE_TO_STATE: 'CONVERT_ZIP_CODE_TO_STATE',
  SUBMIT_PURCHASE: 'SUBMIT_PURCHASE',
  SUBMIT_PURCHASE_COMPLETE: 'SUBMIT_PURCHASE_COMPLETE',
  SUBMIT_RAZORPAY_PURCHASE: 'SUBMIT_RAZORPAY_PURCHASE',
  SUBMIT_RAZORPAY_PURCHASE_COMPLETE: 'SUBMIT_RAZORPAY_PURCHASE_COMPLETE',
  UPDATE_ORDER: 'UPDATE_ORDER',
  UPDATE_ORDER_COMPLETE: 'UPDATE_ORDER_COMPLETE',
  UPDATE_ORDER_WITH_USER: 'UPDATE_ORDER_WITH_USER',
  UPDATE_ORDER_WITH_USER_COMPLETE: 'UPDATE_ORDER_WITH_USER_COMPLETE',
  UPDATE_PAYMENT_METHOD_DATA: 'UPDATE_PAYMENT_METHOD_DATA',
  REPLACE_AVAILABLE_PAYMENT_METHOD: 'REPLACE_AVAILABLE_PAYMENT_METHOD',
  UPDATE_SELECTED_PAYMENT_METHOD: 'UPDATE_SELECTED_PAYMENT_METHOD',
  RESET_ERROR: 'RESET_ERROR',
  USE_EXISTING_CARD: 'USE_EXISTING_CARD'
};

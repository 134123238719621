// TODO: This should be _very_ temporary, until braavos is updated
// to return raw integers for major units (currently only return minor
// unit and string values for major). Also the idea of converting a
// non-USD price to USD for analytics purposes should be server side
// too.

const US_CURRENCY_CONVERSION = {
  AED: 0.27,
  AUD: 0.79,
  BHD: 2.65,
  BRL: 0.24,
  CAD: 0.8,
  CHF: 1.04,
  CNY: 0.15,
  EGP: 0.056,
  EUR: 1.14,
  GBP: 1.46,
  HKD: 0.13,
  INR: 0.015,
  JOD: 1.41,
  JPY: 0.0091,
  KRW: 0.00093,
  KWD: 3.31,
  MAD: 0.1,
  NZD: 0.72,
  OMR: 2.6,
  QAR: 0.27,
  SAR: 0.27,
  SGD: 0.75,
  USD: 1.0,
  ZAR: 0.076
};

function getMajorUnitDenomination(currency) {
  const currenciesWith1000Denominations = ['KWD', 'OMR', 'JOD', 'BHD'];
  const currenciesWith1Denominations = ['KRW', 'JPY'];

  if (currenciesWith1000Denominations.includes(currency)) {
    return 1000;
  } else if (currenciesWith1Denominations.includes(currency)) {
    return 1;
  } else {
    return 100;
  }
}

function convertToMajorAmount(amount, currency) {
  if (amount === 0) {
    return 0;
  }

  const denomination = getMajorUnitDenomination(currency);
  const decimalAmount = amount / denomination;
  const decimalPlaces = Math.log10(denomination);

  return Number(decimalAmount.toFixed(decimalPlaces));
}

function convertToMajorAmountInUsd(amount, currency) {
  return convertToMajorAmount(amount * US_CURRENCY_CONVERSION[currency], 'USD');
}

export default {
  getMajorUnitDenomination,
  convertToMajorAmount,
  convertToMajorAmountInUsd
};

import { AffirmCreditPaymentProvider } from './affirm-credit-payment-provider';
import { EbanxCardPaymentProvider } from './ebanx-card-payment-provider';
import { CkoPaymentProvider } from './cko-provider';
import { PaypalPaymentProvider } from './paypal-payment-provider';
import { RazorpayPaymentProvider } from './razorpay-payment-provider';
import { StripePaymentProvider } from './stripe-payment-provider';
import { PaymentProvider } from './payment-provider';

const PROVIDERS = [
  new StripePaymentProvider(),
  new PaypalPaymentProvider(),
  new RazorpayPaymentProvider(),
  new AffirmCreditPaymentProvider(),
  new CkoPaymentProvider(),
  new EbanxCardPaymentProvider()
];

export class PaymentProviders {
  /**
   * Get the provider model from its type.
   * @param providerType one of constants.providers
   */
  static fromProviderType(providerType: string): PaymentProvider {
    const provider = PROVIDERS
      .find(provider => provider.providerName === providerType);

    if (!provider) {
      throw new Error(
        `Could not find a PaymentProvider for type ${providerType}`
      );
    }

    return provider;
  }

  /**
   * Get the provider model from its payment method type.
   * @param paymentMethodType the type of a Braavos Payment Method
   */
  static fromPaymentMethodType(paymentMethodType: string): PaymentProvider {
    const provider = PROVIDERS
      .find(provider => provider.paymentMethodType === paymentMethodType);

    if (!provider) {
      throw new Error(
        `Could not find a PaymentProvider for payment method type ${paymentMethodType}`
      );
    }

    return provider;
  }
}

import get from 'lodash/get';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import Analytics from '@udacity/ureact-analytics';

import store from 'app/store';
import constants from 'app/constants/constants';
import currencyHelper from 'app/helpers/currency-helper';
import { getCountryCode } from 'app/helpers/geo-helper';
import { ExperimentVariant } from 'app/models/experiments';
import { getTrialData } from 'app/models/promotions';
import { PaymentPlanType } from '../models/orders';

export const CHECKOUT_PAGE_CATEGORY = 'Checkout Page';

export const events = {
  // Original Homeone Events
  CHECKOUT_ABANDONED_STEP: 'Abandoned Checkout Step',
  CHECKOUT_COMPLETED_ORDER: 'Completed Order',
  CHECKOUT_VIEWED_STEP: 'Viewed Checkout Step',

  // Other Actions
  CONFIRMATION_PAGE_VIEW: 'Confirmation Page Viewed',
  CHECKOUT_PAGE_VIEW: 'Checkout Page Viewed',
  RECEIPT_FORM_VIEWED: 'Receipt Form Viewed',
  RECEIPT_DOWNLOADED: 'Receipt Downloaded',
  COUPON_CODE_APPLIED: 'Coupon Code Applied',
  COUPON_CODE_REMOVED: 'Coupon Code Removed',
  COUPON_CHECKBOX_OPT: 'checkboxOptEvent',
  PAYMENT_UPDATED: 'Payment Info Updated',
  PAYPAL_ENTERED: 'PayPal Info Entered',
  AFFIRM_CHECKOUT_CANCELED: 'Affirm Checkout Canceled',
  AFFIRM_CHECKOUT_CLICKED: 'Affirm Checkout Clicked',
  AFFIRM_CHECKOUT_INPUT_ERROR: 'Affirm Checkout Input Error',
  AFFIRM_CHECKOUT_OPENED: 'Affirm Checkout Opened',
  CONFIRM_PURCHASE: 'Confirm Purchase Clicked',
  SUCCESSFUL_PURCHASE: 'Purchase Successful',
  INVOICE_PAYMENT_COMPLETED: 'Invoice Payment Completed',
  INVOICE_PAYMENT_FAILED: 'Invoice Payment Failed',
};

interface DefaultPropsParam {
  category?: string; // The category attribute in the Page Call
  name?: string; // The name attribute in the Page Call
}

const defaultAnalyticProperties = async (
  param: DefaultPropsParam = {}
): Promise<object> => {
  const state = store.getState();
  const user = get(state, 'user.user');
  const order = get(state, 'checkout.order');

  const email = get(user, 'email');
  const isEmailVerified = get(user, 'emailVerified');
  const userId = get(user, 'userId');

  const stripePaymentMethod = find(get(order, 'availablePaymentMethods', []), {
    provider: constants.providers.STRIPE
  });
  const isCreditCardPrefilled = get(stripePaymentMethod, 'data.valid', false);

  const currency = get(order, 'currency');
  const skuName = get(order, 'name');
  const couponCode = get(order, 'coupon.code');
  const couponDescription = get(order, 'coupon.description');
  const price = currencyHelper.convertToMajorAmount(
    get(order, 'baseLineItems.subtotal.amount', 0),
    currency
  );
  const discountAmount = couponCode
    ? currencyHelper.convertToMajorAmount(
        get(order, 'coupon.discount.amount', 0),
        currency
      )
    : 0;
  const priceInUSD = currencyHelper.convertToMajorAmountInUsd(
    get(order, 'baseLineItems.subtotal.amount', 0),
    currency
  );
  const grandTotal = currencyHelper.convertToMajorAmount(
    get(order, 'baseLineItems.grandTotal.amount', 0),
    currency
  );
  const grandTotalInUSD = currencyHelper.convertToMajorAmountInUsd(
    get(order, 'baseLineItems.grandTotal.amount', 0),
    currency
  );

  const isSubscriptionCheckout =
    get(order, 'input.checkoutStrategyType', '').indexOf('subscription') >= 0;

  const startTime = new Date().toISOString();
  const nodeKey = get(order, 'nanodegreeKey');
  const skuUrn = get(order, 'skuUrn');
  const productKey = skuUrn ? skuUrn.replace(/.*:/, '') : null;
  const berliozCountry = await getCountryCode();
  const referrer = get(order, 'input.referrer');
  const talkableVisitorUuid = get(order, 'input.talkable_visitor_uuid');
  const experiments = getExperiments(state);
  const trialData = getTrialData(order);

  const paymentPlanType = get(order, 'paymentPlan.type');
  let duration;

  if (paymentPlanType) {
    duration =
      paymentPlanType === PaymentPlanType.upfrontRecurring
        ? get(order, 'paymentPlan.upfront_interval_count')
        : get(order, 'paymentPlan.recurring_interval_count');
  } else {
    duration = 1;
  }

  // GA
  const label = `${CHECKOUT_PAGE_CATEGORY}-${productKey}-${skuName}`;
  const value = grandTotalInUSD;

  /* Note that for historical purposes, `sku` is set to the ndKey (e.g. nd001)
   * and `product_key` is set to either the original GAE product key or the
   * braavos sku.urn.id (effectively the value of the `sku` URL querystring param).
   */
  const props = {
    ndop_selected_payment_plan: get(order, 'input.payment_plan', null),
    is_subscription: isSubscriptionCheckout,
    full_page_url: window.location.href,
    referrer_url: document.referrer,
    cc_prefilled: isCreditCardPrefilled,
    checkout_app: 'payment_web',
    sku_urn: skuUrn,
    sku: nodeKey,
    product_key: productKey,
    sku_name: skuName,
    currency,
    coupon: couponCode,
    coupon_description: couponDescription,
    coupon_valid: !!couponCode,
    discount_amount: discountAmount,
    price: grandTotal,
    price_before_discount: price,
    price_usd: priceInUSD,
    account_key: userId,
    user_id: userId,
    user_state: userId ? 'logged_in' : 'logged_out',
    email,
    is_email_verified: isEmailVerified,
    value,
    category: param.category,
    experiments,
    label,
    start_time: startTime,
    berlioz_country: berliozCountry,
    referrer,
    talkable_visitor_uuid: talkableVisitorUuid,
    is_trial: !!trialData,
    trial_interval_count: trialData?.trialIntervalCount,
    duration: duration,
    payment_plan_type: paymentPlanType
  };

  if (isSubscriptionCheckout) {
    props['auto_renew'] = get(order, 'input.autoRenewEnabled');
  }

  return props;
};

/**
 * Return the experiments data in a format Amplitude expects.
 *
 * @param rootState
 * @return string of experiment/variation pairs like ['experiment_1:variation_1', 'experiment_2:variation_4']
 * if the variation is null, then the string would just be 'experiment_1:'
 */
const getExperiments = (rootState: object): string[] => {
  const { experiments } = rootState['user'];
  if (experiments) {
    return Object.entries(experiments).map((arr) => {
      const experimentKey = arr[0];
      const variationKey =
        // @ts-ignore
        arr[1] && arr[1]['variationKey'] ? arr[1]['variationKey'] : ''; // don't output literal 'null/undefined'
      return experimentKey + ':' + variationKey;
    });
  }
  return [];
};

export default {
  async trackPageView(
    category: string,
    name: string,
    properties: object = {}
  ): Promise<void> {
    const defaultProps = await defaultAnalyticProperties({
      category,
      name
    });

    return Analytics.page(category, name, {
      ...defaultProps,
      ...properties
    });
  },

  async trackEvent(eventName: string, properties: object = {}): Promise<void> {
    const defaultProps = await defaultAnalyticProperties();

    const combinedProperties = {
      ...defaultProps,
      ...properties
    };
    if (eventName === events.CHECKOUT_COMPLETED_ORDER) {
      combinedProperties['revenue'] = combinedProperties['price'];
      combinedProperties['payment_recurrence'] = get(
        store.getState(),
        'checkout.order.input.checkoutStrategyType'
      );
    }

    return Analytics.track(eventName, combinedProperties);
  },

  /**
   * Create a "CTA Clicked" Track Event
   * @param eventAction the name of the action, e.g. "sign_in_clicked"
   * @param pageCategory the page's analyticsName where the action occurred. e.g. "Order Details"
   */
  async trackAction(
    eventAction: string,
    pageCategory: string,
    properties: object = {}
  ): Promise<void> {
    const defaultProps = await defaultAnalyticProperties();

    return Analytics.track('CTA Clicked', {
      event_action: eventAction,
      page_category: pageCategory,
      ...defaultProps,
      ...properties
    });
  },

  /**
   * Identify the variations the user falls under for experiments.
   */
  async identifyExperiments(experiments: ExperimentVariant[]): Promise<void> {
    if (!isArray(experiments) || experiments.length === 0) {
      return;
    }
    const traits = {};
    experiments.forEach(
      (exp) => (traits[exp.experimentKey] = exp.variationKey)
    );
    return Analytics.identify(traits);
  }
};

export const testables = {
  getExperiments
};

export default {
  name: {
    label: 'Full Name',
    placeholder: 'Your Name'
  },
  company: {
    label: 'Company Name',
    placeholder: 'Your Company name'
  },
  address_1: {
    label: 'Company Address 1',
    placeholder: '129 West 81st Street'
  },
  address_2: {
    label: 'Company Address 2',
    placeholder: 'ZIP Code, City - Country'
  }
};

import checkout from './checkout';
import invoice from './invoice';
import ui from './ui';
import user from './user';

export default {
  checkout,
  invoice,
  ui,
  user
};

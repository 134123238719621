import constants from 'app/constants/constants';
import { PaymentProvider } from './payment-provider';
import { PurchaseDataKind } from './purchase-data-kind';
import Payment from 'payment';
import HttpHelper from 'app/helpers/http-helper';
import config from 'config';
import { SavedCreditCard } from '../saved-credit-card';
import { CreditCardBrands } from '../credit-card-brands';

export class CkoPaymentProvider extends PaymentProvider {
  get providerName(): string {
    return constants.providers.CKO;
  }

  get paymentMethodType(): string {
    return 'checkout_card';
  }

  requiresCardToken(): boolean {
    return false;
  }

  canAuthorizeCharge(): boolean {
    return true;
  }

  getPurchaseDataToSubmit(): PurchaseDataKind[] {
    return [PurchaseDataKind.COLLECTED_DATA];
  }
}

export interface CkoPaymentMethodData {
  name: string,
  expiry: string,
  cvc: string,
  cardNumber: string
  country: string
}

/**
 * Return the initial form field values
 * @country pre-determined country
 */
export const initialFormValues = (country: string | undefined): CkoPaymentMethodData => {
  return {
    name: '',
    expiry: '',
    cvc: '',
    cardNumber: '',
    country: country || ''
  };
};

/**
 * Convert a token response object to a standard saved card object.
 * @param response Checkout Token call response
 */
export const convertToSavedCreditCard = (response: CkoTokenSuccessResponse): SavedCreditCard => {
  return {
    last4: response.last4,
    name: response.name,
    brand: CreditCardBrands.unknown  // Checkout does not document their card brand mapping
  };
};

export type CkoTokenResponse = CkoTokenSuccessResponse | CkoTokenFailedResponse

export interface CkoTokenSuccessResponse {
  type: string,
  token: string,
  expires_on: string,
  expiry_month: number,
  expiry_year: number,
  name: string,
  scheme: string, // mc, visa, etc ...
  last4: string,
  issuer_country: string,
}

export interface CkoTokenFailedResponse {
  request_id: string,
  error_type: string,
  error_codes: string[]
}

/**
 * Type guard to determine if the token response is successful
 */
export const isTokenCallSuccessful = (res: CkoTokenResponse | undefined): res is CkoTokenSuccessResponse => {
  if (!res) {
    return false;
  }
  return 'token' in res;
};

const getTokenEndpoint = (apiKey: string): string => {
  if (apiKey.startsWith('pk_test_')) {
    return 'https://api.sandbox.checkout.com/tokens';
  } else {
    return 'https://api.checkout.com/tokens';
  }
};

export const createCardToken = async (data: CkoPaymentMethodData): Promise<CkoTokenResponse> => {
  const parsedDate = Payment.fns.cardExpiryVal(data.expiry);
  const numberNoSpace = data.cardNumber.replace(/\s/g, '');
  const requestBody = {
    type: 'card',
    name: data.name,
    number: numberNoSpace,
    expiry_month: parsedDate.month.toString(),
    expiry_year: parsedDate.year.toString(),
    cvv: data.cvc
  };

  const apiKey = config.REACT_APP_CKO_API_KEY as string;
  const url = getTokenEndpoint(apiKey);
  const header = { Authorization: apiKey };
  return await new HttpHelper(url, header).post('', requestBody);
};

import { Order } from 'app/models/orders';
import React, { FC, ReactNode, useEffect } from 'react';
import {
  RouteComponentProps,
  Switch,
  withRouter,
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import GuardedAuthentication from './_authentication';
import Checkout from './_checkout';
import analytics, { events } from 'app/helpers/analytics-helper';

const SinglePageCheckout: FC<RouteComponentProps & {
  queryAndSendExperiments: any;
  order?: Order;
}> = ({ match, queryAndSendExperiments, order }) => {
  useEffect(() => {
    analytics.trackEvent(events.CHECKOUT_VIEWED_STEP);
  }, []);

  const { skuId = 'flexusd' } = (match.params || {}) as { skuId: string };
  return (
    <Router basename={'/subscribe/' + String(skuId)}>
      <Switch>
        <Route exact path="/" component={Checkout} />
        <Route
          path="/auth"
          render={(props: any): ReactNode => (
            <GuardedAuthentication
              {...props}
              order={order}
              queryAndSendExperiments={queryAndSendExperiments}
              isSinglePage
            />
          )}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(SinglePageCheckout);

import constants from 'app/constants/constants';
import { PaymentProvider } from './payment-provider';
import { Address } from 'app/models/address';
import config from 'config';
import { PurchaseDataKind } from './purchase-data-kind';

/**
 * The dynamic attributes that are needed to open the hosted Razorpay modal.
 */
export interface RazorpayOrderCreationRequest {
  address: Address,
  amount: number;
  description: string;
  email: string;
  handler: (response: object) => Promise<void>;
  name: string;
  ondismiss: () => void;
  orderId: string;
}

export class RazorpayPaymentProvider extends PaymentProvider {
  get providerName(): string {
    return constants.providers.RAZORPAY;
  }

  get paymentMethodType(): string {
    return 'razorpay';
  }

  requiresCardToken(): boolean {
    return false;
  }

  canAuthorizeCharge(): boolean {
    return false;
  }

  /**
   * Construct the complete request object to be sent to Razorpay for creating the modal.
   * Full list of options at https://razorpay.com/docs/payment-gateway/web-integration/standard/checkout-form/
   *  @param req our request object
   */
  getRequestObject(req: RazorpayOrderCreationRequest): object {
    const { address } = req;
    const options = {
      'key': config.REACT_APP_RAZORPAY_API_KEY,
      'amount': req.amount,
      'currency': 'INR',
      'name': 'Udacity',
      'description': req.description,
      'order_id': req.orderId,
      'handler': req.handler,
      'modal': {
        'ondismiss': req.ondismiss
      },
      'prefill': {
        'contact': address.phoneNumber,
        'email': req.email,
        'name': req.name
      },
      'notes': {
        address: `${address.line1}, ${address.line2}, ${address.city}, ${address.region}, ${address.postalCode}`,
        pincode: address.postalCode,
        state: address.region,
        phone: address.phoneNumber
      },
      'theme': {
        'color': '#02b3e4'
      }
    };
    return options;
  }

  getPurchaseDataToSubmit(): PurchaseDataKind[] {
    return [PurchaseDataKind.COLLECTED_DATA];
  }
}

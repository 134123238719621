import _ from 'lodash';
import Types from '../actions/types';

export const defaultState = {
  authenticated: false,
  user: null,
  loading: {
    user: true,
    experiments: true
  },
  experiments: {},
  features: {}
};

export default (state = defaultState, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case Types.FETCH_ANONYMOUS_ID_COMPLETE:
      return {
        ...state,
        anonymousId: action.payload
      };

    case Types.FETCH_CURRENT_USER:
      return {
        ...state,
        loading: {
          ...state.loading,
          user: true
        }
      };

    case Types.FETCH_CURRENT_USER_COMPLETE: {
      const user = action.payload;

      return {
        ...state,
        authenticated: !_.isEmpty(user),
        loading: {
          ...state.loading,
          user: false
        },
        user
      };
    }

    case Types.FETCH_EXPERIMENTS:
    case Types.FETCH_FEATURE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          experiments: true
        }
      };
    }

    case Types.FORCE_EXPERIMENT_VARIATION: {
      const { projectId, experimentKey, variationKey } = action.payload;
      return {
        ...state,
        experiments: {
          ...state.experiments,
          [experimentKey]: {
            projectId,
            variationKey
          }
        }
      };
    }

    case Types.FETCH_EXPERIMENTS_COMPLETE: {
      const experiments = state.experiments;
      action.payload.forEach((experiment) => {
        const { projectId, experimentKey, variationKey } = experiment;
        experiments[experimentKey] = {
          projectId,
          variationKey
        };
      });
      return {
        ...state,
        loading: {
          ...state.loading,
          experiments: false
        },
        experiments
      };
    }

    case Types.FETCH_FEATURE_COMPLETE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          experiments: false
        },
        features: {
          ...state.features,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
};

// Transform the experiments state into the format Braavos' metadata expects.
export const getExperimentsMetadata = (state) => {
  const { experiments } = state.user;
  if (experiments) {
    return Object.keys(experiments).map((experiment_key) => {
      const experiment = experiments[experiment_key];
      return {
        experiment_key,
        project_id: experiment.projectId,
        variation_key: experiment.variationKey
      };
    });
  }
};

export const isUserInVariant = (state, experimentKey, variationKey) => {
  const userExperiment = state.user.experiments[experimentKey];
  return userExperiment ? userExperiment.variationKey === variationKey : false;
};

export const getFullName = (state) => {
  const first = _.get(state.user, 'user.first', '');
  const last = _.get(state.user, 'user.last', '');
  return `${first} ${last}`.trim();
};

export const getEmail = (state) => {
  return _.get(state.user, 'user.email', '');
};

export const getPhoneNumber = (state) => {
  return _.get(state.user, 'user.phoneNumber', '');
};

export const getUserId = (state) => {
  return _.get(state.user, 'user.userId');
};

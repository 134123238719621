import _ from 'lodash';

export default class URN {
  constructor(namespace, type, subtype, id) {
    this.namespace = namespace;
    this.type = type;
    this.subtype = subtype;
    this.id = id;
  }

  static fromString(urnString) {
    if (urnString === null || urnString === undefined) {
      throw new Error('URN should not be null or undefined');
    }

    var parts = urnString.split(':');
    if (parts[0] !== 'urn') {
      throw new Error(`Invalid URN ${urnString}, should begin with 'urn:'`);
    }

    if (parts.length === 5) {
      return new URN(parts[1], parts[2], parts[3], parts[4]);
    } else if (parts.length === 4) {
      return new URN(parts[1], parts[2], null, parts[3]);
    } else if (parts.length > 5) {
      return new URN(parts[1], parts[2], parts[3], parts.slice(4).join(':'));
    } else {
      throw new Error(
        `Invalid URN ${urnString}, invalid length for the urn string`
      );
    }
  }

  isEqual(urnObject) {
    return (
      this.namespace === urnObject.namespace &&
      this.type === urnObject.type &&
      this.subtype === urnObject.subtype &&
      this.id === urnObject.id
    );
  }

  toString() {
    return _.compact([
      'urn',
      this.namespace,
      this.type,
      this.subtype,
      this.id
    ]).join(':');
  }
}

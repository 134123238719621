import _keyBy from 'lodash/keyBy';
import { CheckoutStrategy } from './checkout-strategy';
import { InitialSubscriptionCheckoutStrategy } from './initial-subscription-strategy';
import { RechargeSubscriptionCheckoutStrategy } from './recharge-subscription-strategy';

const strategies: CheckoutStrategy[] = [
  new InitialSubscriptionCheckoutStrategy(),
  new RechargeSubscriptionCheckoutStrategy()
];

const strategiesByType = _keyBy(strategies, (strategy): string => strategy.type);

export class CheckoutStrategies {
  static fromRouterPath(routerPath: string): CheckoutStrategy {
    const checkoutStrategy = strategies.find((strategy): boolean =>
      strategy.supportsRouterPath(routerPath)
    );

    if (!checkoutStrategy) {
      throw new Error(
        `Could not find a checkout strategy for routerPath ${routerPath}`
      );
    }

    return checkoutStrategy;
  }

  static fromStrategyType(type: string): CheckoutStrategy {
    const checkoutStrategy = strategiesByType[type];

    if (!checkoutStrategy) {
      throw new Error(`Could not find a checkout strategy for type ${type}`);
    }

    return checkoutStrategy;
  }
}

import _ from 'lodash';
import Types from '../actions/types';
import { PaymentProviders } from 'app/models/payment-providers';
import { CreditCardBrands } from 'app/models/credit-card-brands';
import constants from 'app/constants/constants';
import { getTrialData } from '../models/promotions';

const getNormalizedAddress = (providerName, providerData) => {
  if (providerName) {
    const provider = PaymentProviders.fromProviderType(providerName);
    return provider.getAddress(providerData);
  } else {
    return null;
  }
};

export const defaultState = {
  address: null,
  loading: {
    order: false,
    orderUpdate: false
  },
  isUsingExistingCard: null,
  order: null,
  paymentMethodData: {},
  selectedPaymentMethod: {
    provider: null,
    valid: false
  }
};

export default (state = defaultState, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case Types.CREATE_ORDER:
      return {
        ...state,
        ...action.payload,
        loading: {
          ...state.loading,
          order: true
        }
      };

    case Types.CREATE_ORDER_COMPLETE:
      return {
        ...state,
        order: action.payload,
        loading: {
          ...state.loading,
          order: false
        }
      };

    case Types.UPDATE_ORDER:
      return {
        ...state,
        loading: {
          ...state.loading,
          order: true
        }
      };

    case Types.UPDATE_ORDER_COMPLETE:
      return {
        ...state,
        order: action.payload,
        loading: {
          ...state.loading,
          order: false
        }
      };

    case Types.UPDATE_ORDER_WITH_USER:
      return {
        ...state,
        loading: {
          ...state.loading,
          orderUpdate: true
        }
      };

    case Types.UPDATE_ORDER_WITH_USER_COMPLETE:
      return {
        ...state,
        order: action.payload,
        loading: {
          ...state.loading,
          orderUpdate: false
        }
      };

    case Types.REPLACE_AVAILABLE_PAYMENT_METHOD: {
      const providerName = action.payload['provider'];
      return {
        ...state,
        order: {
          ...state.order,
          availablePaymentMethods: state.order.availablePaymentMethods.map(
            (pm) => (pm['provider'] === providerName ? action.payload : pm)
          )
        }
      };
    }

    case Types.SUBMIT_PURCHASE_COMPLETE:
      return {
        ...state,
        purchaseData: action.payload
      };

    case Types.UPDATE_PAYMENT_METHOD_DATA: {
      if (!action.payload) {
        return state;
      }
      const { provider, data } = action.payload;
      return {
        ...state,
        address: getNormalizedAddress(provider, data),
        paymentMethodData: {
          ...state.paymentMethodData,
          [action.payload.provider]: data
        },
        selectedPaymentMethod: {
          provider,
          valid: _.get(action, 'payload.data.valid', false)
        }
      };
    }

    case Types.UPDATE_SELECTED_PAYMENT_METHOD: {
      const provider = action.payload;
      const data = state.paymentMethodData[provider];
      const valid = _.get(data, 'valid', false);
      return {
        ...state,
        address: getNormalizedAddress(provider, data),
        selectedPaymentMethod: {
          provider,
          valid
        }
      };
    }

    case Types.USE_EXISTING_CARD: {
      const newState = {
        ...state,
        isUsingExistingCard: action.payload
      };
      // if switching to existing card, we know for sure the existing payment method is valid.
      if (action.payload === true) {
        newState.selectedPaymentMethod = {
          provider: state.selectedPaymentMethod.provider,
          valid: true
        };
      }
      return newState;
    }

    case Types.FETCH_RAZORPAY_ORDER_ID:
      return {
        ...state,
        loading: {
          ...state.loading,
          order: true
        }
      };

    case Types.CONVERT_ZIP_CODE_TO_STATE:
      return {
        ...state,
        address: {
          ...state.address,
          region: action.payload
        },
        paymentMethodData: {
          ...state.paymentMethodData,
          stripe: {
            ...state.paymentMethodData.stripe,
            region: action.payload
          }
        }
      };

    case Types.FETCH_RAZORPAY_ORDER_ID_COMPLETE: {
      const orderCreated = !action.payload.error;
      return {
        ...state,
        paymentMethodData: {
          ...state.paymentMethodData,
          razorpay: {
            ...state.paymentMethodData.razorpay,
            order_id: _.get(action.payload, 'charge.data.order_id', '')
          }
        },
        selectedPaymentMethod: {
          provider: 'razorpay',
          valid: orderCreated
        },
        purchaseData: action.payload,
        loading: {
          ...state.loading,
          order: false
        }
      };
    }

    default:
      return state;
  }
};

export const getPreferredPaymentMethod = (state) => {
  return _.get(state.checkout.order, 'input.payment_method');
};

/**
 * Return amount and display. e.g.
 * {currency: 'USD', amount: 19900, display: '$199 USD'}
 *
 * @param state the root state
 * @returns AmountAtom or undefined
 */
export const getGrandTotal = (state) => {
  const { order } = state.checkout;
  if (order) {
    const { currency } = order;
    const grandTotal = _.get(order, 'baseLineItems.grandTotal');
    if (currency && grandTotal) {
      return {
        currency: currency,
        display: grandTotal['display'],
        amount: grandTotal['amount']
      };
    }
  }
  return undefined;
};

/**
 * Get the credit card data.
 *
 * - if paying with new card then return the card data as entered.
 * - if paying with saved card then return the saved data.
 * @param state
 * @return SavedCreditCard, undefined if no card data is present
 */
export const getActiveCreditCard = (state) => {
  const saved = state.checkout.isUsingExistingCard;
  const dataPath = 'order.availablePaymentMethods[0].data.card';
  const data = _.get(state.checkout, dataPath);
  const hasActiveCard = data && data['name'];

  if (!hasActiveCard) {
    return undefined;
  }

  const buildFromExistingCard = () => ({
    last4: data['last4'],
    name: data['name'],
    brand: data['brand']
  });

  const buildFromNewCard = () => {
    if (!data['cardNumber']) {
      return undefined;
    }
    // For a new card, need to manually construct the SavedCreditCard object
    const number = data['cardNumber'].trim();
    const numberLen = number.length;
    const last4 = numberLen < 3 ? number : number.substring(numberLen - 4);
    const cardType = data['type'] ? data['type'] : 'unknown';
    const brand = CreditCardBrands.fromPaymentLibType(cardType);
    return {
      last4: last4,
      name: data['name'],
      brand: brand
    };
  };

  if (saved) {
    return buildFromExistingCard();
  } else {
    return buildFromNewCard();
  }
};

/**
 * Return the entered CC data
 * @param state
 * @return CreditCardFormData never null
 */
export const getNewCardData = (state) => {
  const data = _.get(state.checkout, 'paymentMethodData.stripe', {});
  return {
    cardNumber: data.cardNumber,
    country: data.country,
    cvc: data.cvc,
    expiry: data.expiry,
    name: data.name,
    postalCode: data.postalCode,
    type: data.type || 'default_card' // The card form needs this attribute to display placeholder card icon.
  };
};

/**
 * Return the saved CC data
 * @param state
 * @return SavedCreditCard if available
 */
export const getExistingCardData = (state) => {
  const cardData = _.get(
    state.checkout,
    'order.availablePaymentMethods[0].data.card'
  );
  if (cardData && cardData.last4 && cardData.name) {
    return {
      last4: cardData.last4,
      name: cardData.name,
      brand: cardData.brand
    };
  }
  return undefined;
};

/**
 * Return the existing Stripe Payment method's urn
 * @param state
 * @return string if available
 */
export const getExistingPaymentMethodUrn = (state) => {
  const existingData = _.get(
    state.checkout,
    'order.availablePaymentMethods[0].data',
    {}
  );
  if (existingData) {
    return existingData.urn;
  }
  return undefined;
};

/**
 * Return the selected payment provider.
 *
 * @param state the root state
 * @returns {PaymentProvider|undefined}
 */
export const getSelectedPaymentProvider = (state) => {
  const providerString = _.get(
    state.checkout,
    'selectedPaymentMethod.provider'
  );
  if (providerString) {
    return PaymentProviders.fromProviderType(providerString);
  }
  return undefined;
};

/**
 * Is the selected payment method valid?
 *
 * @param state
 * @return {boolean}
 */
export const isPaymentMethodValid = (state) => {
  const { isUsingExistingCard, selectedPaymentMethod } = state.checkout;
  if (!selectedPaymentMethod) {
    return false;
  }
  const existingCard = getExistingCardData(state);
  const { provider, valid } = selectedPaymentMethod;
  if (provider === constants.providers.STRIPE && isUsingExistingCard) {
    return !!existingCard;
  }
  return valid;
};

/**
 * Get the created Razorpay's Order ID
 *
 * @param state
 * @return {string} undefined if the id does not exist.
 */
export const getRazorpayOrderId = (state) => {
  return _.get(
    state.checkout,
    'paymentMethodData.razorpay.order_id',
    undefined
  );
};

/**
 * Is there a need to collect payment data before submitting the purchase?
 *
 *  @param state
 * @return {boolean|undefined} undefined means there is not enough information to make the decision
 */
export const shouldCollectPaymentData = (state) => {
  const { order, selectedPaymentMethod } = state.checkout;
  const grandTotal = _.get(order, 'baseLineItems.grandTotal');
  if (
    !selectedPaymentMethod ||
    !selectedPaymentMethod.provider ||
    !grandTotal
  ) {
    return undefined;
  }
  const provider = PaymentProviders.fromProviderType(
    selectedPaymentMethod.provider
  );
  // For payment methods that support recurring payments, always collect payment data, for future use.
  if (provider.supportsRecurringPayments()) {
    return true;
  }
  if (getTrialData(order)) {
    return false;
  }
  return grandTotal.amount > 0;
};

export const isOrderLoading = (state) => {
  return Boolean(
    state &&
      state.checkout &&
      state.checkout.loading &&
      state.checkout.loading.order
  );
};

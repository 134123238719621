import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@udacity/veritas-components';
import FormHeader from './form-header';
import styles from './form.module.scss';
import constants from '../../constants/constants';

export default class Form extends Component {
  static displayName = 'components/form';

  static propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    header: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.node,
    className: PropTypes.string
  };

  static defaultProps = {
    header: null,
    description: null,
    children: null
  };

  handleKeyPress = (e) => {
    if (e.which === constants.keyCodes.ENTER_KEY) {
      e.preventDefault();
      this.props.onSubmit();
    }
  };

  render() {
    let { buttonLabel, onSubmit, header, description, className } = this.props;

    return (
      <div className={className}>
        <FormHeader header={header} description={description} />

        <form onKeyPress={this.handleKeyPress}>
          <div className={styles.form}>{this.props.children}</div>

          <Button
            variant="primary"
            label={buttonLabel}
            className={styles['primary-button']}
            onClick={onSubmit}
          />
        </form>
      </div>
    );
  }
}

import _get from 'lodash/get';

export const errorTypes = {
  NOTIFY: 'notify',
  FULL_PAGE: 'full_page'
};

class UError extends Error {
  constructor(props) {
    super();
    this.message = props.message;
    this.endUserMessage = props.endUserMessage || this.message;
    this.type = props.type;
    this.key = props.key;

    if (props.cause) {
      this.cause = {
        message: props.cause.message,
        type: props.cause.type,
        stack: props.cause.stack
      };
    }
  }
}

export default {
  httpError(message, status, jsonResponse) {
    const error = new Error(message);
    const endUserMessage = _get(jsonResponse, 'rich_errors[0].detail');
    // We want to be intentional about the presence of an message meant to be seen by end user,
    // so we are using a separate attribute endUserMessage to store user facing message.
    if (endUserMessage) {
      error.endUserMessage = endUserMessage;
    }
    error.status = status;
    error.jsonResponse = jsonResponse;
    return error;
  },

  isApplicationError(error) {
    return error instanceof UError;
  },

  invalidSkuError() {
    return new UError({
      key: 'invalid_sku',
      message: 'We were unable to find that product.',
      type: errorTypes.FULL_PAGE
    });
  },

  orderFailedError({ cause, message }) {
    const defaultMsg =
      'We are having difficulties processing your request. If this error keeps appearing please contact payment-support@udacity.com.';
    return new UError({
      key: 'order_failed',
      message: message || defaultMsg,
      type: errorTypes.FULL_PAGE,
      cause
    });
  },

  razorpayFailedOrderError(error) {
    return new UError({
      key: 'order_failed',
      message: error.message,
      endUserMessage: error.endUserMessage,
      type: errorTypes.NOTIFY,
      cause: error.cause
    });
  },

  invoiceFailedError({ cause, message }) {
    const defaultMsg =
      'We are having difficulties processing your request. If this error keeps appearing please contact payment-support@udacity.com.';
    return new UError({
      key: 'invoice_failed',
      message: message || defaultMsg,
      type: errorTypes.FULL_PAGE,
      cause
    });
  },

  purchaseFailedError({ cause, message }) {
    const defaultMsg =
      'We were unable to complete your purchase. Please try again, or try a new payment method if the problem persists.';
    return new UError({
      key: 'purchase_failed',
      message: message || defaultMsg,
      type: errorTypes.NOTIFY,
      cause
    });
  },

  couponNoLongerValid() {
    const message =
      'This coupon is no longer valid, and has been removed from your order. Please try again to purchase at the updated price.';
    return new UError({
      key: 'coupon_no_longer_valid',
      message,
      type: errorTypes.NOTIFY
    });
  },

  subscriptionIsClosed({ productName } = {}) {
    const message = `Your subscription in ${productName ||
      'this program'} has ended`;
    return new UError({
      key: 'subscription_is_closed',
      message,
      type: errorTypes.FULL_PAGE
    });
  }
};

import React from 'react';
import { CheckoutStrategy } from 'app/models/checkout-strategies/checkout-strategy';
import { LineItem, LineItemGroup, Order } from 'app/models/orders';
import styles from './generic-lines.module.scss';
import classNames from 'classnames';

interface Props {
  checkoutStrategy: CheckoutStrategy,
  order: Order
}

/**
 * A Line component's properties
 */
interface LineProps {
  name: string, // the text on the left
  value?: string // the number on the right
  negative?: boolean, // is the number negative?
  emphasis?: boolean // should this line be emphasised?
  testId?: string // for locating the line item during testing
}

/**
 * Render a line item.
 *
 * @param props properties to render the Line component
 */
const Line: React.FunctionComponent<LineProps> = (props) => {
  return (
    <div
      className={classNames(styles.orderLine, {
        [styles.emphasis]: props.emphasis
      })}
      data-testid={props.testId}
    >
      <div>{props.name}</div>
      <div className={props.negative ? styles.negative : ''}>{props.value}</div>
    </div>
  );
};

/**
 * Return an array that contains Line components.
 */
const Lines: React.FunctionComponent<{
  lines: LineItem[];
}> = (props) => {
  return (
    <>
      {
        props.lines.map((line: LineItem) => {
          // bold all lines below the divider except the tax display
          const emphasis = line.group === LineItemGroup.BELOW_LINE
            && line.key !== 'line_item.india_gst'
          return <Line
            key={line.key}
            name={line.description || ''}
            value={line.value_display}
            negative={line.value ? line.value < 0 : false}
            emphasis={emphasis}
            testId={line.key}
          />
        })
      }
    </>
  );
};

// Component to display a list of line items.
export const GenericLines: React.FunctionComponent<Props> = (props: Props) => {
  const { checkoutStrategy, order } = props;
  const topLines = [];
  const summaryLines = [];

  for (const line of order.lineItems) {
    if (line.group === LineItemGroup.ABOVE_LINE) {
      topLines.push(line);
    } else {
      summaryLines.push(line);
    }
  }

  return (
    <div className={styles.linesContainer}>
      <h3 className={styles.header}
          data-testid="heroTextSmall">{checkoutStrategy.heroTextSmall(order)}</h3>
      <Lines lines={topLines} />
      <hr />
      <Lines lines={summaryLines} />
    </div>
  );
};

import ReactDOM from 'react-dom';
import { fetch as fetchPolyfill } from 'whatwg-fetch';

import config from 'config';
import webPerf from '@udacity/web-perf-metrics';
import { initSentry } from 'app/helpers/sentry-helper';
import routes from 'app/routes';

const SITE_IDENTIFIER = 'Payment Web';

initSentry();

if (config.REACT_APP_ENV === 'e2e_test') {
  window.fetch = fetchPolyfill;
}

ReactDOM.render(routes, document.getElementById('root'));

webPerf.track(SITE_IDENTIFIER);

import auth from '@udacity/ureact-hoth';

const authEnv = () => {
  if (window.location.href.indexOf('-staging.udacity.com') > -1) {
    return 'staging';
  }
  return 'production';
};

export const redirectToSignUp = () => {
  auth.signUp({
    env: authEnv(),
    returnUrl: window.location.href
  });
};

export const getCurrentUserId = () => {
  return auth.getCurrentUserId() || null;
};

import React, { ReactNode } from 'react';
import { Button } from '@udacity/veritas-components';
import { connect } from 'react-redux';
import { CheckOutStepProps } from './checkout-step';
import ErrorMessage from '../components/common/error-message';
import { GenericLines } from '../components/generic-lines';
import _ from 'lodash';
import analytics, { events } from '../helpers/analytics-helper';
import optimizely from '../services/experiments-service';
import constants from '../constants/constants';
import { reportError } from '../helpers/sentry-helper';
import errorHelper from '../helpers/error-helper';
import { getAddress, turnOffPromo } from '../helpers/checkout-helper';
import actions from 'app/actions';
import { Severity } from '@sentry/types';
import styles from './review-order.module.scss';
import { TermsConditions } from 'app/components/terms-conditions';
import {
  getActiveCreditCard,
  getExistingPaymentMethodUrn,
  getGrandTotal,
  getRazorpayOrderId,
  getSelectedPaymentProvider,
  isPaymentMethodValid,
  shouldCollectPaymentData,
} from 'app/reducers/checkout';
import { getMonthsUntilDue } from 'app/reducers/ui';
import { AmountAtom, Order } from 'app/models/orders';
import { SelectedPaymentMethod } from 'app/models/payment-method-types';
import { PaymentProvider } from 'app/models/payment-providers/payment-provider';
import { HeroText } from '../components/common/hero-text';
import { PaymentMethodSummary } from '../components/payment-methods/payment-method-summary';
import { SavedCreditCard } from '../models/saved-credit-card';
import { CRUMB_MAP, PageId } from 'app/components/common/breadcrumb-bar';
import { SubmitPaymentData } from 'app/models/submit-payment-data';
import {
  RazorpayOrderCreationRequest,
  RazorpayPaymentProvider
} from '../models/payment-providers/razorpay-payment-provider';
import {
  getEmail,
  getFullName,
  getPhoneNumber
} from 'app/reducers/user';
import {
  isFreeTrialEligibilityError,
  TurnOffTrialButton
} from 'app/models/promotions';
import { PurchaseDataKind } from '../models/payment-providers/purchase-data-kind';

type Props = DerivedProps & typeof mapDispatchToProps & CheckOutStepProps;

interface DerivedProps {
  ackAutoRenew: boolean;
  address: any;
  email: string;
  error: object;
  existingPaymentMethodUrn?: string;
  grandTotal: AmountAtom | undefined;
  isPaymentMethodValid: boolean;
  loading: boolean;
  order: Order;
  paymentMethodData: object;
  phoneNumber: string;
  purchaseData: object;
  razorpayOrderId: string | undefined; // defined only for Razorpay checkouts.
  selectedCreditCard: SavedCreditCard | undefined;
  shouldCollectPaymentData: boolean | undefined;
  monthsUntilDue: number;
  selectedPaymentProvider: PaymentProvider;
  selectedPaymentMethodData: object; // Data of the selected payment provider
  selectedPaymentMethod: SelectedPaymentMethod;
  userFullName: string;
}

interface State {
  processingPurchase: boolean;
  showTrialError: boolean; // show button to turn off trial
}

const mapStateToProps = (state: any): DerivedProps => {
  const selectedPaymentProvider = getSelectedPaymentProvider(state);
  let selectedPaymentMethodData = undefined;
  if (selectedPaymentProvider) {
    selectedPaymentMethodData =
      state.checkout.paymentMethodData[selectedPaymentProvider.providerName];
  }
  return {
    ackAutoRenew: _.get(state.checkout, 'order.input.ackAutoRenew'),
    address: getAddress(state.checkout),
    email: getEmail(state),
    error: state.ui.errors.checkout,
    existingPaymentMethodUrn: getExistingPaymentMethodUrn(state),
    grandTotal: getGrandTotal(state),
    isPaymentMethodValid: isPaymentMethodValid(state),
    loading: _.get(state.checkout, 'loading.order'),
    monthsUntilDue: getMonthsUntilDue(state),
    order: state.checkout.order,
    paymentMethodData: state.checkout.paymentMethodData,
    phoneNumber: getPhoneNumber(state),
    purchaseData: _.get(state, 'checkout.purchaseData', {}),
    razorpayOrderId: getRazorpayOrderId(state),
    selectedCreditCard: getActiveCreditCard(state),
    shouldCollectPaymentData: shouldCollectPaymentData(state),
    selectedPaymentProvider,
    selectedPaymentMethodData: selectedPaymentMethodData || {},
    selectedPaymentMethod: state.checkout.selectedPaymentMethod,
    userFullName: getFullName(state),
  };
};

const mapDispatchToProps = {
  createRazorpayOrder: actions.getRazorpayOrderId,
  displayError: actions.displayError,
  submitPurchase: actions.submitPurchase,
  submitRazorpayPurchase: actions.submitRazorpayPurchase,
  updateOrder: actions.updateOrder
};

class ReviewOrder extends React.Component<Props, State> {
  public readonly state: Readonly<State> = {
    processingPurchase: false,
    showTrialError: false
  };

  componentDidMount(): void {
    const {
      displayError,
      error,
      history,
      selectedPaymentProvider,
      toPage,
      updateTitle
    } = this.props;
    updateTitle('Review Order');
    // clear errors on page load
    if (error) {
      displayError({ checkout: null });
    }
    // redirect user back to cart if they hit this page directly
    if (!selectedPaymentProvider) {
      toPage(PageId.cart, history, true);
    }
  }

  componentDidUpdate(
    _prevProps: Readonly<Props>,
    _prevState: Readonly<State>,
    _snapshot?: any
  ): void {
    const { error } = this.props;
    const { showTrialError } = this.state;
    if (isFreeTrialEligibilityError(error) && !showTrialError) {
      this.setState({ showTrialError: true });
    }
  }

  buildPurchaseButton(): JSX.Element {
    const { processingPurchase } = this.state;
    const { checkoutStrategy } = this.props;

    return (
      <Button
        testID={'completeOrderBtn'}
        variant="primary"
        disabled={this._isButtonDisabled()}
        loading={processingPurchase}
        onClick={this.confirmPurchase}
        label={checkoutStrategy.submitOrderButtonLabel}
      />
    );
  }

  confirmPurchase = (): void => {
    const {
      selectedPaymentMethod: { provider },
      shouldCollectPaymentData
    } = this.props;
    this.setState({ processingPurchase: true });
    this.sendPurchaseEvents();
    if (provider === constants.providers.RAZORPAY) {
      shouldCollectPaymentData
        ? this.handlePaymentSubmitRazor()
        : this.handleRazorFreePurchase();
    } else {
      this.handlePaymentSubmit();
    }
  };

  sendPurchaseEvents = (): void => {
    try {
      analytics.trackEvent(events.CONFIRM_PURCHASE);
      optimizely.trackEventForAnonymousId(
        constants.optimizelyProjects.PROJECT_ID_GROWTH,
        events.CONFIRM_PURCHASE
      );
    } catch (e) {
      // Don't propagate analytics errors.
      reportError(e);
    }
  };

  handlePaymentSubmit = async (): Promise<void> => {
    const {
      existingPaymentMethodUrn,
      selectedPaymentProvider,
      selectedPaymentMethodData
    } = this.props;

    let paymentMethodData = {};
    const dataToSubmit = selectedPaymentProvider.getPurchaseDataToSubmit();

    if (dataToSubmit.includes(PurchaseDataKind.COLLECTED_DATA)) {
      paymentMethodData = selectedPaymentMethodData;
    }

    if (dataToSubmit.includes(PurchaseDataKind.PAYMENT_METHOD_URN)) {
      paymentMethodData['urn'] = existingPaymentMethodUrn;
    }

    const paymentData = { paymentMethodData };
    const performPurchaseResponse = await this.performPurchase(paymentData);

    if (performPurchaseResponse) {
      this.performPostPurchaseRedirect(performPurchaseResponse);
    }
  };

  // Don't need to collect payment data for free purchases.
  // Simply create the self-activating purchase then redirect to order confirm page.
  handleRazorFreePurchase = async (): Promise<void> => {
    const result = await this.props.createRazorpayOrder();
    const error = result['error'];
    if (error instanceof Error) {
      this.setState({
        processingPurchase: false,
        showTrialError: isFreeTrialEligibilityError(error)
      });
      this.props.displayError({
        checkout: errorHelper.purchaseFailedError({
          cause: error,
          message: error['endUserMessage']
        })
      });
      return;
    }
    this.performPostPurchaseRedirect(result);
  };

  handlePaymentSubmitRazor = (): void => {
    // Modal closed callback.
    const onModalClosed = (): void => {
      this.setState({ processingPurchase: false });
    };

    // Upon payment success, call Braavos to capture the charge.
    const processRazorpayResponse = async (
      razorpayResponse: object
    ): Promise<void> => {
      let success = false;
      try {
        await this.props.submitRazorpayPurchase(razorpayResponse);
        success = true;
      } catch (e) {
        this.props.displayError({
          checkout: errorHelper.purchaseFailedError({
            cause: e,
            message: e.endUserMessage
          })
        });
      } finally {
        onModalClosed();
      }
      if (success) {
        this.performPostPurchaseRedirect(this.props.purchaseData);
      }
    };

    const {
      address,
      email,
      grandTotal,
      order,
      razorpayOrderId,
      userFullName
    } = this.props;
    if (!razorpayOrderId || !grandTotal) {
      return;
    }
    const provider = this.props
      .selectedPaymentProvider as RazorpayPaymentProvider;
    const req: RazorpayOrderCreationRequest = {
      orderId: razorpayOrderId,
      amount: grandTotal.amount,
      description: order.name,
      name: userFullName,
      email: email,
      address: address,
      handler: processRazorpayResponse,
      ondismiss: onModalClosed
    };
    const requestObj = provider.getRequestObject(req);

    // eslint-disable-next-line
    const rzp = new Razorpay(requestObj);
    rzp.open();
  };

  _isButtonDisabled(): boolean {
    const autoRenewCheckFail = (): boolean => {
      const { address } = this.props;
      // Ensure auto renew T&C is acknowledged.
      if (address) {
        const country = address.country;
        const countryRequiresAutoRenewAck = _.includes(
          constants.ackAutoRenewCountries,
          country
        );
        return countryRequiresAutoRenewAck && !this.props.ackAutoRenew;
      }
      return false;
    };

    const { isPaymentMethodValid, shouldCollectPaymentData } = this.props;
    const paymentOkay = isPaymentMethodValid || !shouldCollectPaymentData;
    return (
      this.state.processingPurchase ||
      !paymentOkay ||
      this.props.loading ||
      autoRenewCheckFail()
    );
  }

  performPurchase = async (
    submitPaymentData: SubmitPaymentData
  ): Promise<any> => {
    const provider = this.props.selectedPaymentProvider;
    try {
      await this.doubleCheckCoupon();

      return await this.props.submitPurchase({
        data: submitPaymentData.paymentMethodData,
        provider: provider.providerName
      });
    } catch (e) {
      e.name = 'SubmitPurchaseError';
      e.extraData = Object.assign({}, e.extraData, {
        end_user_message: e.endUserMessage
      });
      reportError(e, {
        fingerprint: ['{{ type }}'],
        severity: Severity.Debug
      });
      this.setState({ processingPurchase: false });
      this.props.displayError({
        checkout: errorHelper.purchaseFailedError({
          cause: e,
          message: e.endUserMessage
        })
      });
    }
  };

  doubleCheckCoupon = async (): Promise<void> => {
    const couponCode = _.get(this.props, 'order.coupon.code');

    if (couponCode) {
      await this.props.updateOrder({ coupon: couponCode });
      const doubleCheckedCouponCode = _.get(this.props, 'order.coupon.code');
      if (couponCode !== doubleCheckedCouponCode) {
        throw errorHelper.couponNoLongerValid();
      }
    }
  };

  performPostPurchaseRedirect = (purchaseResponse: any): void => {
    const { toExternalPage } = this.props;
    if (purchaseResponse.redirectUrl) {
      window.location.href = purchaseResponse.redirectUrl;
    } else {
      this.setState({ processingPurchase: false });
      analytics.trackEvent(events.CHECKOUT_COMPLETED_ORDER, {
        order_id: purchaseResponse.invoice.urn
      });
      toExternalPage({
        pathname: `/confirmation/${purchaseResponse.invoice.urn}`
      });
    }
  };

  updateAutoRenew = (updateAutoRenew: boolean): void => {
    this.props.updateOrder({ ackAutoRenew: updateAutoRenew });
  };

  editPaymentMethod = (): void => {
    analytics.trackAction(
      'edit_payment_method_button_clicked',
      CRUMB_MAP[PageId.review].analyticsName
    );
    this.props.toPage(PageId.payment, this.props.history, true);
  };

  render(): ReactNode {
    const {
      ackAutoRenew,
      address,
      checkoutStrategy,
      error,
      monthsUntilDue,
      order,
      grandTotal,
      selectedCreditCard,
      selectedPaymentProvider
    } = this.props;
    const { showTrialError } = this.state;

    const paymentPlan = order.paymentPlan;

    if (!selectedPaymentProvider || !grandTotal) {
      return <></>;
    }

    const displayPaymentMethodSummary = !(
      selectedPaymentProvider instanceof RazorpayPaymentProvider
    );

    return (
      <div className={styles.reviewOrderContainer}>
        <div className={styles.reviewDetails}>
          <HeroText
            heroText={checkoutStrategy.reviewPageHeroTextLarge}
            subHeroText={checkoutStrategy.heroTextMedium(order)}
          />
          <GenericLines
            checkoutStrategy={this.props.checkoutStrategy}
            order={order}
          />
          {displayPaymentMethodSummary && (
            <PaymentMethodSummary
              selectedCreditCard={selectedCreditCard}
              editPaymentMethod={this.editPaymentMethod}
              selectedPaymentProvider={selectedPaymentProvider}
            />
          )}
        </div>

        <div className={styles.buttonRow}>{this.buildPurchaseButton()}</div>

        <TermsConditions
          ackAutoRenew={ackAutoRenew}
          submitOrderButtonLabel={checkoutStrategy.submitOrderButtonLabel}
          monthsUntilDue={monthsUntilDue}
          address={address}
          grandTotal={grandTotal}
          paymentPlan={paymentPlan}
          order={order}
          updateAutoRenew={this.updateAutoRenew}
          selectedPaymentProvider={selectedPaymentProvider}
        />
        <div className={styles.errorMessage}>
          {error && (
            // @ts-ignore
            <ErrorMessage error={error} />
          )}
        </div>
        {showTrialError && (
          <div className={styles.buttonRow}>
            <TurnOffTrialButton
              onClick={(): void =>
                turnOffPromo(this.props.parentComponentLocation)
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewOrder);

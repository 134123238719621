import React from 'react';
import styles from './payment-method-selector.module.scss';
import PayPalIcon from 'assets/images/paypal.png';

interface Props {
  doSelect: (selected: string) => void
  provider: string,
  selected: boolean
}

export const PaymentMethodSelector: React.FunctionComponent<Props> = (props: Props) => {
  const getButtonBody = (): JSX.Element => {
    const { provider } = props;
    switch (provider) {
      case 'stripe':
        return <span>Card</span>;
      case 'paypal':
        return (
          <span className={styles.paypal_icon_wrapper}>
            <img src={PayPalIcon} alt="PayPal" className={styles.providerIcon} />
          </span>
        );
      case 'affirm_credit':
        return <span>0% Financing</span>;
      default:
        return <></>;
    }
  };

  const { provider, selected } = props;
  const ButtonBody = getButtonBody();
  const buttonClass = selected ? `${styles.selectorButton} ${styles.selected}` : styles.selectorButton;

  return (
    <div className={styles.buttonWrapper}>
      <button data-testid={provider + 'Btn'}
              type='button'
              className={buttonClass}
              onClick={(): void => props.doSelect(provider)}>{ButtonBody}
      </button>
    </div>
  );
};

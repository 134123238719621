import '@udacity/veritas-components/dist/styles/index.scss';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Loading } from '@udacity/veritas-components';

import errorHelper from './helpers/error-helper';
import { actionsBinder } from './helpers/action-helper';
import analytics, {
  events,
  CHECKOUT_PAGE_CATEGORY
} from 'app/helpers/analytics-helper';
import { reportError } from 'app/helpers/sentry-helper';
import Header from './components/common/header';
import styles from './app.module.scss';
import ErrorMessage from 'app/components/common/error-message';
import constants from 'app/constants/constants';
import { CheckoutStrategies } from 'app/models/checkout-strategies';
import { getCountryCode } from 'app/reducers/ui';
import MultiStepCheckout from 'app/views/multi-step-checkout';
import SinglePageCheckout from 'app/views/single-page-checkout';

const mapStateToProps = (state) => ({
  loadingGeo: state.ui.loading,
  loadingUser: state.user.loading.user,
  loadingExperiments: state.user.loading.experiments,
  error: state.ui.errors.fullPage,
  countryCode: getCountryCode(state),
  checkoutStrategyType: _.get(
    state,
    'checkout.order.input.checkoutStrategyType'
  ),
  currency: _.get(state, 'checkout.order.currency'),
  user: state.user.user,
  order: state.checkout.order
});

const mapDispatchToProps = actionsBinder(
  'createOrder',
  'displayError',
  'fetchAnonymousId',
  'fetchCurrentUser',
  'fetchExperiments',
  'fetchFeature',
  'fetchUserGeoLocation'
);

export class App extends Component {
  state = {
    loading: true
  };

  async componentDidMount() {
    await this.props.fetchCurrentUser();
    await this.props.fetchUserGeoLocation();
    try {
      await this.setupInitialOrder();
    } catch (error) {
      const fullPageError = errorHelper.isApplicationError(error)
        ? error
        : errorHelper.orderFailedError({ cause: error });
      this.props.displayError({ fullPage: fullPageError });
    }
    await this.props.fetchAnonymousId();
    await this.queryAndSendExperiments();
  }

  queryAndSendExperiments = async () => {
    const { checkoutStrategyType } = this.props;

    try {
      const isRenew = checkoutStrategyType !== 'recurring_first';
      // Don't let renew visits participate in experiment.
      const experiments = isRenew ? [] : [];
      await this.props.fetchExperiments(
        constants.optimizelyProjects.PROJECT_ID_GROWTH,
        experiments,
        this.props.countryCode,
        this.props.user.userId,
        this.props.currency
      );
    } catch (e) {
      e.name = 'ExperimentsError';
      reportError(e, { severity: 'warning' });
    } finally {
      // Must wait until experiments have been fetched before sending initial page view event,
      // because the event includes experiment data.
      // But we still want to send the event if fetchExperiments fails.
      analytics.trackPageView(
        CHECKOUT_PAGE_CATEGORY,
        events.CHECKOUT_PAGE_VIEW
      );
    }
  };

  async setupInitialOrder() {
    const {
      createOrder,
      location: { pathname, search },
      history,
      match: {
        params: { skuId, subscriptionUrn }
      },
      location
    } = this.props;

    // Convert a search param into a plain object.
    const convertToQuery = (search) => {
      const searchParam = new URLSearchParams(search);
      const queryObj = {};
      for (const pair of searchParam.entries()) {
        queryObj[pair[0]] = pair[1];
      }
      return queryObj;
    };

    const query = convertToQuery(search);

    const routerPath = _.get(location, 'pathname', '').replace(/\/$/, '');

    if (_.get(query, 'affirmCanceled') === 'true') {
      analytics.trackEvent(events.AFFIRM_CHECKOUT_CANCELED, {
        start_time: _.get(query, 'affirmStartTime')
      });

      const params = _.omit(query, ['affirmCanceled', 'affirmStartTime']);
      history.replace(`${pathname}?${queryString.stringify(params)}`);
    }

    const checkoutStrategy = CheckoutStrategies.fromRouterPath(routerPath);

    const paymentPlanQueryParam = _.get(query, 'payment_plan', null);
    const paymentPlan = ['recurring', 'upfront_recurring'].includes(
      paymentPlanQueryParam
    )
      ? paymentPlanQueryParam
      : 'recurring';

    const order = await createOrder({
      ...query,
      payment_plan: paymentPlan,
      sku: skuId,
      rechargeSubscriptionUrn: subscriptionUrn,
      checkoutStrategyType: checkoutStrategy.type,
      selectedAutoRenewState: false
    });

    if (_.get(order, 'isRedirectingToClassroom')) {
      return;
    }

    this.setState({ loading: false });
  }

  showSinglePageCheckout() {
    const isRenew = this.props.checkoutStrategyType !== 'recurring_first';
    if (isRenew) {
      return false;
    }
    const { paymentOptions } = this.props.order;
    return paymentOptions.includes(constants.providers.STRIPE);
  }

  render() {
    const { error, loadingGeo, loadingUser, loadingExperiments } = this.props;
    const loading =
      this.state.loading || loadingGeo || loadingUser || loadingExperiments;

    if (loading && !error) {
      return (
        <div className={styles.loading}>
          <div className={styles.loading_child}>
            <Loading />
          </div>
        </div>
      );
    }

    return (
      <div className={styles.app}>
        <Header />

        <main>
          {error ? (
            <ErrorMessage error={error} />
          ) : (
            <div className={styles.body}>
              {this.showSinglePageCheckout() ? (
                <SinglePageCheckout
                  order={this.props.order}
                  queryAndSendExperiments={this.queryAndSendExperiments}
                />
              ) : (
                <MultiStepCheckout />
              )}
            </div>
          )}
        </main>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import queryString from 'query-string';
import errorHelper from 'app/helpers/error-helper';
import HttpHelper from 'app/helpers/http-helper';
import { PaymentProvider } from 'app/models/payment-providers/payment-provider';
import config from 'config';

export const braavos = new HttpHelper(config.REACT_APP_BRAAVOS_API_URL);

type PriceRequest = {
  coupon?: string;
  currency?: string;
  payment_plan_type?: string;
  sku?: string;
  timezone_offset?: number;
  user_id?: string;
  campaign_participation?: string;
}

// TODO: Add more detailed types throughout this API

export default {
  createPaymentMethod: (
    userId: string,
    provider: PaymentProvider | undefined,
    cardToken: string | undefined,
    cardTokenData: {},
    providerData: {}
  ): Promise<any> => {
    if (!provider) {
      return Promise.reject(new Error('Could not get a payment method for this purchase'));
    }

    if (provider.requiresCardToken() && !cardToken) {
      return Promise.reject(new Error('No card token'));
    }

    const body: {
      provider: string;
      data: object;
      card_token?: string;
    } = {
      provider: provider.paymentMethodType,
      data: {}
    };

    if (cardToken) {
      body.card_token = cardToken;
    }

    if (provider.savesTokenData() && cardTokenData) {
      body.data = cardTokenData;
    }

    Object.assign(body.data, provider.getCreationData(providerData));
    return braavos.post(`/users/${userId}/payment_methods`, body);
  },

  createSubscriptionPurchase: (userId: string, inputData: object): Promise<any> => {
    return braavos.post(`/users/${userId}/subscriptions`, inputData)
      .then((response: { subscription: object; }): object => response.subscription);
  },

  getInvoice: (invoiceUrn: string): Promise<any> => {
    return braavos.get(`/invoices/${invoiceUrn}`)
      .then((response: { invoice: any }): object => response.invoice);
  },

  getOrderHistory: (userId: string | undefined, subscriptionUrn: string): Promise<any> => {
    if (!userId) {
      return Promise.resolve(null);
    }

    return braavos.get(`/users/${userId}/order_history/${subscriptionUrn}`)
      .then((response: { order_history: any }): object => response.order_history);
  },

  getPaymentMethods: (userId: string): Promise<{}[]> => {
    if (!userId) {
      return Promise.resolve([]);
    }

    return braavos.get(`/users/${userId}/payment_methods`)
      .then((reponse: { payment_methods: any[] }): object[] => reponse.payment_methods);
  },

  getPrice: (request: PriceRequest): Promise<object> => {
    return braavos.get(`/prices?${queryString.stringify(request)}`)
      .then((response: { results?: any[] }): object => {
        if (!response.results || !response.results[0]) {
          throw errorHelper.invalidSkuError();
        }
        return response.results[0];
      });
  },

  getSubscription: (subscriptionUrn: string): Promise<any> => {
    return braavos.get(`/subscriptions/${subscriptionUrn}`)
      .then((response: { subscription: any }): object => response.subscription);
  },

  updateCharge: (chargeUrn: string, chargeData: object): Promise<any> => {
    return braavos.patch(`/charges/${chargeUrn}`, chargeData)
      .then((response: { charge: object }): object => response.charge);
  },

  updateInvoice: (invoiceUrn: string, invoiceData: object): Promise<object> => {
    return braavos.patch(`/invoices/${invoiceUrn}`, invoiceData)
      .then((response: { invoice: object }): object => response.invoice);
  },

  updateSubscription: (subscriptionUrn: string, paymentMethodUrn: string): Promise<any> => {
    return braavos.patch(`/subscriptions/${subscriptionUrn}`, {
      payment_method_urn: paymentMethodUrn
    });
  },

  validateSubscriptionPurchase: (userId: string, inputData: object): Promise<any> => {
    return braavos.post(`/users/${userId}/subscriptions/validate`, inputData);
  }
}

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import config from '../config';
import reducers from './reducers';

let createStoreWithMiddleware = compose(
  applyMiddleware(thunk),
  config.ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
)(createStore);

export default createStoreWithMiddleware(combineReducers(reducers));

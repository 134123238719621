import braavosService from "app/services/braavos-service";
import { CheckoutStrategy } from "./checkout-strategy";
import { Order, PaymentPlan } from 'app/models/orders';

export class InitialSubscriptionCheckoutStrategy extends CheckoutStrategy {
  get type(): string {
    return 'recurring_first';
  }

  get purchaseButtonText(): string {
    return 'Confirm Subscription';
  }

  get displayPlanSelection(): boolean {
    return true;
  }

  get showAutoRenewTerms(): boolean {
    return true;
  }

  heroTextLarge(): string {
    return 'Order Details';
  }

  heroTextMedium(order: Order): string {
    return order.name;
  }

  heroTextSmall(order: Order): string {
    const paymentPlan = new PaymentPlan(order.paymentPlan);
    return paymentPlan.getLengthDesc();
  }

  continueButtonLabel: string = 'Review Your Order';

  submitOrderButtonLabel: string = 'Complete Order';

  reviewPageHeroTextLarge: string = 'Complete Your Order';

  getSkuAmountPathForPaymentPlanType(paymentPlanType: string): string {
    // Now that braavos is returning `upfront_amount` as the first payment for monthly purchases, we can always use `upfront_amount` as the first payment's amount regardless of payment plan type.
    return 'payment_plans.' + paymentPlanType + '.upfront_amount';
  }

  supportsRouterPath(routerPath: string): boolean {
    return routerPath.startsWith('/subscribe/');
  }

  supportsPaymentProvider(): boolean {
    return true;
  }

  createPurchase(userId: string, inputData: object): Promise<object> {
    return braavosService.createSubscriptionPurchase(userId, inputData);
  }

  validatePurchase(userId: string, inputData: object): Promise<object> {
    return braavosService.validateSubscriptionPurchase(userId, inputData);
  }
}

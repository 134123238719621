import HttpHelper from 'app/helpers/http-helper';
import config from 'config';
import Cookies from 'js-cookie';
import snakeCase from 'lodash/snakeCase';

const ExperimentsApi = new HttpHelper(config.REACT_APP_EXPERIMENTS_API_URL);

const SEGMENT_READY_TIMEOUT = 2000;

const EXCLUDE_FROM_EXPERIENCE_VARIATION = {
  variationKey: null
};

// If segment does not load or return an anonymousId quickly enough,
// then return undefined.
const safeGetAnonymousId: Promise<string | undefined> = new Promise((resolve): void => {
  const cookieValue = Cookies.get('ajs_anonymous_id');
  if (cookieValue) {
    return resolve(cookieValue.replace(/"/g, '')); // Cookie's value is quoted
  }
  try {
    if (window.analytics && window.analytics.ready) {
      window.analytics.ready((): any => {
        resolve(window.analytics.user().anonymousId());
      });
    }

    setTimeout((): any => {
      resolve(undefined);
    }, SEGMENT_READY_TIMEOUT);
  } catch (e) {
    // ingore errors getting anonymousId
    resolve(undefined);
  }
});

const getAnonymousId = async (): Promise<string | undefined> => {
  const anonymousId = await safeGetAnonymousId;

  return Promise.resolve(anonymousId);
};

/**
 * Parse an array of overrides and return an object containing experiment key to variation key.
 * If an experiment has null variation then the pipe character should be the last char in the string.
 *
 *  The input has the following format: ["exp1|variation1","exp2|variation2","exp3|"]
 *
 * @param overrides
 * @return object containing experiment key to a nullable variation key
 */
const getExperimentOverrides = (overrides: string[]): object => {
  const retval = {};
  overrides.forEach(override => {
    const split = override.split('|');
    if (split.length === 2 && split[0].length > 0) {
      const variation = split[1] === '' ? null : split[1];
      retval[split[0]] = variation;
    }
  });
  return retval;
};

// Try to use segment anonymousId as the userId for expierments-api
const getExperimentVariationForAnonymousId = async (
  projectId: string,
  experimentKey: string,
  attributes: object = {}
): Promise<object> => {
  const userId = await getAnonymousId();

  if (!userId) {
    return {};
  }

  return await getExperimentVariationForUserId(
    projectId,
    userId,
    experimentKey,
    attributes
  );
};

const getExperimentVariationForUserId = async (
  projectId: string,
  userId: string,
  experimentKey: string,
  attributes: object
): Promise<object> => {
  const url = `/v1/users/${encodeURIComponent(
    userId
  )}/experiments/${encodeURIComponent(experimentKey)}/activation`;

  return await ExperimentsApi.post(
    url,
    {
      attributes: {
        'anonymous_id': userId,
        ...attributes
      }
    },
    {
      'X-Optimizely-Project-ID': projectId
    }
  ).catch((): object => EXCLUDE_FROM_EXPERIENCE_VARIATION);
};

const trackEventForAnonymousId = async (projectId: string, eventKey: string): Promise<object> => {
  const userId = await getAnonymousId();

  if (!userId) {
    return Promise.resolve({});
  }

  return await trackEventForUserId(projectId, userId, eventKey);
};

const trackEventForUserId = async (projectId: string, userId: string, eventKey: string): Promise<object> => {
  eventKey = snakeCase(eventKey);
  const url = `/v1/users/${encodeURIComponent(
    userId
  )}/events/${encodeURIComponent(eventKey)}/tracking`;

  return await ExperimentsApi.post(
    url,
    {},
    { 'X-Optimizely-Project-ID': projectId }
  );
};

const isFeatureEnabled = async (projectId: string, userId: string, featureKey: string): Promise<boolean> => {
  const url = `/v1/users/${userId}/enabled-features/${featureKey}`;
  const response = await ExperimentsApi.get(url, { 'X-Optimizely-Project-ID': projectId }) as object;
  return response && response['enabled'] === true;
};

export default {
  getAnonymousId,
  getExperimentOverrides,
  getExperimentVariationForAnonymousId,
  getExperimentVariationForUserId,
  isFeatureEnabled,
  trackEventForAnonymousId,
  trackEventForUserId
};

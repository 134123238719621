import { getCurrentUserId } from 'app/helpers/auth-helper';
import * as Sentry from '@sentry/browser';
import { Scope } from '@sentry/browser';
import config from 'config';

export const initSentry = (): void => {
  const environment = config.REACT_APP_ENV;
  if (!environment || !['production', 'staging'].includes(environment)) {
    return;
  }
  Sentry.init({
    dsn: config.REACT_APP_SENTRY_DSN,
    environment: environment
  });

  const userId = getCurrentUserId();

  if (userId) {
    Sentry.setUser({ id: userId });
  }
};

interface ReportErrorParams {
  /**
   * Override the default grouping mechanism by providing our own grouping key.
   * See https://docs.sentry.io/data-management/event-grouping/sdk-fingerprinting/?platform=browser
   */
  fingerprint?: string[]

  // The error's severity level.
  severity?: Sentry.Severity
}

// Log the error in Sentry.
export const reportError = (
  error: Error, { fingerprint, severity }: ReportErrorParams = {}): void => {

  const doInScope = (scope: Scope): void => {
    if (error['extraData']) {
      scope.setExtras(error['extraData']);
    }

    if (fingerprint && fingerprint.length > 0) {
      scope.setFingerprint(fingerprint);
    }

    if (severity) {
      scope.setLevel(severity);
    }

    Sentry.captureException(error);
  };

  Sentry.configureScope(doInScope);
};

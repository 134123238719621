import { CampaignParticipation, Order } from 'app/models/orders';
import { Button } from '@udacity/veritas-components';
import React from 'react';

const FIRST_MONTH_FREE_TRIAL_INTERVAL_COUNT = 30;

/**
 * Data that describes a free trial's configuration.
 */
export interface TrialData {
  trialInterval: string; // date interval unit e.g. 'day', 'month'
  trialIntervalCount: number; // interval length e.g. 7, 30
  trialIntervalCountWord: string; // interval length in English e.g. 'seven'
}

/**
 * Get trial data. At the moment, trial length defined in units other than days are not
 * supported.
 *
 * @param order the order to evaluate
 * @return trial data object, or undefined if trial is not available
 */
export const getTrialData = (order: Order): TrialData | undefined => {
  const isParticipating = order?.campaignParticipation === CampaignParticipation.JOIN;
  if (!isParticipating) {
    return undefined;
  }
  for (const [, promoDetail] of Object.entries(order.promotion)) {
    const interval = promoDetail['trial_interval'];
    const count = promoDetail['trial_interval_count'];
    const word = promoDetail['trial_interval_count_word'];
    const isDataValid = interval === 'day' && Number.isInteger(count) && count > 0 && word;
    if (isDataValid) {
      return {
        trialInterval: interval,
        trialIntervalCount: count,
        trialIntervalCountWord: word
      };
    }
  }
  return undefined;
}

const trialEndDate = (trialData: TrialData | undefined, now?: Date): Date => {
  let future = now ? now : new Date();
  const days = trialData ? trialData.trialIntervalCount : 0;
  future.setDate(future.getDate() + days);
  return future;
};
/**
 * Return the localized date string indicating when the trial ends. Date is in in device time zone.
 * @param trialData trial data object, or undefined if trial is not available
 * @param now optional overriding of current time
 */
export const getTrialEndDateStringLocalized = (trialData: TrialData | undefined, now?: Date): string => {
  return trialEndDate(trialData, now).toLocaleDateString();
};

/**
 * Return the date string indicating when the trial ends, in format MMMM-DD-YYYY, i.e September 23, 2020
 * Date is in in device time zone.
 * @param trialData trial data object, or undefined if trial is not available
 * @param now optional overriding of current time
 */
export const getTrialEndDateStringMmDdYyyy = (trialData: TrialData | undefined, now?: Date): string => {
  const date = trialEndDate(trialData, now);

  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

export const getTrialSecondPaymentDateMmDdYyyy = (trialData: TrialData | undefined, now?: Date): string => {
  const date = trialEndDate(trialData, now);
  date.setMonth(date.getMonth() + 1);

  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
};

export const isFreeTrialEligibilityError = (error: object | undefined): boolean => {
  const errMsg = error && error["message"];
  if (!errMsg) {
    return false;
  }
  return errMsg.includes("already received a free trial") || errMsg.includes("promotion is not currently available");
};

export const TurnOffTrialButton = (props: { onClick: () => void }): JSX.Element => {
  return (
    <Button
      testID={'turnOffTrial'}
      label={'enroll at current price'}
      onClick={props.onClick}
      small
    />
  );
};

export const getZeroAmountDisplay = (currency: string): string => {
  switch (currency) {
    case 'AED':
      return '0 د.إ AED';
    case 'AUD':
      return '$0 AUD';
    case 'BHD':
      return 'ب.د0 BHD';
    case 'BRL':
      return 'R$0 BRL';
    case 'CAD':
      return '$0 CAD';
    case 'CHF':
      return 'CHF0 CHF';
    case 'CNY':
      return '¥0 CNY';
    case 'EGP':
      return 'ج.م0 EGP';
    case 'EUR':
      return '€0 EUR';
    case 'GBP':
      return '£0 GBP';
    case 'HKD':
      return '$0 HKD';
    case 'INR':
      return '₹0 INR';
    case 'JOD':
      return 'د.ا0 JOD';
    case 'JPY':
      return '¥0 JPY';
    case 'KRW':
      return '₩0 KRW';
    case 'KWD':
      return 'د.ك0 KWD';
    case 'MAD':
      return '0 د.م. MAD';
    case 'NZD':
      return '$0 NZD';
    case 'OMR':
      return 'ر.ع.0 OMR';
    case 'QAR':
      return '0 ر.ق QAR';
    case 'SAR':
      return 'ر.س0 SAR';
    case 'SGD':
      return '$0 SGD';
    case 'USD':
      return '$0 USD';
    case 'ZAR':
      return 'R0 ZAR';
    default:
      return '0';
  }
};

export const hasFirstMonthFreeTrial = (order: Order): boolean => {
  const trialData = getTrialData(order);
  return (trialData !== undefined) && (trialData.trialIntervalCount === FIRST_MONTH_FREE_TRIAL_INTERVAL_COUNT);
};


import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { TextInput, Button } from '@udacity/veritas-components';

import { actionsBinder } from 'app/helpers/action-helper';
import analytics, { events } from 'app/helpers/analytics-helper';
import IconCaret from 'app/components/common/icon-caret';
import styles from './coupon-code-form.module.scss';

const mapStateToProps = (state) => ({
  requestedCoupon: _.get(state, 'checkout.order.input.coupon'),
  coupon: _.get(state, 'checkout.order.coupon')
});

const mapDispatchToProps = actionsBinder('updateOrder');

class CouponCodeForm extends React.Component {
  state = {
    codeApplied: false,
    couponCode: null,
    open: false
  };

  componentDidMount() {
    const { requestedCoupon } = this.props;

    if (!_.isEmpty(requestedCoupon)) {
      this.setState({
        codeApplied: true,
        couponCode: requestedCoupon,
        open: true
      });
    }
  }

  componentDidUpdate(prevProps) {
    const newCode = _.get(this.props, 'coupon.code');
    const oldCode = _.get(prevProps, 'coupon.code');
    if (newCode !== oldCode && newCode === this.state.couponCode) {
      analytics.trackEvent(events.COUPON_CODE_APPLIED, {
        coupon_code: newCode
      });
    }
  }

  toggleForm = () => {
    this.setState({ open: !this.state.open });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.applyCouponCode();
    }
  };

  updateCouponCode = (event, callback = _.noop) => {
    const couponCode = _.toUpper(event.target.value);
    this.setState(
      {
        codeApplied: false,
        couponCode
      },
      callback
    );
  };

  applyCouponCode = () => {
    this.props.updateOrder({ coupon: this.state.couponCode }).then(() => {
      this.setState({
        codeApplied: true
      });
    });
  };

  removeCouponCode = () => {
    const couponCodeForRemoval = this.props.coupon?.code;

    this.props.updateOrder({ coupon: undefined }).then(() => {
      this.setState({
        codeApplied: false
      });
      analytics.trackEvent(events.COUPON_CODE_REMOVED, {
        coupon_code: couponCodeForRemoval
      });
    });
  };

  getCouponErrorCode = (requestedCoupon) => {
    return `Coupon ${requestedCoupon} is invalid.`;
  };

  render() {
    const { codeApplied, open } = this.state;
    const { coupon, requestedCoupon } = this.props;
    const invalidCoupon =
      codeApplied && requestedCoupon !== _.get(coupon, 'code');
    const showDescription = open && codeApplied && !invalidCoupon;

    return (
      <div className={styles.coupon_code_form}>
        <span className={styles.link} onClick={this.toggleForm}>
          {'I have a coupon code'}
          <span className={styles.icon}>
            <IconCaret rotated={open} color="gray-dark" />
          </span>
        </span>

        {open && (
          <div className={styles.form_container}>
            <TextInput
              label=""
              required={true}
              id="couponInput"
              type="text"
              onChange={this.updateCouponCode}
              onKeyDown={this.handleKeyPress}
              placeholder={this.state.couponCode || 'Enter Code'}
            />
            <Button
              variant="secondary"
              onClick={this.applyCouponCode}
              disabled={!this.state.couponCode || this.state.codeApplied}
              label="Apply"
            />
            <Button
              variant="minimal"
              onClick={this.removeCouponCode}
              disabled={!coupon}
              label="Remove"
            />
          </div>
        )}

        {showDescription && (
          <div className={styles.coupon_description}>
            Coupon {requestedCoupon} applied!
          </div>
        )}

        {open && invalidCoupon && (
          <div className={styles.coupon_error_message}>
            {this.getCouponErrorCode(requestedCoupon)}
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponCodeForm);

import React, { Component } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/min';
import PropTypes from 'prop-types';
import styles from './phone-number-editor.module.scss';

class PhoneNumberEditor extends Component {
  static displayName = 'components/phone-number-editor.jsx';

  static propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    country: PropTypes.string
  };

  render() {
    const { country, inputClassName, onChange, value } = this.props;

    return (
      <div className={styles.phone_number_editor}>
        <PhoneInput
          placeholder={'Phone Number'}
          inputClassName={inputClassName}
          country={country}
          value={value}
          onChange={onChange}
          displayInitialValueAsLocalNumber
        />
      </div>
    );
  }
}

export default PhoneNumberEditor;

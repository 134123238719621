import _ from 'lodash';
import { PaymentProviders } from 'app/models/payment-providers';
import { CampaignParticipation } from 'app/models/orders';

/**
 * Get the current address from the checkout state.
 * @return {Address|undefined}
 */
export const getAddress = (checkoutState) => {
  const provider = _.get(checkoutState, 'selectedPaymentMethod.provider');
  const paymentMethodData = _.get(checkoutState, 'paymentMethodData');
  if (provider && paymentMethodData) {
    const paymentProvider = PaymentProviders.fromProviderType(provider);
    const providerPaymentMethodData = _.get(paymentMethodData, provider, {});
    return paymentProvider.getAddress(providerPaymentMethodData);
  }
  return undefined;
};

/**
 * Refresh the page after adding URL param that turns off promo.
 */
export const turnOffPromo = (location) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set('campaign_participation', CampaignParticipation.SKIP);
  window.location.search = searchParams.toString();
};

import moment from 'moment';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconCheck } from '@udacity/veritas-icons';
import { Loading } from '@udacity/veritas-components';

import { actionsBinder } from 'app/helpers/action-helper';
import analytics, {
  events,
  CHECKOUT_PAGE_CATEGORY
} from 'app/helpers/analytics-helper';

import Card from 'app/components/common/card';
import EmployerReceiptForm from 'app/components/forms/employer-receipt-form';
import Header from 'app/components/common/header';
import OrderConfirmation from 'app/components/order-confirmation';

import notificationService from 'app/services/notification-service';
import userService from 'app/services/user-service';
import styles from './confirmation.module.scss';
import {
  getTrialEndDateStringMmDdYyyy,
  getTrialData
} from 'app/models/promotions';
import { PaymentPlanType } from 'app/models/orders';
import { getTrialSecondPaymentDateMmDdYyyy } from 'app/models/promotions';

const mapStateToProps = (state) => ({
  user: state.user.user,
  userLoading: state.user.loading.user,
  geoLocation: state.ui.geoLocation,
  geoLoading: state.ui.loading,
  invoice: state.invoice,
  paymentPlanType: state.invoice.paymentPlanType,
  upfrontInterval: state.invoice.upfrontInterval,
  upfrontIntervalCount: state.invoice.upfrontIntervalCount,
  order: state.checkout.order
});

const mapDispatchToProps = actionsBinder(
  'fetchInvoiceData',
  'fetchCurrentUser',
  'fetchUserGeoLocation'
);

export class Confirmation extends Component {
  async componentDidMount() {
    await this.props.fetchCurrentUser();
    const {
      match: {
        params: { invoiceUrn }
      }
    } = this.props;
    this.props.fetchInvoiceData(invoiceUrn);
    this.props.fetchUserGeoLocation();
    analytics.trackPageView(
      CHECKOUT_PAGE_CATEGORY,
      events.CONFIRMATION_PAGE_VIEW
    );
  }

  render() {
    const {
      user,
      userLoading,
      invoice: {
        askForPhoneNumber,
        autoRenew,
        freeTrialFirstInvoice,
        grandTotal,
        invoiceUrn,
        paymentPlanType,
        isInvoiceFetched,
        name,
        ndKey,
        nextChargeAmountDisplay,
        nextChargeDueDate,
        upfrontInterval,
        upfrontIntervalCount
      },
      order,
      geoLocation,
      geoLoading
    } = this.props;
    const nextChargeDueDateFormatted = moment(nextChargeDueDate).format(
      'MMMM Do, YYYY'
    );

    const total = _.get(grandTotal, 'display');
    const showReceipt = _.get(grandTotal, 'amount', 0) > 0;
    const isLoading = !isInvoiceFetched || geoLoading || !name || userLoading;
    const trialData = getTrialData(order);

    if (isLoading) {
      return (
        <div className={styles.loading}>
          <div className={styles.loading_child}>
            <Loading />
          </div>
        </div>
      );
    }

    const userFullName = _.trim(
      `${user.firstName || ''} ${user.lastName || ''}`
    );

    const bundleLengthInMonth =
      upfrontInterval === 'month' ? upfrontIntervalCount : 0;
    const isBundle = paymentPlanType === PaymentPlanType.upfrontRecurring;
    const payableRecurringAmountDisplay = _.get(
      this.props.order,
      'paymentPlan.recurring_amount.payable_amount_display',
      ''
    );

    const FreeTrialTerms = () => {
      const recurringPaymentDescription = ` After ${bundleLengthInMonth} months you will be
      automatically enrolled in a monthly subscription and you will be charged 
      ${payableRecurringAmountDisplay} /  month until you graduate or cancel.`;

      const completeBundleDescription = () => {
        return (
          <>
            for {bundleLengthInMonth}-months access.
            {payableRecurringAmountDisplay && recurringPaymentDescription}
          </>
        );
      };

      const completeMonthlyDescription = () => {
        return (
          <>
            . On {getTrialSecondPaymentDateMmDdYyyy(trialData)}, you will be
            charged {payableRecurringAmountDisplay} / month until you graduate
            or cancel.
          </>
        );
      };

      return (
        <p>
          Your free trial ends on {getTrialEndDateStringMmDdYyyy(trialData)} and
          you will be charged {nextChargeAmountDisplay}
          {isBundle ? (
            <> {completeBundleDescription()}</>
          ) : (
            <>{completeMonthlyDescription()}</>
          )}
        </p>
      );
    };

    return (
      <div>
        <Header showLock={false} headerText="Payment Confirmation" />

        <main className={styles.body}>
          <div className={styles.container}>
            <div className={styles.main_card}>
              <h1 className={styles.header_text}>
                <IconCheck title="check" />
                Your payment is confirmed
              </h1>

              <div className={styles.info} data-testid="youPaid">
                <span>
                  You paid <b>{total}</b>.
                </span>
              </div>

              {freeTrialFirstInvoice && <FreeTrialTerms />}

              {autoRenew && !freeTrialFirstInvoice && (
                <div className={styles.info}>
                  Your monthly subscription will auto-renew at a monthly price
                  of {nextChargeAmountDisplay} starting on{' '}
                  {nextChargeDueDateFormatted}.
                </div>
              )}
              {showReceipt && (
                <>
                  <div className={styles.info}>
                    A receipt was sent to your email address.
                  </div>

                  {invoiceUrn && (
                    <EmployerReceiptForm
                      invoiceUrn={invoiceUrn}
                      name={userFullName}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div className={styles.container}>
            <Card>
              <OrderConfirmation
                user={user}
                country={geoLocation.countryCode}
                updatePhoneNumber={userService.updatePhoneNumber}
                updateNotificationPref={
                  notificationService.updateNotificationPref
                }
                askPhoneNumber={askForPhoneNumber === true}
                ndName={name}
                ndKey={ndKey}
                total={total}
              />
            </Card>
          </div>

          <div className={styles.container}>
            <p>
              Questions or concerns? Please visit the{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
                href="https://support.udacity.com"
              >
                Help Center
              </a>
              .
            </p>
          </div>
        </main>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);

import config from '../../config';

const optimizelyProjects = {
  PROJECT_ID_GROWTH: config.REACT_APP_PROJECT_ID_GROWTH
};

export default {
  providers: {
    AFFIRM_CREDIT: 'affirm_credit',
    EBANX_CARD: 'ebanx_card',
    CKO: 'cko',
    NOOP: 'noop',
    PAYPAL: 'paypal',
    STRIPE: 'stripe',
    RAZORPAY: 'razorpay'
  },
  breakpoints: {
    MOBILE: 320,
    TABLET: 740,
    DESKTOP: 980,
    WIDE: 1300
  },
  experiments: {},
  optimizelyProjects,
  ackAutoRenewCountries: [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GR',
    'HR',
    'HU',
    'IE',
    'IS',
    'IT',
    'LI',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK'
  ],
  links: {
    AUTO_RENEW_FAQ:
      'https://udacity.zendesk.com/hc/en-us/sections/360007576551-Subscription-and-Auto-Renewal',
    CALIFORNIA_NOTICE: 'https://www.udacity.com/legal/california-residents',
    FAQ: 'https://www.udacity.com/faq',
    ONE_MONTH_FREE_TERMS:
      'https://www.udacity.com/legal/en-us/one-month-free-access#free-month',
    PRIVACY: 'https://www.udacity.com/legal/privacy',
    TERMS: 'https://www.udacity.com/legal/terms-of-use'
  },
  scripts: {
    EBANX: 'https://js.ebanx.com/ebanx-libjs-latest.min.js',
    PAYPAL: 'https://www.paypalobjects.com/api/checkout.js',
    RAZORPAY_HOSTED_CHECKOUT: 'https://checkout.razorpay.com/v1/checkout.js'
  },
  keyCodes: {
    ENTER_KEY: 13
  },
  authProviders: {
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    GATECH: 'gatech'
  },
  auth: {
    SIGN_UP: 'sign-up',
    SIGN_IN: 'sign-in'
  }
};

import React from 'react';
import styles from './course-feature.module.scss';
import brain from 'assets/images/course-feature/blue-brain.svg';
import career from 'assets/images/course-feature/purple-career.svg';
import head from 'assets/images/course-feature/green-head.svg';
import console from 'assets/images/course-feature/red-console.svg';

export const CourseFeature: React.FunctionComponent = () => {
  return (
    <div className={styles.feature}>
      <div className={styles.textCenter}>
        <h3>Get the Udacity Difference</h3>
        <h4>Only Udacity has all the critical factors to deliver real results.</h4>
      </div>
      <div className={styles.wrapperGrid}>
        <div className={styles.box}>
          <div><img src={brain} alt="brain icon" /></div>
          <span>Quality curriculum teaches real, employable skills</span>
        </div>
        <div className={styles.box}>
          <div>
            <div><img src={head} alt="head icon" /></div>
          </div>
          <span>Flexible, online platform lets you learn on your schedule</span>
        </div>
        <div className={styles.box}>
          <div>
            <div><img src={console} alt="console icon" /></div>
          </div>
          <span>Real-world projects offer active, hands-on learning</span>
        </div>
        <div className={styles.box}>
          <div>
            <div><img src={career} alt="career icon" /></div>
          </div>
          <span>24/7 access to technical mentors provides the help you need, when you need it</span>
        </div>
      </div>
    </div>
  );
};

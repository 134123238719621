import { createAsyncAction } from 'app/helpers/action-helper';
import analytics from 'app/helpers/analytics-helper';
import experimentsService from 'app/services/experiments-service';
import userService from 'app/services/user-service';
import Types from './types';
import { createAction } from '../helpers/action-helper';

export default {
  fetchAnonymousId() {
    return createAsyncAction(
      Types.FETCH_ANONYMOUS_ID,
      Types.FETCH_ANONYMOUS_ID_COMPLETE,
      () => {
        return experimentsService.getAnonymousId();
      }
    );
  },

  fetchCurrentUser() {
    return createAsyncAction(
      Types.FETCH_CURRENT_USER,
      Types.FETCH_CURRENT_USER_COMPLETE,
      () => {
        return userService.getUserAccountInfo();
      }
    );
  },

  /**
   * Fetch a list of experiments the use participates in
   * @param projectId Optimizely project id
   * @param experimentKeys array of experiment keys
   * @param countryCode two letter upper case country code
   * @param userId udacity id
   * @param currency three letter upper case currency code of the sku
   * @return array of experiments and their variations
   */
  fetchExperiments(
    projectId,
    experimentKeys,
    countryCode,
    userId,
    currency,
    additionalAttr = {}
  ) {
    return createAsyncAction(
      Types.FETCH_EXPERIMENTS,
      Types.FETCH_EXPERIMENTS_COMPLETE,
      async () => {
        const rawOverrides = new URLSearchParams(window.location.search).getAll(
          'experiment'
        );
        const overrides = experimentsService.getExperimentOverrides(
          rawOverrides
        );
        const experiments = await Promise.all(
          experimentKeys.map(async (experimentKey) => {
            // null is an accepted value in overrides, so can't use overrides[experimentKey].
            if (experimentKey in overrides) {
              return {
                projectId,
                experimentKey,
                variationKey: overrides[experimentKey]
              };
            }
            const attributes = {
              ...additionalAttr,
              user_id: userId,
              country_code: countryCode,
              currency,
              user_state: userId ? 'logged_in' : 'logged_out'
            };
            const variation = await experimentsService.getExperimentVariationForAnonymousId(
              projectId,
              experimentKey,
              attributes
            );

            const variationKey = variation ? variation.variationKey : null;
            return {
              projectId,
              experimentKey,
              variationKey
            };
          })
        );
        analytics.identifyExperiments(experiments);
        return experiments;
      }
    );
  },

  fetchFeature(projectId, userId, featureKey) {
    return createAsyncAction(
      Types.FETCH_FEATURE,
      Types.FETCH_FEATURE_COMPLETE,
      async () => {
        const enabled = await experimentsService.isFeatureEnabled(
          projectId,
          userId,
          featureKey
        );
        return { [featureKey]: enabled };
      }
    );
  },

  /**
   * Force a feature to be on or off, bypassing calling experiment service
   */
  forceFeature(featureKey, forcedValue) {
    return createAction(Types.FETCH_FEATURE_COMPLETE, {
      [featureKey]: forcedValue
    });
  },

  forceVariation(experiment, variationKey) {
    return createAction(Types.FORCE_EXPERIMENT_VARIATION, {
      projectId: experiment.project_id,
      experimentKey: experiment.experiment_key,
      variationKey
    });
  }
};

import _ from 'lodash';
import Types from 'app/actions/types';
import { errorTypes } from 'app/helpers/error-helper';
import constants from 'app/constants/constants';

const noErrorState = {
  fullPage: null,
  checkout: null,
  notify: null
};

export const defaultState = {
  geoLocation: null,
  loading: false,
  initializedScripts: {},
  errors: noErrorState
};

export default (state = defaultState, action) => {
  const error = _.get(action, 'payload.error');

  if (error) {
    switch (error.type) {
      case errorTypes.FULL_PAGE:
        return {
          ...state,
          errors: {
            ...state.errors,
            fullPage: error
          }
        };
      case errorTypes.NOTIFY:
        return {
          ...state,
          errors: {
            ...state.errors,
            notify: error
          }
        };
      default:
        return state;
    }
  }

  switch (action.type) {
    case Types.DISPLAY_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload
        }
      };

    case Types.FETCH_USER_GEO_LOCATION:
      return {
        ...state,
        loading: true
      };

    case Types.FETCH_USER_GEO_LOCATION_COMPLETE:
      return {
        ...state,
        geoLocation: action.payload,
        loading: false
      };

    case Types.RESET_ERROR:
      return {
        ...state,
        errors: noErrorState
      };

    default:
      return state;
  }
};

// Get the 2-letter country code
export const getCountryCode = (state) => {
  return state.ui.geoLocation ? state.ui.geoLocation.countryCode : null;
};

// Get if the terms should be checkboxed. This is true for California and all
// of Europe. All of Europe is listed in the constants.
export const getCheckboxTerms = (state) => {
  const { countryCode } = state.ui.geoLocation;
  // If Europe
  if (_.includes(constants.ackAutoRenewCountries, countryCode)) {
    return true;
  }

  // TODO: geoLocation currently comes from geode's `/` route, which only returns a subset of the geode data. To
  // get region, we'd have to call berlioz with `/some.ip.address` instead of
  // utilizing the CF served `/`. Currently judging it too expensive to make
  // another back-end call to determine this and enforcing checkbox terms for
  // all of US;
  //
  // return countryCode === 'US' && region === 'CA';
  return countryCode === 'US';
};

/**
 * Return the number of month before the next payment is due
 * @param state
 * @return {number} an integer
 */
export const getMonthsUntilDue = (state) => {
  const coupon = _.get(state.checkout, 'order.coupon');
  // Hack to handle the special case of n month free coupons.
  if (coupon && coupon.duration === 'repeating' && coupon.percentOff === 100) {
    return coupon.durationInMonths;
  }
  const defaultMonthUntilDue = 1;
  const paymentPlan = _.get(state.checkout, 'order.paymentPlan');
  if (paymentPlan) {
    const paymentPlanType = paymentPlan.type;
    return paymentPlanType === 'recurring'
      ? paymentPlan['recurring_interval_count']
      : paymentPlan['upfront_interval_count'];
  }
  return defaultMonthUntilDue;
};

/**
 * Get the current error's message. Favor the specific use facing message over the generic message.
 * @param state
 * @param errorType {string} the type of the error, one of value from {@link errorTypes}
 * @return {string|undefined} error message in string or undefined if there is no error
 */
export const getUiErrorMessage = (state, errorType) => {
  const error = _.get(state.ui, `errors.` + errorType);
  if (error) {
    if (error.endUserMessage) {
      return error.endUserMessage;
    } else {
      return error.message;
    }
  }
  return undefined;
};

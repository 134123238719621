import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { CheckOutStepProps } from './checkout-step';
import constants from '../constants/constants';
import { CRUMB_MAP, PageId } from 'app/components/common/breadcrumb-bar';
import OrSeparator from '../components/common/or-separator';
import SignUpForm from '../components/forms/sign-up-form';
import ProviderButtons from '../components/provider-buttons';
import styles from './authentication.module.scss';
import SignInForm from '../components/forms/sign-in-form';
import { getCheckboxTerms } from '../reducers/ui';
import actions from 'app/actions';
import analytics from 'app/helpers/analytics-helper';

type Props = DerivedProps & typeof mapDispatchToProps & CheckOutStepProps;

interface DerivedProps {
  checkboxTerms: boolean;
}

interface State {
  isSignUp: boolean | undefined;
  showWelcome: boolean;
  accountExisted: boolean;
}

const mapStateToProps = (state: object): DerivedProps => {
  return {
    checkboxTerms: getCheckboxTerms(state),
  };
};

const mapDispatchToProps = {
  fetchCurrentUser: actions.fetchCurrentUser,
  updateOrderWithUser: actions.updateOrderWithUser
};

export class Authentication extends React.Component<Props, State> {

  state = {
    isSignUp: true,
    showWelcome: true,
    accountExisted: false
  };

  /**
   * After auth success, load the user's information from User Service, then
   * fetch the user's payment methods and validate the order.
   */
  onSuccess = async (): Promise<void> => {
    const { fetchCurrentUser, history, order, toPage, updateOrderWithUser, isSinglePage } = this.props;
    const user = await fetchCurrentUser();
    await updateOrderWithUser(order, user);
    // For single page checkout, we do not want to use the `toPage` helper function to navigate users to the hash router.
    if(isSinglePage) return;
    toPage(PageId.cart, history, false);
  };

  onAccountExists = (): void => {
    this.setState({ isSignUp: false, accountExisted: true });
  };

  toggleIsSignUp = (): void => {
    this.setState({ isSignUp: !this.state.isSignUp });
  };

  continue = (isSignUp: boolean): void => {
    const pageCategory = CRUMB_MAP[PageId.authentication].analyticsName;
    if (isSignUp) {
      analytics.trackAction('start_checkout_sign_up_button_clicked', pageCategory);
    } else {
      analytics.trackAction('start_checkout_sign_in_button_clicked', pageCategory);
    }
    this.setState({
      isSignUp,
    });
  };

  render(): ReactNode {
    const { isSignUp, accountExisted } = this.state;

    const signInComponent = <>
      <div>
        <h1 className={styles.title}>Sign in</h1>
        <div className={styles.description}>To use a different email address, please return to <button className={styles.toggleTextButton} onClick={this.toggleIsSignUp}>Quick Checkout</button>.</div>
      </div>
      <ProviderButtons
        type={constants.auth.SIGN_IN}
        next={window.location.href}
        providers={[constants.authProviders.GOOGLE, constants.authProviders.FACEBOOK]}
      />
      <OrSeparator />
      <SignInForm
        onSuccess={this.onSuccess}
        accountExisted={accountExisted}
      />
    </>

    const signUpComponent = <>
      <div>
        <h1 className={styles.title}>Quick Checkout</h1>
        <div>
          <div className={styles.subtitle}>Already have an account? <button className={styles.toggleTextButton} onClick={this.toggleIsSignUp}>Sign in</button></div>
        </div>
        <div className={styles.description}>Sign up using your Google or Facebook account or get started with just your email.</div>
      </div>
      <ProviderButtons
        type={constants.auth.SIGN_UP}
        next={window.location.href}
        providers={[constants.authProviders.GOOGLE, constants.authProviders.FACEBOOK]}
      />
      <OrSeparator />
      <SignUpForm
        onSuccess={this.onSuccess}
        onAccountExists={this.onAccountExists}
        checkboxTerms={this.props.checkboxTerms}
      />
    </>

    return (
      <div className={styles.container}>
        {
          isSignUp ? signUpComponent : signInComponent
        }
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authentication);

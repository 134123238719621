import constants from 'app/constants/constants';
import { PaymentProvider } from './payment-provider';
import { Address } from 'app/models/address';
import { PurchaseDataKind } from './purchase-data-kind';

export class StripePaymentProvider extends PaymentProvider {
  get providerName(): string {
    return constants.providers.STRIPE;
  }

  get paymentMethodType(): string {
    return 'stripe';
  }

  requiresCardToken(): boolean {
    return true;
  }

  supportsRecurringPayments(): boolean {
    return true;
  }

  getAddress(paymentMethodData: any): Address {
    if (!paymentMethodData) {
      return new Address({});
    }
    const addressData = paymentMethodData.card ? paymentMethodData.card : paymentMethodData;
    return new Address({
      line1: addressData.line1,
      line2: addressData.line2,
      city: addressData.city,
      region: addressData.region,
      country: addressData.country,
      postalCode: addressData.postalCode
    });
  }

  canAuthorizeCharge(): boolean {
    return true;
  }

  getPurchaseDataToSubmit(): PurchaseDataKind[] {
    return [PurchaseDataKind.PAYMENT_METHOD_URN];
  }
}

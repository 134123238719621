import config from 'config';
import _ from 'lodash';
const parseUdacityUrlString = (urlString) => {
  const url = new URL(urlString);

  if (!_.endsWith(url.hostname, '.udacity.com')) {
    throw new Error('Cannot redirect to a non Udacity page');
  }

  return url;
};

export default {
  goToClassroom(ndKey = null) {
    const path = (() => {
      let nodeType = '';
      if (ndKey && ndKey.length > 1) {
        nodeType = ndKey.substring(0, 2);
      }
      switch (nodeType) {
        case 'nd':
          return `nanodegrees/${ndKey}`;
        case 'cd':
          return `paid-courses/${ndKey}`;
        default:
          return 'me';
      }
    })();

    window.location.href = `${config.REACT_APP_CLASSROOM_URL}/${path}`;
  },

  goToUdacityUrl(urlString) {
    const url = parseUdacityUrlString(urlString);

    window.location.href = url;
  },

  openWindowWithUdacityUrl(urlString) {
    const url = parseUdacityUrlString(urlString);

    window.open(url.href);
  }
};
